/* RADIUS */
$styleList-borderRadius: (
    extra-small: 4,
    small: 8,
    medium: 16,
);

@function getBorderRadius($value) {
  @return map-get($styleList-borderRadius, $value);
}

@each $typeStyle in map-keys($styleList-borderRadius) {
  .cc-borderRadius-#{$typeStyle} {
    border-radius: rem-calc(getBorderRadius($typeStyle));
  }
}