.cc-boolean-field {
    display: inline-flex;
    position: relative;
    margin-bottom: $global-margin;
    padding-right: rem-calc(34);

    input[type="checkbox"],
    input[type="radio"] {
        height: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 0;
    
        &:checked {
            & ~ .cc-checkbox-input,
            & ~ .cc-radio-input {
                &::before {
                border-color: $border-black;
                }
            }
            &::after {
              opacity: 1;
            }
        }  

        &:focus-visible {
          & ~ .cc-checkbox-input,
          & ~ .cc-radio-input {
            &::before {
              border-color: $border-focused;
            }
          }
        }
    
        &:disabled {
            cursor: not-allowed;
            & ~ .cc-checkbox-input::before, & ~ .cc-radio-input::before {
                cursor: not-allowed;
                border-color: $border-grey;
            }
            & ~ .cc-checkbox-label, & ~ .cc-radio-label {
                color: $text-grey;
                cursor: not-allowed;
            }
        }
      }

    .cc-checkbox-label,
    .cc-radio-label {
        color: $black;
        cursor: pointer;
        line-height: rem-calc(18);
    }

    .cc-checkbox-input {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(24);
        text-transform: none;
        margin: 0;

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: rem-calc(-2);
            left: 0;
            width: rem-calc(20);
            height: rem-calc(20);
            background-color: transparent;
            border: rem-calc(1) solid $border-black;
            transition: all 0.2s;
            cursor: pointer;
            @include breakpoint(large up) {
                width: rem-calc(16);
                height: rem-calc(16);
                top: 0;
            }
        }

        &:after {
            top: rem-calc(0.5);
            left: rem-calc(2.5);
            width: rem-calc(15);
            height: rem-calc(15);
            background-color: $surface-dark;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: all 0.1s;
            cursor: pointer;
            @include breakpoint(large up) {
                width: rem-calc(10);
                height: rem-calc(10);
                top: rem-calc(3);
                left: rem-calc(3);
            }
        }
    }

    .cc-radio-input {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(24);
        text-transform: none;
        margin: 0;

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: rem-calc(-2);
            left: 0;
            width: rem-calc(20);
            height: rem-calc(20);
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid $border-black;
            transition: all 0.2s;
            cursor: pointer;
            @include breakpoint(large up) {
                width: rem-calc(16);
                height: rem-calc(16);
                top: 0;
            }
        }

        &:after {
            top: rem-calc(0.5);
            left: rem-calc(2.5);
            border-radius: 50%;
            width: rem-calc(15);
            height: rem-calc(15);
            background-color: $surface-dark;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: all 0.1s;
            cursor: pointer;
            @include breakpoint(large up) {
                width: rem-calc(10);
                height: rem-calc(10);
                top: rem-calc(3);
                left: rem-calc(3);
            }
        }

        &.error-refund {
            &:before {
                border-color: $border-error;
            }
            &:after {
                background-color: $surface-error;
            }
        }
    }
    input[type="checkbox"]:checked + .cc-checkbox-input:after,
    input[type="radio"]:checked + .cc-radio-input:after {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
    input[type="checkbox"]:focus ~ .cc-checkbox-input:before,
    input[type="radio"]:focus ~ .cc-radio-input:before {
        outline: none;
    }
    &.is-invalid-input {
        background: transparent;

        input[type="radio"]:not(:focus) + .cc-radio-input:before {
            border-color: $border-error;
        }
        input[type="checkbox"]:not(:focus):not(:checked) + .cc-checkbox-input:before {
            border-color: $border-error;
        }
    }
}
.toggle-switch {
    position: relative;
    display: flex;
    font-size: 1.4rem;
    letter-spacing: .4px;
    cursor: pointer;
    
    .toggle {
        position: relative;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $grey3;
        transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
        border-radius: rem-calc(34);
        min-width: rem-calc(50);
        max-width: rem-calc(50);
        width: rem-calc(50);
        height: rem-calc(24);
    
        &:before {
            position: absolute;
            content: '';
            height: rem-calc(20);
            width: rem-calc(20);
            left: rem-calc(2);
            bottom: rem-calc(2);
            background-color: $white;
            transition: 0.4s;
            border-radius: 50%;
        }
    }
    
    &.active .toggle{
        background-color: $black;
        &:before {
            transform: translateX(#{rem-calc(26px)});
        }
    }
}


