.wrapper-max-width {
    // max-width: rem-calc(635);
    margin: 0 auto;

    h2.title-refund-login {
        font-size: rem-calc(30);
        line-height: rem-calc(43);
        padding-top: rem-calc(47);
        padding-bottom: rem-calc(30);
        text-align: center;
        font-weight: $global-weight-dark;
        margin: 0;

        @include breakpoint (small down) {
            padding-top: rem-calc(21);
            padding-bottom: rem-calc(10);
            font-size: rem-calc(24);
        }
    }

    h3 {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        padding-bottom: rem-calc(24);
        font-weight: $global-weight-dark;

        @include breakpoint (small down) {
            text-align: center;
            padding-bottom: rem-calc(8);
        }
    }

    p {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        text-align: left;
        padding-bottom: rem-calc(25);
        margin: 0;

        @include breakpoint (small down) {
            padding-bottom: rem-calc(15);
            text-align: center;
        }
    }

    .marb-30 {
        margin-bottom: rem-calc(30);
    }

    .note-message-required {
        font-size: rem-calc(12);
        line-height: rem-calc(20);
        padding-bottom: rem-calc(10);
        margin: 0;
        text-align: left;

        @include breakpoint (small down) {
            text-align: left;
        }
    }

    .box-note-message-required {
        text-align: center;
        font-size: rem-calc(12);
        line-height: rem-calc(17);
        padding: rem-calc(20 45 20 25);
        background-color: #9F847E;
        margin-bottom: rem-calc(10);
        width: 100%;

        img {
            padding-right: rem-calc(10);
        }
    }

    .input-width-img {
        position: relative;

        img {
            position: absolute;
            right: rem-calc(10);
            top: rem-calc(10);

        }
    }
}

.wrapper-max-height {
    height: 85vh;

    @include breakpoint (small down) {
        height: 100vh;
    }

    button {
        @include breakpoint (medium down) {
            width: 100%;
            margin-bottom: rem-calc(55);
        }
    }
}

.grid-x>.small-12 {
    &.refund-iban {
        margin-top: 5%;
    }
    .privacy-policy {
        font-size: 9px;
        letter-spacing: 0.2px;
        line-height: 17px;
        text-transform: uppercase;
    }
}