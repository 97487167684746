body.tingle-enabled {
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
}

.modal {
    display: none;
}

.consent-modal .tingle-modal-box__content {
    padding: rem-calc(10 50 10 10);
}

.tingle-modal {
    z-index: 3010;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: $surface-dark-40;
    cursor: default;
    transition: opacity .35s cubic-bezier(0.165, 0.84, 0.44, 1);
    &.tingle-modal--visible {
        visibility: visible;
        opacity: 1;
        .tingle-modal-box__footer {
            bottom: 0;
        }
        .tingle-modal-box {
            transform: translateY(0);
            opacity: 1;
            &.rightModalAttribute{
                display: flex;
            }
        }
    }
    &__close,
    &__closeLabel {
        display: none;
    }
    &--noClose {
        &.tingle-modal__close {
            display: none;
        }
    }

    &.indexRemovePersonalization {
        z-index: 4000;
    }
}

.tingle-modal-box {
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    background: #fff;
    bottom: 0;
    min-height: 100%;
    cursor: auto;
    overflow-y: auto;
    transform: translateY(50px);
    opacity: 0;
    transition: all .35s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.4);
    width: 100%;
    max-height: 100vh;
        @include breakpoint(medium up) {
        width: rem-calc(491);
        max-width: rem-calc(491);
        max-height: 100%;
        overflow-x: hidden;
    }
    @include breakpoint(small only){
        min-height: calc(100% - #{rem-calc(46)});
        max-height: calc(100% - #{rem-calc(46)});
    }
    &__content {
        padding: rem-calc(23 39);
        background-color: $surface-white;
        @include breakpoint(small down){
            padding: rem-calc(23 20);
        }
        .back-arrow {
            color: $black;
        }
        .close {
            color: $black;
            position: absolute;
            top: rem-calc(20);
            right: rem-calc(30);
            font-size: 2em;
            cursor: pointer;
        }
        button {
            margin-bottom: 0;
        }
    }
    .modal__closeIcon {
        position: absolute;
        top: rem-calc(13);
        right: rem-calc(13);
        font-size: 2em;
        cursor: pointer;
    }
    &__footer {
        padding: rem-calc(10 20);
        width: auto;
        cursor: auto;
        text-align: right;
        &:after {
            display: table;
            clear: both;
            content: "";
        }
        &--sticky {
            position: fixed;
            bottom: -200px; // TODO: find a better way
            z-index: 10001;
            opacity: 1;
            transition: bottom .35s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
        button {
            margin-bottom: 0;
        }
    }
}

.consent-modal {
    & .tingle-modal-box {
        position: absolute;
        bottom: 3%;
        right: 3%;
    }
}

.choice {
    text-align: center;
}

.center {
    margin: auto;
    width: 60%;
    padding: 10px
}

.margin {
    padding-top: 5px;
}

.multilanguage-popup {
    .rightModalAttribute {
        @include breakpoint (medium down){
            background-color: #F6F6F5;
        }
    }
    .tingle-modal-box__content {
        @include breakpoint(large up){
            padding: rem-calc(23 40);
        }
    }
}

.multilanguage {
    padding-left: 0;
    padding-right: 0;
    @include breakpoint (medium down){
        .logo-heading {
            position: absolute;
            background-color: white;
            width: 100%;
            top: 0;
            left: 0;
            height: rem-calc(61);
            padding-top: rem-calc(17);
            padding-left: rem-calc(20);
            h3 {
                width: rem-calc(130);
            }
        }
    }
    .heading {
        font-weight: 300;
        margin-bottom: 0;
        @include breakpoint (medium down){
            margin-top: rem-calc(91);
        }
    }
    .select-your-language {
        margin-bottom: rem-calc(17);
        font-size: rem-calc(12);
        @include breakpoint (medium down){
            margin-top: rem-calc(4);
        }
    }
    .setLocaleCountry {
        border-bottom: rem-calc(1) solid $black;
        padding-right: rem-calc(19);
        @include breakpoint (medium down){
            padding-bottom: rem-calc(13);
        }
        @include breakpoint(large up){
            padding-bottom: rem-calc(12);
        }
    }
    .action-change-container{
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        @include breakpoint(large up){
            padding: rem-calc(40);
        }
        @include breakpoint (medium down){
            padding: rem-calc(30 20);
        }
        .action-change{
            width: 100%;
            min-width: 100%;
        }
    }
}

.miniWishlistProductCard-label {
    font-size: .625rem!important;
    line-height: .625rem!important;
}



.miniWishlistProductCard-gotoWishlist {
    padding:  1.42857rem 1.42857rem 1.875rem 1.42857rem!important;
    @media print, screen and (min-width: 75em) {
        padding: 1.875rem 2.85714rem!important;
   }
}
