html {
  &.special-cover-widget {
    margin: 0;
    height: 100%;
    overflow: hidden;
    body {
      margin: 0;
      height: 100%;
      overflow: hidden;
    }
  }
}

.spacer {
  &--large {
    height: rem-calc(120);
    @include breakpoint (medium down) {
      height: rem-calc(60);
    }
  }
  &--medium {
    height: rem-calc(90);
    @include breakpoint (medium down) {
      height: rem-calc(45);
    }
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  /* For Google Chrome */
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  /* For Internet Explorer */
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

.widget1-wrapper {
  display: flex;
  &.img-left {
    > div {
      &:last-of-type {
        order: -1;
      }
    }
  }
  @include breakpoint (medium down) {
    flex-direction: column;
    > div {
      &:last-of-type {
        order: -1;
      }
    }
  }
  > div {
    flex: 1;
    &:last-of-type {
      a {
        display: block;
      }
      img {
        width: 100%;
        @media screen and (max-width: 1024px) {
          max-height: 600px;
          object-fit: cover;
          padding-bottom: 20px;
        }
      }
    }
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    height: 100%;
  }
  h2.text1 {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
    font-family: "MessinaSansWeb", Arial, sans-serif;
    letter-spacing: .0125rem;
    @include breakpoint (medium down) {
      margin-bottom: 40px;
    }
    @include breakpoint (small down) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .bottom-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    @include breakpoint (small down) {
      width: 100%;
    }
    h2 {
      font-size: 34px;
      line-height: 42px;
      margin-bottom: 10px;
      letter-spacing: .0125rem;
      @include breakpoint (small down) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    p {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: .0125rem;
      font-family: "MessinaSansWeb", Arial, sans-serif;
    }
    .btn {
      margin-top: 15px;
      > span {
        padding: 13px 30px 10px;
        text-transform: uppercase;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        letter-spacing: .0125rem;
      }
    }
    .cta {
      margin-top: 20px;
      @include breakpoint (medium down) {
        margin-top: 25px;
      }
      > span {
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
        position: relative;
        font-family: 'MessinaSansWeb', sans-serif;
        font-weight: 400;
        letter-spacing: .0125rem;
        &:after {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #000;
        }
      }
    }
  }
}

.widget2-wrapper {
  overflow: hidden;
  &.grid-container {
    @include breakpoint (medium down) {
      max-width: none;
      padding-right: 0;
    }
    @include breakpoint (small down) {
      padding-left: 0;
    }
  }
  .grid-x {
    > div {
      @include breakpoint (medium down) {
        transform: translateX(-5px);
      }
    }
  }
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    letter-spacing: .0125rem;
    @include breakpoint (medium down) {
      transform: translateX(-5px);
    }
    @include breakpoint (small down) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .three-blocks {
    &__wrapper {
      display: flex;
      margin-left: -5px;
      margin-right: -5px;
      @include breakpoint (medium down) {
        overflow-x: auto;
        position: relative;
        user-select: none;
        cursor: grab;
        @include scrollbars(2px, #000, #DDD);
      }
    }
    &__single {
      flex: 1;
      margin-left: 5px;
      margin-right: 5px;
      @include breakpoint (medium down) {
        flex: none;
        max-width: 467px;
        padding-bottom: 30px;
      }
      @media screen and (max-width: 800px) {
        max-width: 360px;
      }
      @include breakpoint (small down) {
        max-width: 260px;
        padding-bottom: 32px;
      }
      &:first-of-type {
        @include breakpoint (medium down) {
          margin-left: 0;
        }
      }
      img:not(.set-image):not(.swatch-circle-for-product-set) {
        width: 100%;
        margin-bottom: 20px;
      }
      .single-title,
      .single-subtitle {
        font-family: 'MessinaSansWeb', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        letter-spacing: .0125rem;
      }
      p {
        margin-top: 8px;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: .0125rem;
      }
      del {
        margin-top: rem-calc(8);
      }
      .cta {
        margin-top: 15px;
        > span {
          display: inline-block;
          font-size: 14px;
          line-height: 1;
          position: relative;
          font-family: 'MessinaSansWeb', sans-serif;
          font-weight: 500;
          text-decoration: underline;
          text-underline-offset: rem-calc(3);
        }
      }
    }
  }
  .btn {
    margin-top: 33px;
    @include breakpoint (medium down) {
      margin-left: -5px;
      margin-top: 35px;
    }
    > span {
      padding: 13px 30px 10px;
      text-transform: uppercase;
      display: inline-block;
      font-size: 14px;
      line-height: 1;
      border: 1px solid #000;
      letter-spacing: .0125rem;
    }
  }
}

.widget2-full-wrapper {
  overflow: hidden;
  .grid-container {
    &:last-of-type {
      max-width: none;
      padding-right: 0;
      @include breakpoint (small down) {
        padding-left: 23px;
      }
    }
    @include breakpoint (small down) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .top {
    position: relative;
    margin-bottom: 20px;
    .title {
      font-size: 20px;
      line-height: 28px;
      font-family: 'MessinaSansWeb', sans-serif;
      min-height: 5px;
      letter-spacing: .0125rem;
      margin-bottom: 0;
      font-weight: 400;
    }
    .arrows {
      &__wrapper {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        @include breakpoint (small down) {
          display: none;
        }
      }
      &__left,
      &__right {
        display: inline-block;
        &.active {
          cursor: pointer;
          svg {
            path {
              fill: #000;
            }
          }
        }
        svg {
          max-width: 20px;
          path {
            fill: #D3CBC9;
            transition: all .2s ease;
          }
        }
      }
      &__left {
        padding-right: 5px;
      }
      &__right {
        padding-left: 5px;
      }
    }
  }
  .widget2-full {
    &__cards {
      display: flex;
      margin-left: -5px;
      margin-right: -5px;
      width: calc(100% + 3px);
      left: rem-calc(3);
      overflow-x: auto;
      position: relative;
      user-select: none;
      @include scrollbars(2px, #000, #DDD);
      &.smooth {
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
      }
      > div {
        margin-left: 5px;
        margin-right: 5px;
        flex: none;
        max-width: 425px;
        padding-bottom: 30px;
        position: relative;
        @media screen and (max-width: 1440px) {
          max-width: 320px;
        }
        @media screen and (max-width: 1280px) {
          max-width: 355px;
        }
        @media screen and (max-width: 1024px) {
          max-width: 245px;
        }
        @media screen and (max-width: 800px) {
          max-width: 265px;
        }
        @include breakpoint (small down) {
          max-width: 232px;
        }
        &:first-of-type {
          margin-left: 0;
        }
        .single-title {
          font-family: 'MessinaSansWeb', sans-serif;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: .0125rem;
        }
        img:not(.set-image):not(.swatch-circle-for-product-set) {
          width: 100%;
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 24px;
          font-family: "MessinaSansWeb", sans-serif;
          letter-spacing: .0125rem;
          max-width: 90%;
        }
        .cta {
          > span {
            display: inline-block;
            margin-top: 15px;
            font-size: 14px;
            line-height: 14px;
            position: relative;
            font-family: 'MessinaSansWeb', sans-serif;
            font-weight: 400;
            letter-spacing: .0125rem;
            &:after {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 1px;
              background: #000;
            }
          }
        }
      }
    }
  }

  &.pd-product-slider {
    padding: 60px 0;
    
    @include breakpoint (small down) {
      padding: 50px 0;
    }

    .widget2-full {
      &__cards {
        > div {
          max-width: 425px;
          @media screen and (max-width: 1440px) {
            max-width: 390px;
          }
          @media screen and (max-width: 1280px) {
            max-width: 400px;
          }
          @media screen and (max-width: 1199px) {
            max-width: 380px;
          }
          @media screen and (max-width: 1024px) {
            max-width: 305px;
          }
          @media screen and (max-width: 800px) {
            max-width: 235px;
          }
          @include breakpoint (small down) {
            max-width: calc(50vw - 16px);
          }
        }
      }
    }
  }
}

.experience-component.experience-layouts-cover {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: calc(80vh + 8px);
  @include breakpoint (medium up) {
    min-height: rem-calc(500);
  }
  margin-top: -70px;
  transition: height .3s ease, margin-top .3s ease;
  @media screen and (max-width: 1024px) {
    margin-top: -63px;
  }
  &.top-strip-in {
    height: calc(80vh - 36px);
    margin-top: -30px;
    @include breakpoint(medium down) {
      margin-top: -19px;
    }
    @include breakpoint (medium up) {
      min-height: rem-calc(500);
    }
    .cover__wrapper,
    .cover__wrapper .cover__slides.slick-slider.slick-vertical .slick-list,
    .cover__wrapper .cover__slides.slick-slider.slick-vertical .slick-slide {
      height: calc(80vh - 36px) !important;
      @include breakpoint (medium up) {
        min-height: rem-calc(500);
      }
    }
  }
}
.cover {
  &__wrapper {
    width: calc(100% + 2px);
    height: calc(80vh + 8px);
    @include breakpoint (medium up) {
      min-height: rem-calc(500);
    }
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-1px);
    * {
      outline: none;
    }
    &.white {
      .cover__slide .grid-container div .text-box .h2,
      .cover__slide .grid-container div .text-box p {
        color: $white;
      }
      .cover__slide .text-box .btn>span.widget-span.button-white,
      .cover__slide .text-box .btn>span.widget-span.button-black {
        border: 1px solid $white !important;
        background: transparent !important;
        color: $white !important;

        &:hover,
        &:focus,
        &.active {
            border: 1px solid $black !important;
            background: $black !important;
            color: $white !important;
        }
      }
      .cover__slides.slick-dotted .slick-dots li button {
        background: $white;
        &:before {
          border: 1px solid $white;
          border-bottom: 0;
        }
      }
    }
    &.black {
      .cover__slide .grid-container div .text-box .h2,
      .cover__slide .grid-container div .text-box p {
        color: $black;
      }
      .cover__slides.slick-dotted .slick-dots li button {
        background: $black;
        &:before {
          border: 1px solid $black;
          border-bottom: 0;
        }
      }
    }
  }
  &__slides {
    width: 100%;
    height: 100%;
    &.slick-dotted {
      &.slick-vertical {
        .slick-dots {
          display: flex;
          flex-direction: column;
          width: auto;
          bottom: auto;
          height: auto;
          top: 50%;
          transform: translateY(-50%);
          li {
            margin: 7px 0;
            padding: 0;
            width: 8px;
            height: 8px;
            transform: rotate(45deg);
            &:only-child {
              display: none;
            }
            button {
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 20px;
              opacity: 0.3;
              transition: background .2s ease;
              &:before {
                content: '';
                width: 16px;
                height: 8px;
                background: transparent;
                margin-top: 0;
                opacity: 1;
                top: 8px;
                left: -4px;
                border-radius: 50% 50% 0 0 / 100% 100% 0 0;
                transition: border .2s ease;
              }
            }
            &.slick-active {
              button {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  &__slide {
    position: relative;

    video:not(:only-of-type),  // Mobile format is not required, do not target when desktop is the only one present.
    img:not(.play-video) {     // .play-video - icon for playing youtube video 
      width: 100%;
      display: none;
      &:first-of-type {
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
      &:last-of-type {
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
      @media screen and (width: 1024px) and (orientation: portrait) {
        &:first-of-type {
          display: none;
        }
        &:last-of-type {
          display: block;
        }
      }
    }
    .clickable-link {
      z-index:1;
      background: rgba(0,0,0,0.8);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .grid-container {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    .text-box {
      position: absolute;
      width: 35%;
      z-index: 2;
      @include breakpoint (medium down) {
        width: 65%;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        @media screen and (max-width: 800px) {
          top: auto;
          left: auto;
          bottom: 30px;
          transform: none;
        }
        @include breakpoint (small down) {
          bottom: 15%;
        }
        .ctas-wrapper {
          justify-content: center;
        }
      }
      &.bottom-center {
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        @media screen and (max-width: 1024px) {
          bottom: 50px;
        }
        @include breakpoint (small down) {
          bottom: 15%;
        }
        .ctas-wrapper {
          justify-content: center;
        }
      }
      &.bottom-left {
        bottom: 40px;
        left: 0;
        text-align: left;
        @include breakpoint (medium down) {
          width: 55%;
        }
        @media screen and (max-width: 1024px) {
          width: 100%;
          bottom: 50px;
        }
        @include breakpoint (small down) {
          bottom: 15%;
        }
        .ctas-wrapper {
          @include breakpoint (small down) {
            align-items: start;
          }
        }
      }
      .title {
        display: inline-block;
        font-size: 34px;
        line-height: 42px;
        font-family: 'SangBleu Sunrise', Times, serif;
        font-weight: 300;
        margin-bottom: 10px;
        letter-spacing: .0125rem;
        transition: color .2s ease;
        @include breakpoint (small down) {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 10px;
        }
      }
      p {
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 24px;
        font-family: "MessinaSansWeb", sans-serif;
        font-weight: 400;
        letter-spacing: .0125rem;
        transition: color .2s ease;
        @include breakpoint (small down) {
          margin-bottom: 10px;
        }
      }
      .btn {
        @include breakpoint (small down) {
          margin-top: 10px;
        }
        > span {
          padding: 13px 30px 10px;
          text-transform: uppercase;
          display: inline-block;
          font-size: 14px;
          line-height: 1;
          font-weight: 500;
          letter-spacing: .0125rem;
          @include breakpoint (small down) {
            padding: 13px 20px 10px;
            text-align: center;
            min-width: 135px;
          }
        }
      }
    }
    .ctas-wrapper {
      display: flex;
      .first-one {
        @include breakpoint (small down) {
          margin-right: 10px;
        }
      }
      .second-one {
        @include breakpoint (small down) {
          margin-top: 10px !important;
          margin-left: 10px;
          display: inline-block !important;
        }
      }
      .btn {
        &:nth-of-type(2) {
          margin-left: 30px;
          @include breakpoint (small down) {
            margin-top: 15px;
            margin-left: 0;
          }
        }
      }
    }
    &--video {
      .play-video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        cursor: pointer;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover; 
      }
    }
  }
}
.cover__wrapper .cover__slides.slick-slider.slick-vertical .slick-list,
.cover__wrapper .cover__slides.slick-slider.slick-vertical .slick-slide {
  height: calc(80vh + 8px) !important;
  @include breakpoint (medium up) {
    min-height: rem-calc(500);
  }
  border: 0;
  &.slick-current.slick-active {
    @include breakpoint (small down) {
      position: static !important;
    }
  }
}
.cover__wrapper .cover__slides.slick-slider.slick-vertical .slick-slide .cover__slide {
  width: 100%;
  height: 100%;
}
.cover__wrapper .cover__slides.slick-slider.slick-vertical .slick-slide .cover__slide:not(.cover__slide--video) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lightbox {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  &-toggle {
    position: absolute;
  }
  &-video {
    width: 100%;
    padding-bottom: 56%;
  }
  &-close {
    position: absolute;
    display: block;
    top: -40px;
    right: -35px;
    color: $white;
    font-size: 30px;
    z-index: 1;
    font-weight: 700;
    line-height: 1;
    @include breakpoint (small down) {
      right: 0;
    }
    &:hover {
      color: $white;
    }
  }
  &-container {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 1024px) {
      max-width: 850px;
    }
    @media screen and (max-width: 768px) {
      max-width: 650px;
    }
    @include breakpoint (small down) {
      max-width: 95%;
    }
  }
  
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
  }
}

.widget4-wrapper {
  position: relative;
  @include breakpoint (medium down){
    padding: 0;
  }
  .stretch-div {
    @include breakpoint (medium up){
      padding: 0 1.7%;
    }
  }
  .title {
    font-size: 34px;
    line-height: 32px;
    margin-bottom: 20px;
    letter-spacing: rem-calc(0.2);
    @include breakpoint (small down) {
      font-size: 28px;
      line-height: 36px;
      margin: rem-calc(0 0 15);
      letter-spacing: .0125rem;
    }
    &.original {
      @include breakpoint (small down) {
        margin-left: rem-calc(20);
      }
    }
  }
  .img-wrapper {
    position: relative;
    img {
      width: 100%;
      &:first-of-type {
        display: none;
        @media screen and (min-width: 801px) {
          display: block;
        }
      }
      &:last-of-type {
        display: none;
        @media screen and (max-width: 800px) {
          display: block;
        }
      }
    }
    video {
      width: 100%;
      &.desktop-video {
        display: none;
        @media screen and (min-width: 801px) {
          display: block;
        }
      }
      &.mobile-video {
        display: none;
        @media screen and (max-width: 800px) {
          display: block;
        }
      }
    }
    &__text-box {
      position: absolute;
      padding: 40px;
      top: 0;
      left: 0;
      @include breakpoint (small down) {
        padding: 20px;
      }
      .title {
        color: $white;
        margin-bottom: 20px;
        @include breakpoint (small down) {
          letter-spacing: .0125rem;
        }
      }
      p {
        color: $white;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        @include breakpoint (small down) {
          letter-spacing: .0125rem;
        }
      }
      .cta {
        > span {
          color: $white;
          letter-spacing: .0125rem;
          &:after {
            background: $white;
          }
        }
      }
    }
    &__text-out {
      h2{
        padding-top: 30px;
      }
      @include breakpoint (small down) {
        padding: rem-calc(0 20 0 20);
        h2, p{
          margin-left:0;
          margin-right: 0;
          letter-spacing: .0125rem;
        }
      }
    }
  }
  .title {
    color: $black;
    font-size: 34px;
    line-height: 32px;
    margin-bottom: 10px;
    @include breakpoint (small down) {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 15px;
    }
  }
  p {
    color: $black;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .cta {
    > span {
      display: inline-block;
      font-size: 15px;
      line-height: 1;
      position: relative;
      font-family: 'MessinaSansWeb', sans-serif;
      font-weight: 500;
      color: $black;
      letter-spacing: .0125rem;
      &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 1px;
        background: $black;
      }
    }
  }
  .both-title-text {
    margin-bottom: rem-calc(35);
    @include breakpoint (small down) {
      padding: rem-calc(0 20);
    }
    .width-max-desk{
      @include breakpoint (large up) {
        width: 50%;
      }
    }
  }
  &--full,
  &--video {
    position: relative;
    img,
    video {
      width: 100%;
      &:first-of-type {
        display: none;
        @media screen and (min-width: 801px) {
          display: block;
        }
      }
      &:last-of-type {
        display: none;
        @media screen and (max-width: 800px) {
          display: block;
        }
      }
    }
    h1.title {
      p {
        font-family: "SangBleu Sunrise",Arial,sans-serif;
        font-weight: 300;
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 0;
        @include breakpoint (small down) {
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
    .cta {
      margin-top: 20px;
    }
  }
  &--video {
    h2.title {
      p {
        @include breakpoint (small down) {
          font-size: 26px;
          line-height: 32px;
        }
      }
    }
  }
}

.widget5-wrapper {
  display: flex;
  &.img-left {
    > div {
      &:last-of-type {
        order: -1;
      }
    }
  }
  @include breakpoint (medium down) {
    flex-direction: column;
    > div {
      &:last-of-type {
        order: -1;
      }
    }
  }
  > div {
    flex: 1;
    .tab-image {
      width: 100%;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all .2s ease;
      @include breakpoint (medium down) {
        max-height: 600px;
        object-fit: cover;
      }
      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 43px;
    height: 100%;
    @include breakpoint (small down) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .title-5-h1{
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: .0125rem;
    @include breakpoint (medium down) {
      margin-bottom: 40px;
    }
    @include breakpoint (small down) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 28px;
      line-height: 36px;
    }
  }
  h2.text1 {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: .0125rem;
    @include breakpoint (medium down) {
      margin-bottom: 40px;
    }
    @include breakpoint (small down) {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 28px;
      line-height: 36px;
    }
  }
  .bottom-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    * {
      outline: none;
    }
    .tab-container {
      display: flex;
      button {
        position: relative;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: .0125rem;
        text-transform: uppercase;
        flex: 1;
        padding-bottom: 10px;
        text-align: left;
        font-family: 'MessinaSansWeb', sans-serif;
        font-weight: 400;
        transition: border-bottom .1s ease;
        &:not(.active) {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: rgba(255, 255, 255, 0.8);
            bottom: -1px;
            left: 0;
          }
        }
        &.active {
          border-bottom-width: 2px !important;
        }
      }
    }
    .tab {
      padding-top: 20px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all .2s ease;
      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: .0125rem;
        font-family: "MessinaSansWeb", Arial, sans-serif;
      }
      .btn {
        margin-top: 30px;
        > span {
          padding: 13px 30px 10px;
          text-transform: uppercase;
          display: inline-block;
          letter-spacing: .0125rem;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

.widget9-wrapper {
  @include breakpoint (small down) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    letter-spacing: .0125rem;
    @include breakpoint (small down) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .two-blocks {
    &__wrapper {
      display: flex;
      margin-left: -5px;
      margin-right: -5px;
      @include breakpoint (small down) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &__single {
      flex: 1;
      margin-left: 5px;
      margin-right: 5px;
      @include breakpoint (small down) {
        margin-left: 0;
        margin-right: 0;
      }
      &:first-of-type {
        @include breakpoint (small down) {
          margin-bottom: 40px;
        }
      }
      a {
        display: block;
      }
      img:not(.set-image):not(.swatch-circle-for-product-set) {
        width: 100%;
        margin-bottom: 20px;
      }
      .single-title,
      .single-subtitle {
        font-family: 'MessinaSansWeb', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        letter-spacing: .0125rem;
      }
      .single-subtitle {
        font-family: 'MessinaSansWeb', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
        @include breakpoint (medium down) {
          width: calc(100% - 20px);
        }
      }
      .cta {
        > span {
          display: inline-block;
          font-size: 14px;
          line-height: 14px;
          position: relative;
          font-family: 'MessinaSansWeb', sans-serif;
          font-weight: 400;
          letter-spacing: .0125rem;
          &:after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 1px;
            background: #000;
          }
        }
      }
    }
  }
}

.horizontal-tabs-title{
  font-size: rem-calc(34);
  margin-left: rem-calc(40);
  margin-bottom: rem-calc(20);
  @include breakpoint (medium down){
    font-size: rem-calc(28);
    margin-left: rem-calc(20);
    margin-bottom: rem-calc(22);
  }
}
.widget10-wrapper {
  background-color: $grey6;
  &__tabs {
    display: flex;
    @include breakpoint (medium down){
      display: block;
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }
    .box-label {
      float: left;
      width: rem-calc(80);
      height: rem-calc(530);
      padding-top: rem-calc(120);
      color: $brown1;
      background-color: $grey6;
      border-left: 1px solid $brown1;
      cursor: pointer;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      display: flex;
      align-items: center;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      &:first-of-type {
        border-left: none;
      }
      &.active .arrow,
      &:first-of-type .arrow,
      &.closedLeft .arrow {
        transform: translateX(-50%) rotate(180deg);
      }
      @media screen and (max-width: 800px) {
        float: none;
        width: 100%;
        height: rem-calc(80);
        padding-top: 0;
        padding-left: 2.85714rem;
        padding-right: 2.85714rem;
        writing-mode: horizontal-tb;
        text-orientation: initial;
        border-left: none;
        border-bottom: 1px solid $brown1;
        &.active,
        &:last-of-type {
          border-bottom: none;
        }
        &.active .arrow,
        &:first-of-type .arrow,
        &.closedLeft .arrow {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      @include breakpoint (small down) {
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        height: rem-calc(70);
      }
      .arrow {
        width: rem-calc(40);
        height: rem-calc(40);
        background-color: $white;
        border-radius: 50%;
        position: absolute;
        top: rem-calc(40);
        left: 50%;
        transform: translateX(-50%);
        transition: transform 0.25s ease-out;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: rem-calc(20);
          path {
            fill: #000;
          }
        }
        @media screen and (max-width: 800px) {
          top: 50%;
          left: auto;
          right: 2.85714rem;
          transform: translateY(-50%);
          transition: transform 0.25s ease-out;
          svg {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
        @include breakpoint (small down) {
          right: rem-calc(20);
        }
      }
      span {
        font-size: rem-calc(20);
        letter-spacing: rem-calc(0.2);
        line-height: 1;
        text-transform: uppercase;
        caret-color: transparent;
      }
    }
    .box-info {
      width: 0;
      float: left;
      background-color: $grey1;
      color: #222;
      height: rem-calc(530);
      display: none;
      transition: width 0.25s ease-out;
      @media screen and (max-width: 800px) {
        width: 100%;
        height: auto;
        float: none;
        transition: none;
      }
      p {
        font-size: rem-calc(16);
        letter-spacing: rem-calc(0.2);
        line-height: rem-calc(24);
        @media screen and (max-width: 800px) {
          margin-bottom: rem-calc(25);
        }
      }
      .clickable-link {
        display: block;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .grid-x {
        padding: rem-calc(40);
        height: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.15s ease-out;
        @include breakpoint (small down) {
          padding: rem-calc(20);
        }
        &.in {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
        .text {
          display: flex;
          flex-direction: column;
          padding-right: rem-calc(40);
          height: 100%;
          @media screen and (max-width: 800px) {
            width: 100%;
            padding-right: 0;
          }
          p {
            flex: 1;
          }
          a {
            &:hover {
              border-bottom: none;
            }
            span {
              letter-spacing: rem-calc(0.2);
              position: relative;
              display: inline-block;
              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0;
                bottom: rem-calc(-1);
                background-color: $black;
              }
            }
          }
        }
        .img {
          height: 100%;
          @media screen and (max-width: 800px) {
            width: 100%;
            order: -1;
            margin-bottom: rem-calc(30);
          }
          @include breakpoint (small down) {
            margin-bottom: rem-calc(24);
          }
        }
      }
    }
  }
}

.widget11a-wrapper {
  padding: rem-calc(25 0 33);
  &__box {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 800px) {
      width: 60%;
    }
    @include breakpoint (small down) {
      width: 90%;
    }
  }
  .title {
    font-size: rem-calc(24);
    line-height: rem-calc(32);
    font-weight: 300;
    margin-bottom: 0;
    letter-spacing: 0.2px;
  }
  p {
    margin: rem-calc(10 0 0);
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    letter-spacing: 0.2px;
  }
  a {
    margin-top: rem-calc(10);
    position: relative;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -1px;
    }
    &:hover {
      border-bottom: none;
    }
    span {
      letter-spacing: 0.2px;
    }
  }
}

.widget16-wrapper {
  &.grid-container {
    @include breakpoint (small down) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .grid-x>.medium-4 {
    @include breakpoint (medium down) {
      width: 50%;
    }
    @include breakpoint (small down) {
      width: 100%;
    }
  }
  .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 10px;
    letter-spacing: .0125rem;
    @include breakpoint (small down) {
      font-weight: 300;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: .0125rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.widget7 {
  &__wrapper {
    overflow: hidden;
    * {
      outline: none;
    }
    @include breakpoint (small down) {
      padding-top: 40px;
    }
    .grid-container {
      @include breakpoint (small down) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .top {
      position: relative;
      margin-bottom: 20px;
      @include breakpoint (small down) {
        margin-bottom: 17px;
      }
      .title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        font-family: 'MessinaSansWeb', sans-serif;
        margin-bottom: 0;
        @include breakpoint (small down) {
          max-width: 60%;
        }
      }
      .arrows {
        &__wrapper {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
        &__left,
        &__right {
          display: inline-block;
          &.active {
            cursor: pointer;
            svg {
              path {
                fill: #000;
              }
            }
          }
          svg {
            max-width: 20px;
            path {
              fill: #D3CBC9;
              transition: all .2s ease;
            }
          }
        }
        &__left {
          padding-right: 7px;
        }
        &__right {
          padding-left: 7px;
        }
      }
    }
  }
  &__slide {
    > .grid-x {
        @include breakpoint (medium down) {
        flex-direction: column;
      }
      > .cell {
        @include breakpoint (medium down) {
          width: 100%;
        }
      }
    }
    .clickable-link {
      display: block;
    }
    img {
      width: 100%;
      @include breakpoint (medium down) {
        height: 100%;
        object-fit: cover;
      }
    }
    .text-box {
      padding: 34px 40px 51px;
      display: flex;
      flex-direction: column;
      @include breakpoint (medium only) {
        padding: rem-calc(40);
      }
      @include breakpoint (small down) {
        padding: 20px 0 0;
      }
      .title {
        font-size: 20px;
        line-height: 28px;
        font-family: 'MessinaSansWeb', sans-serif;
        margin-bottom: 30px;
        @include breakpoint (small down) {
          margin-bottom: 50px;
        }
      }
      div {
        flex: 1;
        @include breakpoint (medium down) {
          margin-bottom: 50px;
        }
        @include breakpoint (small down) {
          margin-bottom: 40px;
        }
        p {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
        }
        &.paragraph{
          @include breakpoint (medium down) {
            margin-bottom: rem-calc(30);
          }
        }
        &.flex-0{
          flex: 0;
          @include breakpoint (medium up) {
            margin-bottom: 0;
          }
          @include breakpoint(small down){
            margin-bottom: rem-calc(10);
          }
        }
      }
      .btn {
        padding-left: rem-calc(60);
        padding-right: rem-calc(60);
        &:not(:hover){
          background-color: transparent;
        }
        @include breakpoint (medium up) {
          margin-right: rem-calc(26);
          margin-bottom: 0;
        }
        @include breakpoint (small down) {
          padding-bottom: 15px;
          margin-bottom: rem-calc(20);
        }
      }
      .extended-cta-mobile{
        @include breakpoint (small down) {
          width: calc(100% - 1px);
        }
      }
    }
  }
}

.widget8 {
  &__wrapper {
    position: relative;
    &.img-right {
      .small-image {
        order: -1;
      }
      .on-top {
        .big-image {
          .text-box {
            left: 40px;
            @include breakpoint (medium down) {
              left: 0;
            }
          }
        }
        .small-image {
          order: -1;
        }
      }
    }
    .big-image {
      position: relative;
      max-height: 730px;
      @include breakpoint (medium down) {
        width: 100%;
      }
      &.video-in {
        @include breakpoint (large up) {
          height: 730px;
        }
      }
      img {
        width: 100%;
        @include breakpoint (medium up){
          height: 100%;
          object-fit: cover;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &.desktop-video {
          display: none;
          @media screen and (min-width: 801px) {
            display: block;
          }
        }
        &.mobile-video {
          display: none;
          @media screen and (max-width: 800px) {
            display: block;
          }
        }
      }
      .text-box {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: calc(100% - 40px);
        @include breakpoint (medium down) {
          width: 100%;
        }
        @include breakpoint (small down) {
          position: relative;
          bottom: auto;
          left: auto;
        }
        .title {
          font-size: 34px;
          line-height: 42px;
          margin-bottom: 0;
          font-family: "MessinaSansWeb", sans-serif;
          @include breakpoint (small down) {
            color: $black !important;
            font-size: 28px;
            line-height: 36px;
            padding-top: 17px;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
    .small-image {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 730px;
      @include breakpoint (medium down) {
        display: none;
      }
      &.video-in {
        @include breakpoint (large up) {
          height: 730px;
        }
      }
      img {
        max-height: 55%;
      }
      video {
        width: 100%;
        height: 100%;
        max-width: 55%;
        max-height: 55%;
        object-fit: cover;
      }
    }
    .on-top {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      @include breakpoint (small down) {
        position: relative;
        top: auto;
        left: auto;
      }
      .grid-container,
      .grid-x {
        height: 100%;
        @include breakpoint (small down) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .big-image {
        @include breakpoint (medium down) {
          width: 100%;
        }
      }
      .small-image {
        @include breakpoint (medium down) {
          display: none;
        }
      }
    }
  }
  &__tags {
    display: flex;
    flex-flow: wrap;
    @include breakpoint (small down) {
      flex-flow: nowrap;
      overflow-x: auto;
      padding-left: 15px;
      padding-bottom: 20px;
    }
    a {
      outline: none;
    }
    button {
      margin-top: 20px;
      margin-bottom: 0;
      margin-right: 15px;
      font-size: 15px;
      line-height: 15px;
      &.white-transparent {
        &:hover {
          border: 1px solid $white !important;
          background-color: $white !important;
          color: $black;
        }
      }
      &.black-transparent {
        &:hover {
          border: 1px solid $black !important;
          background-color: $black !important;
          color: $white;
        }
      }
      @include breakpoint (small down) {
        font-size: 15px;
        line-height: 15px;
        border: 1px solid $black !important;
        color: $black !important;
        &:hover {
          background-color: transparent !important;
        }
      }
    }
    > div {
      @include breakpoint (small down) {
        flex: none;
      }
      &:last-of-type {
        button {
          margin-right: 0;
          @include breakpoint (small down) {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.pd-widget-anchor-list {
  background: $brown1;
  text-align: center;
  font-size: 0;
  min-height: 10px;

  .pd-content-items {
    text-align: center;
    white-space: nowrap;
    overflow-x: auto;
    @include scrollbars(0px, transparent, transparent);

    @include breakpoint (medium down) {
      margin-right: rem-calc(-40);
    }
    @include breakpoint (small down) {
      margin-right: rem-calc(-15);
    }

    .pd-item {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      margin: 0 50px 0 0;

      &:last-child {
        margin-right: 25px;
      }

      .pd-item-link {
        padding: 10px 0;
        display: block;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        color: $white;
        letter-spacing: rem-calc(0.2);
        font-weight: 400;
      }
    }
  }
}
.pd-widget-anchor-marker {
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
  background: transparent;
  margin: 0;
  padding: 0;
}

.breadcrumbs {
  padding-top: 15px;
  padding-bottom: 15px;
  > div {
    display: flex;
  }
  a {
    line-height: 1;
    span {
      line-height: 1;
    }
  }
  .separator {
    line-height: 1;
    font-weight: 600;
    padding: 0 3px;
  }
}

.widget13-wrapper {
  .title {
    font-size: 24px;
    margin-bottom: 25px;
    @include breakpoint (medium down) {
      width: 83.33333%;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint (small down) {
      width: 100%;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .three-blocks {
    &__wrapper {
      overflow: hidden;
      > div {
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        @include breakpoint (medium down) {
          padding-left: 2.85714rem;
          padding-right: 2.85714rem;
          flex-direction: column;
          margin-left: 0;
          margin-right: 0;
        }
        @include breakpoint (small down) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    &__single {
      position: relative;
      flex: 1;
      margin-left: 5px;
      margin-right: 5px;
      @include breakpoint (medium down) {
        width: 83.33333%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        @include breakpoint (small down) {
          width: 100%;
        }
      }
      .clickable-link {
        display: block;
      }
      img {
        width: 100%;
      }
      .text-box {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        max-width: 300px;
        .single-subtitle {
          font-size: 11px;
          line-height: 11px;
          text-transform: uppercase;
          margin-bottom: 5px;
        }
        .single-title {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 20px;
          font-weight: 300;
        }
        p {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 25px;
        }
        .cta {
          margin-top: 15px;
          display: inline-block !important;
        }
      }
    }
  }
  .bottom-box {
    text-align: center;
    padding-bottom: rem-calc(15);
    .title {
      font-size: 22px;
      line-height: 26px;
      font-weight: 300;
      margin-top: 40px;
      margin-bottom: 0;
      @include breakpoint (small down) {
        line-height: 28px;
      }
    }
    > div {
      display: flex;
      justify-content: center;
      @include breakpoint (small down) {
        flex-direction: column;
      }
      .btn {
        margin-top: 37px;
        margin-right: 30px;
        @include breakpoint (small down) {
          width: 100%;
          margin-right: 0;
        }
        &:last-of-type {
          margin-right: 0;
          @include breakpoint (small down) {
            margin-top: 20px;
          }
        }
        > span {
          @include breakpoint (small down) {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }
  }
}

.pd-widget6 {
  padding: rem-calc(90 0);
  .widget-title {
    font-size: rem-calc(20);
    line-height: rem-calc(38);
    margin-bottom: rem-calc(20);
    position: relative;
  }
  .widget-subtitle {
    font-size: rem-calc(16);
    margin-bottom: rem-calc(20);
    font-weight: 300;
    .widget-selected-color {
      font-weight: 400;
    }
  }
  .arrows{
    &__wrapper {
      position: absolute;
      top: rem-calc(11);
      right: 9%;
      @include breakpoint (small down) {
        display: none;
      }
    }
    &__left,
    &__right {
      display: inline-block;
      &.active {
        cursor: pointer;
        svg {
          path {
            fill: #000;
          }
        }
      }
      svg {
        max-width: 20px;
        path {
          fill: #D3CBC9;
          transition: all .2s ease;
        }
      }
    }
    &__left {
      padding-right: 5px;
    }
    &__right {
      padding-left: 5px;
    }
  }
  .colors-wrapper{
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    position: relative;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.smooth {
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
    }
  }
  .pd-color-pill {
    border: 1px solid $black;
    padding: rem-calc(12) rem-calc(30);
    margin-right: rem-calc(15);
    border-radius: rem-calc(30);
    font-size: rem-calc(15);
    &.black {
      color:black;
    }
  }

  .pd-products-wrapper {
    margin-top: rem-calc(20);
    display: flex;
    @include breakpoint (medium down){
      @include scrollbars(2px, #000, #DDD);
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      padding-bottom: 2px;
    }
    @include scrollbars(2px, #000, #DDD);

    .pd-product-slide{
      flex: 1 1;
      @include breakpoint (large up){
        max-width: 33%;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.pd-product-slide {
  margin-right: 65px;

  @include breakpoint (medium down) {
    margin-right: 40px;
    min-width: calc(33.333333% - 40px);
  }
  @include breakpoint (small down) {
    margin-right: rem-calc(7);
    min-width: calc(50% - 3.5px);
    margin-bottom: rem-calc(30);
  }
  
  .pd-slide-title {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    font-weight: 300;
    min-height: rem-calc(24);
    color: $black;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 10px;
    margin: 0;
    text-align: left;
  }

  .pd-prod {
    .pd-content-img {
      overflow: hidden;
      margin-bottom: 15px;

      @include breakpoint (small down) {
        margin-bottom: 10px;
      }

      .pd-image {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }

    .pd-prod-body {
      position: relative;
      padding-right: 100px;

      @include breakpoint (medium down) {
        padding-right: 0;
      }

      .pd-content-promo {
        display: block;

        .hidden {
          display: none !important;
        }

        .js-new-msg {
          font-size: rem-calc(10);
          line-height: rem-calc(10);
          letter-spacing: rem-calc(0.2);
          text-align: left;
          color: $green;
          padding-bottom: 7px;
          margin: 0;

          @include breakpoint (small down) {
            padding-bottom: 5px;
          }
        }

        .js-green-msg {
          font-size: rem-calc(10);
          line-height: rem-calc(10);
          letter-spacing: rem-calc(0.2);
          text-align: left;
          color: $green;
          padding-bottom: 7px;
          margin: 0;

          @include breakpoint (small down) {
            padding-bottom: 5px;
          }
        }

        .marketing-budge {
          font-size: rem-calc(10);
          line-height: rem-calc(10);
          letter-spacing: rem-calc(0.2);
          text-align: left;
          color: $sale;
          padding-bottom: 7px;
          margin: 0;

          @include breakpoint (small down) {
            padding-bottom: 5px;
          }
        }
      }

      .pd-content-prod-name {
        .pd-prod-name {
          display: block;
          font-size: rem-calc(14);
          line-height: rem-calc(20);
          letter-spacing: rem-calc(0.2);
          text-align: left;
          color: $black;
          padding-bottom: 10px;
          margin: 0;
          white-space: normal;

          @include breakpoint (small down) {
            font-size: rem-calc(12);
            line-height: rem-calc(16);
            padding-bottom: 5px;
            white-space: normal;
          }
        }
      }

      .pd-content-price {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        min-width: 100px;
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        letter-spacing: rem-calc(0.2);
        color: $black;
        text-align: right;
        font-weight: 300;
        padding-bottom: 10px;

        @include breakpoint (medium down) {
          position: relative;
          top: auto;
          right: auto;
          text-align: left;
        }
        @include breakpoint (small down) {
          font-size: rem-calc(12);
          line-height: rem-calc(12);
        }

        .price-container {
          .light {
            color: #999;
            font-size: rem-calc(14);
            font-weight: 300;
          }
          .sales {
            display: flex;
            flex-direction: column;
            justify-content: right;
            @include breakpoint (small down) {
              padding-bottom: rem-calc(6);
            }
          }
          .sale {
            .light {
              color: #A83151;
            }
          }
          .club{
            .light {
              color: #EA8F18;
            }
          }
        }

        &.double-price-wrapper{
          .main-price-container{
            .sales{
              @include breakpoint (medium down) {
                display: flex;
                flex-direction: row;
                justify-content: left;
                margin-top: rem-calc(5);
              }
              .double-currency-sales, .double-currency-list{
                font-size: rem-calc(12);
                line-height: rem-calc(14);
                font-weight: 300;
                @include breakpoint (medium down) {
                  display: inline-block;
                  margin-left: rem-calc(5);
                  font-size: rem-calc(11);
                }
              }
            }
            .base-price-container{
              @include breakpoint (medium down) {
                display: inline-block;
                margin-top: rem-calc(5);
              }
              span {
                @include breakpoint (medium down) {
                  font-size: rem-calc(12);
                }
              }
              .double-currency-sales, .double-currency-list{
                font-size: rem-calc(12);
                line-height: rem-calc(14);
                font-weight: 300;
                @include breakpoint (medium down) {
                  font-size: rem-calc(11);
                }
              }
            }
          }
        }
        

        .priceclub, .strike-through {
          color: $grey10;
          display: block;
          padding-bottom: 0;
          font-weight: 300;
          
          @include breakpoint (small down){
            display: inline-block;
          }
        }
        .sale {
            color: #A83151;
        }
        .club {
            color: #EA8F18;
        }
        .extraordinaryprice {
            color: #C75015;
        }

        .value {
          font-weight: 300;
          letter-spacing: rem-calc(0.2);
        }
      }

      .pd-content-n-colors {

        .pd-n-colors {
          display: block;
          font-size: rem-calc(12);
          line-height: rem-calc(12);
          letter-spacing: rem-calc(0.2);
          color: $black;
          text-align: left;
        }
      }
    }
  }
}


.pd-widget-14 {
    padding-top: 35px;

    .pd-container-full {
        max-width: 100%;
    }

    .pd-order-1 {
        order: 1;
    }
    .pd-order-2 {
        order: 2;
    }

    .container-tabs {
        position: relative;
        @include breakpoint (medium down) {
            padding-top: calc(100vw + 35px);
        }
        @include breakpoint (small down) {
            padding-top: 0;
        }

        &.pd-tabs-true:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 34px;
            left: 40px;
            width: calc(100% - 80px);
            height: 1px;
            background: $divider;

            @include breakpoint (medium down) {
                top: calc(100vw + 69px);
            }
            @include breakpoint (small down) {
                top: 34px;
                left: 15px;
                width: calc(100% - 15px);
            }
        }

        ul.tabs {
            display: flex;
            position: relative;
            z-index: 2;
            margin-bottom: 40px;
            overflow-x: auto;
            @include scrollbars(0px, transparent, transparent);

            @include breakpoint (small down) {
                margin-right: -15px;
            }
    
            li {
                flex-grow: 1;
                flex-basis: 0;
                padding-bottom: 6px;
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.2);
                white-space: nowrap;
                padding-right: 45px;
            }
        }

        .pd-content-sections {
            .pd-section {
                display: none;
                &.current {
                    display: block;
                }
            }
        }
    }

    .pd-widget-14-element {
        position: static;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent !important;

        @include breakpoint (medium down) {
            padding-top: 0;
        }
        @include breakpoint (small down) {
            padding-top: 0;
        }

        .pd-technical-info {
            padding-bottom: 40px;
        }

        .pd-col-left {
            padding-bottom: 0;
            
            @include breakpoint (large up) {
                padding-top: 0;
            }
        }
    }
}

.pd-widget-14-element {
    position: relative;

    @include breakpoint (medium down) {
        padding-top: calc(100vw + 40px);
    }
    @include breakpoint (small down) {
        padding-top: 40px;
    }

    .pd-col-left {
        padding-bottom: 10px;
        @include breakpoint (large up) {
            padding-top: 40px;
        }
    }

    .pd-col-left,
    .pd-col-right {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .pd-content-text {
        padding-right: 27%;
        padding-bottom: 40px;

        @include breakpoint (medium down) {
            padding-bottom: 80px;
        }
        @include breakpoint (small down) {
            padding-bottom: 40px;
            padding-right: 0;
        }

        .pd-text-title {
            display: block;
            font-size: rem-calc(20);
            line-height: rem-calc(28);
            color: $black;
            text-align: left;
            letter-spacing: rem-calc(0.2);
            margin: 0;
            padding-bottom: 10px;
        }

        .pd-text {
            display: block;
            font-family: $body-font-family-cursive;
            font-weight: 300;
            font-size: rem-calc(24);
            line-height: rem-calc(32);
            color: $black;
            text-align: left;
            letter-spacing: rem-calc(0.2);
            margin: 0;
            padding: 0;
        }
    }

    .pd-section-image-mobile {
        display: none;
        margin-bottom: 40px;

        @include breakpoint (small down) {
            display: block;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include breakpoint (large up) {
            height: 730px;
          }
          &.desktop-video {
            display: none;
            @media screen and (min-width: 801px) {
              display: block;
            }
          }
          &.mobile-video {
            display: none;
            @media screen and (max-width: 800px) {
              display: block;
            }
          }
        }

        .pd-content-img {
            margin: 0 rem-calc(-40);
            padding-bottom: 74%;
            position: relative;

            @include breakpoint (small down) {
                margin: 0 rem-calc(-15);
            }

            .pd-img {
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .pd-technical-info {
        margin-top: auto;
        padding-bottom: 0;

        .pd-technical-title {
            display: block;
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            color: $black;
            text-align: left;
            padding-bottom: 30px;
            margin: 0;
        }

        .pd-technical-items {
            .pd-technical-item {
                font-size: 0;
                text-align: left;
                padding-bottom: 20px;

                .pd-content-icon {
                    display: inline-block;
                    vertical-align: middle;
                    width: 40px;
                    margin-right: 15px;
                    @include breakpoint (small down) {
                        display: block;
                        margin-right: 0;
                    }

                    .pd-icon {
                        display: block;
                        width: 100%;
                    }
                }

                .pd-item-text {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    font-weight: 300;
                    color: $black;
                    text-align: left;
                    padding: 0;
                    margin: 0;
                    
                    @include breakpoint (small down) {
                        display: block;
                        padding-top: 5px;
                    }
                }
            }
        }

        .pd-content-cta {
            padding-top: 10px;

            .button {
                background: transparent;
                margin-bottom: 0;

                &:hover {
                    background: $black;
                }
            }
        }
    }

    .clickable-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .pd-col-right {
        margin-right: rem-calc(-40);
        
        @include breakpoint (medium down) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            height: 100vw;
            margin: 0;
        }
        @include breakpoint (small down) {
            display: none;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include breakpoint (large up) {
            height: 730px;
          }
          &.desktop-video {
            display: none;
            @media screen and (min-width: 801px) {
              display: block;
            }
          }
          &.mobile-video {
            display: none;
            @media screen and (max-width: 800px) {
              display: block;
            }
          }
        }

        .pd-content-img {
            overflow: hidden;

            @include breakpoint (medium down) {
                height: 100%;
                position: relative;
            }

            .pd-img {
                display: block;
                width: 100%;

                @include breakpoint (medium down) {
                    height: 100%;
                    position: relative;
                    object-fit: cover;
                }
            }
        }
    }
    .pd-order-1 {
        .pd-col-right {
            margin-right: 0;
            margin-left: rem-calc(-40);
            @include breakpoint (medium down) {
                margin-left: 0;
            }
        }
    }
    .pd-order-2 {
        padding-left: 40px;
        @include breakpoint (medium down) {
            padding-left: 0;
        }
    }
}

.pd-widget-17 {
    padding: 50px 0;

    .pd-container-full {
        max-width: 100%;
    }

    .container-tabs {
        position: relative;

        .pd-grid-container-right {
            @include breakpoint (medium down) {
                max-width: none;
                padding-right: 0;
            }

            @include breakpoint (small down) {
                padding-right: rem-calc(15);
            }
        }

        .pd-content-section-title {
            padding-bottom: 60px;

            @include breakpoint (small down) {
                padding-bottom: 35px;
            }

            .pd-section-title {
                display: block;
                font-size: rem-calc(34);
                line-height: rem-calc(42);
                letter-spacing: rem-calc(0.2);
                color: $black;
                text-align: center;
                margin: 0;
                padding: 0;

                @include breakpoint (small down) {
                    font-size: rem-calc(28);
                    line-height: rem-calc(36);
                    text-align: left;
                }
            }
        }

        ul.tabs {
            display: flex;
            position: relative;
            z-index: 2;
            margin-bottom: 40px;
            overflow-x: auto;
            @include scrollbars(0px, transparent, transparent);
    
            li {
                flex-grow: 1;
                flex-basis: 0;
                padding-bottom: 6px;
                font-size: rem-calc(14);
                letter-spacing: rem-calc(0.2);
                white-space: nowrap;
                padding-right: 90px;
                position: relative;

                @include breakpoint (small down) {
                    padding-right: rem-calc(10);
                }

                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    bottom: -2px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $divider;
                }

                .pd-tab-link-content-img {
                    width: 54px;
                    margin-bottom: 12px;
                    opacity: 0.5;

                    @include breakpoint (small down) {
                        display: none;
                    }

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                &.current {
                    .pd-tab-link-content-img {
                        opacity: 1;
                    }
                }

                .pd-tab-link-text {
                    display: block;

                    .pd-label-description {
                        display: inline-block;
                        
                        @include breakpoint (small down) {
                            display: none;
                        }
                    }
                }
            }
        }

        .pd-content-sections {
            .pd-section {
                display: none;
                &.current {
                    display: block;
                }
            }
        }
    }

    // Override internal modules
    .widget2-full-wrapper {
        .widget2-full__cards>div p {
            font-size: rem-calc(16);
            font-weight: 300;

            @include breakpoint (small down) {
                width: 100%;
            }
    
            &.pd-text-bold {
                font-weight: 400;
                margin-bottom: 18px;
                font-weight: 400;
            }
        }

        .top .title {
            font-family: $body-font-family-cursive;
            font-weight: $global-weight-light;
            font-size: rem-calc(24);
            line-height: rem-calc(32);
            letter-spacing: rem-calc(0.2);
            color: $black;
            margin-bottom: 0;
            font-size: rem-calc(26);
    
            @include breakpoint (small down) {
                font-size: rem-calc(24);
                line-height: rem-calc(32);
            }
        }

        & > .grid-container > .grid-x > .cell {
            @include breakpoint (small down) {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .widget2-wrapper {
        .three-blocks__single {
            .single-title,
            .single-subtitle,
            p {
                font-size: rem-calc(16);
            }
        }
        .title,
        .grid-x>div {
            @include breakpoint (medium down) {
                transform: translateX(0);
            }
        }
        .three-blocks__wrapper {
            @include breakpoint (medium down) {
                margin-left: 0;
            }
        }

        & > .grid-x > .cell {
            @include breakpoint (small down) {
                width: 100%;
                margin-left: 0;
            }
        }
    }
    
}

.page-designer-reference {
  transition: padding-top .3s ease;
  &.top-strip-in {
    padding-top: 40px;
  }

  #countryside-edit {
    .experience-assets-widget1 {
      cursor: pointer;
    }
  }
}

.top-strip,
.widget11b-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 40px;
  @include breakpoint (small down) {
    z-index: 900;
  }
  * {
    outline: none;
  }
  &__slide {
    text-align: center;
    margin: rem-calc(14 0 13);
    font-size: rem-calc(11) !important;
    line-height: 1 !important;
    letter-spacing: 0.2px !important;
    text-transform: uppercase;
    @include breakpoint (small down) {
      font-size: rem-calc(10) !important;
    }
    * {
      margin-bottom: 0;
      font-size: rem-calc(11) !important;
      line-height: 1 !important;
      letter-spacing: 0.2px !important;
      @include breakpoint (small down) {
        font-size: rem-calc(10) !important;
      }
    }
    a {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: -1px;
      }
      &:hover {
        border-bottom: none;
      }
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    width: rem-calc(16);
    height: rem-calc(16);
    right: 40px;
    top: 50%;
    transform: translateY(-9px);
    @include breakpoint (small down) {
      right: 15px;
    }
  }
}

.top-strip {
  &__slide {
    a {
      &:not(.no-underline):not(.no-border):not(.force-hover) { 
        border-bottom: none !important;
      }
      &:after {
        background-color: transparent !important;
      }
    }
    @include breakpoint (small down) {
      position: relative;
      height: 40px;
      margin: 0;
      text-align: left;
      padding: 0 13px;
      p {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        padding-right: 60px;
        font-size: 10px !important;
        line-height: 14px !important;
        font-weight: 300;
      }
      a {
        text-decoration: none;
        font-size: 10px !important;
        line-height: 14px !important;
        font-weight: 300;
        &:after {
          content: none;
        }
      }
    }
  }
}

// Search bar widget
.site-search{
  &.search-widget{
    height: unset;
    overflow-y: unset;
    position: relative;
    top: unset;
    right: unset;
    padding: 120px 0;
    @include breakpoint (medium down){
      padding: 60px 0;
    }
    .searchInputContainer{
      position: relative;
      .search-bar-icon{
        position: absolute;
        top: rem-calc(10);
        left: 0;
      }
      input[type="search"]{
        margin: 0;
        border-bottom: 1px solid $black;
        padding-left: rem-calc(20);
      }
      .searchInputContainer-delete-widget{
        outline: none;
        display:none;
        font-size: rem-calc(10);
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: rem-calc(10);
        padding-bottom: rem-calc(11);
        padding-top: rem-calc(16);
        position:absolute;
        right:0;
        text-align: right;
        text-transform: uppercase;
        top:0;

        @media screen and (max-width:1024px) {
          top: auto;
          bottom: 13px;
          right: 16px;
          padding: 0;
        }
      }
    }
    .suggestions{
      position: relative;
      top: unset;
      right: unset;
      width: unset;
      .product-tiles-compact-wrapper{
          padding-bottom: 0;
      }
    }
    .js-desk-width{
      transition: width 0.8s ease;
      &.smooth-grow{
        @include breakpoint (large up){
          width: 80%;
        }
      }
    }
  }
}

.widget11b-wrapper {
  position: relative;
  z-index: auto;
  height: 60px;
  &__slider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -52%);
    margin: 0;
    width: 100%;
    text-align: center;
    p,
    a {
      text-transform: none;
      font-size: rem-calc(16) !important;
      letter-spacing: 0.2px !important;
      line-height: 1 !important;
    }
    a {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: -1px;
      }
      &:hover {
        border-bottom: none;
      }
    }
  }
}

.pd-best-sellers-full-wrapper {
  overflow: hidden;
  .grid-container {
    &:last-of-type {
      max-width: none;
      padding-right: 0;
      @include breakpoint (small down) {
        padding-left: 23px;
      }
    }
    @include breakpoint (small down) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .top {
    position: relative;
    margin-bottom: 20px;
    .title {
      font-size: 20px;
      line-height: 28px;
      min-height: 5px;
      letter-spacing: .0125rem;
      margin-bottom: 0;
      font-weight: 400;
    }
    .arrows {
      &__wrapper {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        @include breakpoint (small down) {
          display: none;
        }
      }
      &__left,
      &__right {
        display: inline-block;
        &.active {
          cursor: pointer;
          svg {
            path {
              fill: #000;
            }
          }
        }
        svg {
          max-width: 20px;
          path {
            fill: #D3CBC9;
            transition: all .2s ease;
          }
        }
      }
      &__left {
        padding-right: 5px;
      }
      &__right {
        padding-left: 5px;
      }
    }
  }
  .best-sellers-full {
    &__cards {
      display: flex;
      margin-left: -5px;
      margin-right: -5px;
      width: calc(100% + 3px);
      transform: translateX(3px);
      overflow-x: auto;
      position: relative;
      user-select: none;
      @include scrollbars(2px, #000, #DDD);
      &.smooth {
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
      }
      > div {
        margin-left: 5px;
        margin-right: 5px;
        flex: none;
        width: 425px;
        padding-bottom: 30px;
        position: relative;
        @media screen and (max-width: 1440px) {
          width: 320px;
        }
        @media screen and (max-width: 1280px) {
          width: 355px;
        }
        @media screen and (max-width: 1024px) {
          width: 245px;
        }
        @media screen and (max-width: 800px) {
          width: 265px;
        }
        @include breakpoint (small down) {
          width: 232px;
        }
        &:first-of-type {
          margin-left: 0;
        }
        .single-title {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: .0125rem;
        }
        img {
          width: 100%;
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: .0125rem;
          max-width: 90%;
        }
        .cta {
          > span {
            display: inline-block;
            margin-top: 15px;
            font-size: 14px;
            line-height: 14px;
            position: relative;
            font-weight: 400;
            letter-spacing: .0125rem;
            &:after {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 1px;
              background: #000;
            }
          }
        }
      }
    }
  }

  &.pd-product-slider {
    padding: 60px 0;
    
    @include breakpoint (small down) {
      padding: 50px 0;
    }

    .best-sellers-full {
      &__cards {
        > div {
          max-width: 425px;
          @media screen and (max-width: 1440px) {
            max-width: 390px;
          }
          @media screen and (max-width: 1280px) {
            max-width: 400px;
          }
          @media screen and (max-width: 1199px) {
            max-width: 380px;
          }
          @media screen and (max-width: 1024px) {
            max-width: 305px;
          }
          @media screen and (max-width: 800px) {
            max-width: 235px;
          }
          @include breakpoint (small down) {
            max-width: calc(50vw - 16px);
          }
        }
      }
    }
  }
}
.cta-widget {
  margin: rem-calc(20 0);
  display: block;
  text-align: center;
  > span {
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    letter-spacing: rem-calc(0.2);
    font-family: 'MessinaSansWeb', sans-serif;
    font-weight: 400;
    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
    }
  }
}

// Grid 1 column and Grid 2 colummns
.product-grid-listing-pd{
  @include breakpoint (large up) {
    margin: rem-calc(0 -24);
  }
  .product-cell-pd{
    @include breakpoint (large up) {
      padding: rem-calc(0 24);
    }
  }
}
.pd-product-card-widget{
  &.product-image-style-normal{
    width: 100%;
    .tile-image{
      width: 100%;
    }
  }
  &.product-image-style-horizontal{
    width: 100%;
    .fnProduct-imageBox:not(.fnProduct-imageBoxEditorial) {
      @include breakpoint (large up){
        width: max-content;
        margin: 0 auto;
        .tile-image{
          height: 45.5vw;
          width: unset;
          max-width: 100%;
          @media screen and (min-width: 1500px){
            height: 46vw;
          }
          @media screen and (min-width: 1900px){
            height: 46.5vw;
          }
          @media screen and (min-width: 2000px){
            height: 937px;
          }
        }
      }
    }

    .fnPlpEditorialBox {
      .fnProduct-editorial-description {
          // Classes added through content asset
          .plp-editorial-description {
              background-color: #E7E7E7;
              padding: rem-calc(40);
              font-size: rem-calc(20);
              line-height: rem-calc(28);
              font-weight: 300;
              height: 100%;

              @include breakpoint (small down) {
                  padding: rem-calc(16);
                  font-size: rem-calc(12);
                  line-height: rem-calc(18);
              }

              &-title {
                  display: block;
                  font-family: 'SangBleu Sunrise';
                  font-size: rem-calc(24);
                  margin-bottom: rem-calc(30);
                  font-weight: 300;

                  @include breakpoint (small down) {
                      font-size: rem-calc(18);
                      margin-bottom: rem-calc(20);
                  }
              }
          }
          // End content asset classes
      }

      .fnProduct-imageBoxEditorial {
          width: 50%;
          margin: 0;
      }
    }
  }
  &.product-image-style-vertical{
    width: 100%;
    @include breakpoint (large up){
      width: 70.5%;
      padding: 0;
      margin: 0 auto;
    }
    .tile-image{
      width: 100%;
    }
  }
}

.poster-widget {
  position: relative;
  > a {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &:first-of-type {
      display: none;
      @media screen and (min-width: 801px) {
        display: block;
      }
    }
    &:last-of-type {
      display: none;
      @media screen and (max-width: 800px) {
        display: block;
      }
    }
  }
  .text-box {
    position: absolute;
    width: auto;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include breakpoint (small down){
      width: 100%;
      top: auto;
      transform: translateX(-50%);
      bottom: rem-calc(30);
    }
    h2 {
      text-align: center;
      font-size: rem-calc(34);
      letter-spacing: rem-calc(0.2);
      line-height: rem-calc(42);
      margin-bottom: 0;
      @include breakpoint (small down){
        font-size: rem-calc(28);
        line-height: rem-calc(36);
      }
    }
  }    
}

.pd-widget6 {
  .pd-price-with-omnibus {
    .price-container {
      @include breakpoint (large up) {
        display: flex;
        flex-flow: column-reverse;
        font-size: rem-calc(14);
        line-height: rem-calc(14);
        
        .strike-through {
          padding-top: rem-calc(3);
        }
      }
      @include breakpoint (medium down){
        font-size: rem-calc(12);
        display: flex;
        justify-content: flex-end;
        flex-flow: row-reverse;
        line-height: rem-calc(12);
        padding-top: rem-calc(5);

        .strike-through {
          font-size: rem-calc(12);
          line-height: rem-calc(12);
          margin-left: rem-calc(10);
        }
      }
    }
  }

  .pd-price-info-pdp-container {
    .pd-omnibus {
      margin-top: rem-calc(-10);
    }
  }
}

.pd-price-info-pdp-container {
  .pd-omnibus {
    color: $grey8;
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    margin-bottom: rem-calc(10);
  } 
}