.breadcrumb-wrap {
    text-align: left;
    padding: rem-calc(50 0 19 20);
    @include breakpoint (medium up) {
        padding-left: rem-calc(40);
    }
    .breadcrumb {
        list-style: none;
        margin-bottom: 0;
        margin-left: 0;
        font-size: rem-calc(12);
        letter-spacing: 0.2px;
        line-height: rem-calc(12);
        z-index: 1;
        position: relative;
        &-item {
            display: inline;
            &:last-child a {
                color: $grey10;
            }
            .disabled{
                color: #999999;
                text-decoration: none;
                cursor: default;
                pointer-events: none;
            }
        }
    }
}

.breadcrumb-sovra {
    padding: rem-calc(19 0 19 20);
}