.tingle-enabled{
    top: 0 !important;
}
.localities-input-wrapper {
    width: 100%;

    .localities-input-container {
        display: block;
    }
}

.localities-container {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    font-family: $body-font-family;
    z-index: 3060;
    box-sizing: border-box;
    position: absolute;
    background-color: #fff;
    margin: -1px 0 0;
    padding: 0;
    list-style-type: none;

    &.localities-hidden {
        display: none;
    }

    .localities-item {
        height: auto;
        cursor: pointer;
        padding: rem-calc(16);
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: rem-calc(14);
        line-height: rem-calc(24);
        font-family: Arial, sans-serif;
        border: solid rem-calc(1) $black;
        &:not(:first-of-type) {
            border-top: none;
        }
    }

    .localities-item-selected .localities-icon,
    .localities-item:hover .localities-icon {
        filter: brightness(0);
    }

    .localities-item-selected,
    .localities-item:hover {
        background-color: #edf4f9;
        
    }
}

.localities-empty-button {
    display: block;
    position: absolute;
    top: rem-calc(20);
    right: rem-calc(16);
    width: rem-calc(15);
    height: rem-calc(15);
    background-color: transparent;
    background-image: url("../../images/close-cross.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}