/* ELEVATION */
$styleList-elevation-xOffSet: (
    small: 4,
    medium: 8,
);
$styleList-elevation-yOffSet: (
    small: 4,
    medium: 8,
);
$styleList-elevation-blur: (
    small: 20,
    medium: 20,
);
$styleList-elevation-spread: (
    small: 0,
    medium: 0,
);
$styleList-elevation-color: (
    small: rgba(0, 0, 0, .05),
    medium: rgba(0, 0, 0, .15),
);

@function getElevationXOffSet($value) {
  @return map-get($styleList-elevation-xOffSet, $value);
}
@function getElevationYOffSet($value) {
  @return map-get($styleList-elevation-yOffSet, $value);
}
@function getElevationBlur($value) {
  @return map-get($styleList-elevation-blur, $value);
}
@function getElevationSpread($value) {
  @return map-get($styleList-elevation-spread, $value);
}
@function getElevationColor($value) {
  @return map-get($styleList-elevation-color, $value);
}

@each $typeStyle in map-keys($styleList-elevation-xOffSet) {
  .cc-elevation-#{$typeStyle} {
    box-shadow: rem-calc(getElevationXOffSet($typeStyle)) rem-calc(getElevationYOffSet($typeStyle)) rem-calc(getElevationBlur($typeStyle)) rem-calc(getElevationSpread($typeStyle)) getElevationColor($typeStyle);
  }
}