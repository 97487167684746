@import 'typoVariables';
strong,
.strong,
.font-strong {
  font-weight: $global-weight-bold;
}

.dark,
.font-dark {
  font-weight: $global-weight-dark;
}

.font-normal {
  font-weight: $global-weight-normal;
}

.font-light {
  font-weight: $global-weight-light;
}

.text-capitalized {
  text-transform: capitalize !important;
}

// Headings
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, p, .p {
  font-family: $body-font-family;
  color: $black;
}

// Header Modifiers
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  &.inline {
    display: inline;
  }
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.underline {
  text-decoration: underline;
}
.no-underline {
  text-decoration: none;
}
address {
  font-style: normal;
}

p,
span,
a,
button,
.button,
label,
input,
dl {
  letter-spacing: 0.0125rem;

  /* TODO: DELETE ALL TYPOS NOT RELATED TO NEW UIKIT FOR RESTYLE */

  &.small {
    font-size: rem-calc(12);
  }

  &.medium {
    font-size: rem-calc(16);
  }

  &.regular {
    font-size: rem-calc(14);
  }

  &.big {
    font-size: rem-calc(20);
  }

  &.huge {
    font-size: rem-calc(35);
  }

  &.light {
    font-weight: $global-weight-light;
  }

  &.no-margin {
    margin: 0;
  }

  &.small-margin {
    margin-bottom: $paragraph-margin-bottom / 2;
  }
}

a {
  font-weight: $global-weight-normal;
  text-decoration: none;
  transition: all 0.35s ease;

  // Hover state
  &.force-hover,
  &.underline {
    text-decoration: none;
    border-bottom: 1px solid $black;
    transition: all 0.35s ease;

    &:not(.no-hover):not(.button):hover {
      border-bottom-color: transparent;
    }
  }
  &.navigation-links {
    text-decoration: none;
    border: none;
    border-bottom: 1px solid transparent;
    transition: all 0.35s ease;
    padding-bottom: rem-calc(3);

    &:not(.no-hover):not(.button):hover {
      border-bottom: 1px solid $black;
    }
  }
}

span {
  &.navigation-links {
    text-decoration: none;
    border: none;
    border-bottom: 1px solid $black;
    transition: all 0.35s ease;

    &:not(.no-hover):not(.button):hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.cc-terms-conditions{
  font-weight: $global-weight-normal;
  text-decoration: underline;
  margin-left: rem-calc(2);
}

// generate colored links
@mixin brand-links($palette) {
  a {
    @each $color, $hex in $palette {
      &.cc-text-#{$color} {
        color: $hex;

        // Hover state
        &:not(.no-hover):hover,
        &.force-hover,
        &.underline {
          border-color: $hex;
        }
      }
    }
  }
}

.strike-through {
  text-decoration: line-through;
}

// generate colored paragraphs
@mixin brand-text($palette) {
  div,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  i,
  sup,
  label,
  input {
    @each $color, $hex in $palette {
      &.text-#{$color} {
        color: $hex;
      }
    }
  }
}

/* NEW TYPOGRAPHY */

@each $typeStyle in map-keys($typeStyleList-fontSize) {
    .cc-#{$typeStyle} {
        font-size: rem-calc(getFontSize($typeStyle));
        line-height: rem-calc(getLineHeight($typeStyle));
        letter-spacing: rem-calc(getLetterSpacing($typeStyle));
        font-weight: $global-weight-normal;
        @include breakpoint(large up) {
            font-size: rem-calc(getFontSizeDesktop($typeStyle));
            line-height: rem-calc(getLineHeightDesktop($typeStyle));
            letter-spacing: rem-calc(getLetterSpacing($typeStyle));
        }
    }
}
@each $typeUpperStyle in map-keys($typeStyleList-upperFontSize) {
  .cc-#{$typeUpperStyle} {
    font-size: rem-calc(getUpperFontSize($typeUpperStyle));
    line-height: rem-calc(getUpperLineHeight($typeUpperStyle));
    letter-spacing: rem-calc(getUpperLetterSpacing($typeUpperStyle));
    font-weight: $global-weight-normal;
    text-transform: uppercase;
    @include breakpoint(large up) {
        font-size: rem-calc(getUpperFontSizeDesktop($typeUpperStyle));
        line-height: rem-calc(getUpperLineHeightDesktop($typeUpperStyle));
        letter-spacing: rem-calc(getUpperLetterSpacing($typeUpperStyle));
    }
  }
}
@each $typeLinkStyle in map-keys($typeStyleList-linkFontSize) {
  .cc-#{$typeLinkStyle} {
    font-size: rem-calc(getLinkFontSize($typeLinkStyle));
    line-height: rem-calc(getLinkLineHeight($typeLinkStyle));
    letter-spacing: rem-calc(getLinkLetterSpacing($typeLinkStyle));
    font-weight: $global-weight-normal;
    text-decoration: underline;
    text-underline-offset: rem-calc(2);
    color: $text-black;
    @include breakpoint(large up) {
        font-size: rem-calc(getLinkFontSizeDesktop($typeLinkStyle));
        line-height: rem-calc(getLinkLineHeightDesktop($typeLinkStyle));
        letter-spacing: rem-calc(getLinkLetterSpacing($typeLinkStyle));
    }
  }
}