.cc-isPageLogin {
    &:not([data-action="Account-NewRegistration"]){
        .cc-simpleFooter {
            display: none;

            @include breakpoint(large up) {
                display: block;
            }
        }
    }
}

.cc-loginLayer,
.cc-loginPage,
.cc-forgotPassword,
.cc-loyaltyRegistration {
    &-wrapper {
        @include flex(column, initial, initial);
        height: 100%;

        .cc-loginLayer-body {
            padding-bottom: rem-calc(130);
        }
    }

    .tingle-modal-box {
        bottom: 0;
        min-height: calc(100dvh - #{rem-calc(53)});
        @include breakpoint(large up) {
            min-height: 100%;
        }

        &__content {
            padding: 0;
            background: $surface-white;
        }
    }

    &-header {
        @include flex(row, center, center);
        padding: rem-calc($xs);
        flex: 0 0 auto;
        position: relative;
        border-bottom: rem-calc(1) solid $border-medium-grey;

        &-label {
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textMedium'));
            letter-spacing: rem-calc(getLetterSpacing('textMedium'));
            line-height: rem-calc(getLineHeight('textMedium'));

            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('textLarge'));
                letter-spacing: rem-calc(getLetterSpacing('textLarge'));
                line-height: rem-calc(getLineHeight('textLarge'));
            }

            &-wrapper {
                @include flex(column, initial, initial);
                gap: rem-calc($xxs);
            }
        }

        &-back {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: rem-calc(44);
            height: rem-calc(44);
            justify-content: center;
            align-items: center;
        }
    }

    &-closeButton,
    &-backButton {
        @include flex(row, initial, initial);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: rem-calc(44);
        height: rem-calc(44);
        justify-content: center;
        align-items: center;
    }

    &-backButton {
        right: unset;
        left: 0;
    }

    &-body {
        @include flex(column, initial, initial);
        gap: rem-calc($m);
        flex: 1;
        padding: rem-calc($xs);
        overflow-y: auto;
        background: $surface-white;

        @include breakpoint(large up) {
            gap: 0;
            padding: rem-calc($m);
        }

        &_loginLanding {
            padding-top: rem-calc(90);
            position: relative;

            .cc-tabs {
                position: absolute;
                width: calc(100% - #{rem-calc($m)});
                padding-top: rem-calc($xs);
                top: 0;

                @include breakpoint(large up) {
                    position: static;
                    width: 100%;
                }

                &_fixed {
                    position: fixed;
                    top: 0;
                    left: rem-calc(16);
                    width: calc(100% - #{rem-calc($m)});
                    background-color: $surface-white;
                    z-index: 1;
                }
            }
        }

        &-tabsContainer {
            @include flex(column, initial, initial);
            gap: rem-calc($m);
        }

        &-inputOuterBox {
            @include flex(column, initial, initial);
            gap: rem-calc($m);

            .cc-feedbackMessage-container {
                margin: rem-calc(-$xxs 0)
            }
        }

        &_gap {
            gap: rem-calc($m);
        }
    }

    &-form {
        @include flex(column, initial, initial);
        gap: rem-calc($s);

        &-inputContainer {
            @include flex(column, initial, initial);
            gap: rem-calc($xxs);
        }
    }

    &-confirmButton {
        width: 100%;

        &-wrapper {
            &_logged {
                position: fixed;
                bottom: 0;
                left: 0;
                padding: rem-calc($xs);
                width: 100%;
                background: $surface-white;
                border-top: rem-calc(1) solid $border-medium-grey;
                margin-top: 0;

                @include breakpoint(large up) {
                    padding: rem-calc($xs $m);
                }
            }
        }
    }

    &-rememberMe {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);

        &-label,
        &-description {
            margin-bottom: 0;
        }
    }

    &-footerArea {
        @include flex(column, center, center);
        gap: rem-calc($xxs);
        background-color: $surface-white;
        border-top: rem-calc(1) solid $border-medium-grey;
        padding: rem-calc($xs);
        @include breakpoint(large up) {
            padding: rem-calc($xs $m);
        }
    }

    // PAGE
    &_page,
    &_registration,
    &_resetPwd,
    &_confirmation {
        @include flex(column, initial, initial);
        flex: 1;
        @include breakpoint(large up) {
            @include flex(row, initial, initial);
            flex: 1;
        }

        .cc-loginPage-body {
            z-index: 1000;
            box-shadow: 1px -14px 20px 0px rgba($color: $black, $alpha: 0.4);
            padding-bottom: rem-calc($xxl);
            @include breakpoint(large up) {
                max-width: rem-calc(375);
                margin: rem-calc($xl) auto rem-calc($l);
                padding: 0;
                z-index: unset;
                box-shadow: none;
                overflow: unset;
            }
        }
    }

    &-image {
        width: 100%;
        z-index: -1;
        position: sticky;
        top: rem-calc(57);

        @include breakpoint(large up) {
            width: 44.23611111vw;
            z-index: 0;
            position: static;
            & > div {
                position: sticky;
                top: rem-calc(53);
            }
        }
    }

    &-paragraph {
        @include flex(column, initial, initial);
        gap: rem-calc($xxs);
        @include breakpoint(large up) {
            gap: rem-calc($xs);
        }

        &-title {
            margin-bottom: rem-calc($xxs);
            @include breakpoint(large up) {
                margin-bottom: 0;
            }
        }
    }

    &-CTAs {
        @include flex(column, center, center);
        gap: rem-calc($xs);

        &-extended {
            width: 100%;
        }
    }

    &-resetForm {
        &-body {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);

            &-text {
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                font-size: rem-calc(getFontSize('textMedium'));
                letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                line-height: rem-calc(getLineHeight('textMedium'));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize('textLarge'));
                    letter-spacing: rem-calc(getLetterSpacing('textLarge'));
                    line-height: rem-calc(getLineHeight('textLarge'));
                }
            }

            &-confirm {
                @include flex(column, initial, initial);
                gap: rem-calc($xs);

                &-title {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textLarge'));
                    letter-spacing: rem-calc(getLetterSpacing('textLarge'));
                    line-height: rem-calc(getLineHeight('textLarge'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('title'));
                        letter-spacing: rem-calc(getLetterSpacing('title'));
                        line-height: rem-calc(getLineHeight('title'));
                    }
                }

                &-subtitle {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textSmall'));
                    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                    line-height: rem-calc(getLineHeight('textSmall'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('textMedium'));
                        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                        line-height: rem-calc(getLineHeight('textMedium'));
                    }
                }
            }
        }

        &-sendAgain {
            @include flex(column, initial, initial);
            gap: rem-calc(10);

            &-button {
                text-decoration: underline;
            }
        }
    }
}

.cc-forgotPassword {
    .cc-form-item_resetForm {
        margin-top: rem-calc(24);
    }
 }

.cc-registerConfirmation {
    flex: 1;

    &-body {
        padding-top: rem-calc($s);
        @include breakpoint(large up) {
            padding-top: 0;
        }
    }

    &-wrapper,
    &-box {
        @include flex(column, initial, initial);
        gap: rem-calc($xxs);

        @include breakpoint(large up) {
            gap: rem-calc($xs);
        }
    }

    &-box {
        gap: rem-calc($m);
    }

    &-title {
        margin: 0;
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textLarge'));
        letter-spacing: rem-calc(getLetterSpacing('textLarge'));
        line-height: rem-calc(getLineHeight('textLarge'));

        @include breakpoint(large up) {
            font-size: rem-calc(getFontSize('title'));
            letter-spacing: rem-calc(getLetterSpacing('title'));
            line-height: rem-calc(getLineHeight('title'));
        }
    }

    &-subtitle {
        margin: 0;
    }
}

.cc-conditionPrivacyPolicy {
    &-wrapper {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);
        width: 100%;
        margin-top: rem-calc($xxs);

        &_login {
            .cc-conditionPrivacyPolicy-infoDescription {
                margin-top: rem-calc($xs);
            }
        }
    }

    &-checkboxLabel {
        margin-top: rem-calc(-3);
    }

    &-cta {
        color: inherit;
        text-decoration: underline;
    }

    &-label {
        padding: 0;
        margin: 0;
    }

    &-infoDescription {
        margin-bottom: 0;

        &-link {
            text-decoration: underline;
        }
    }

    &-modal {
        &-close {
            top: rem-calc(16);
            right: rem-calc(16);
            position: absolute;
        }
        .tingle-modal-box {
            &__content {
                padding: 0;

                // CONTENT ASSET CLASSES
                .form-request-title {
                    padding: rem-calc($xs);
                    margin: 0;
                    text-align: center;
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textMedium'));
                    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                    line-height: rem-calc(getLineHeight('textMedium'));
                }
                .intro-content {
                    padding: rem-calc($s $xs $xs);
                    margin-top: 0;
                    @include breakpoint(large up) {
                        padding: rem-calc($m);
                    }
                }
            }
        }
    }
}

.cc-conditionLoyaltyPolicy {
    &-advantages {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);
        padding: rem-calc($xs);
        background-color: $surface-organic;

        @include breakpoint(large up) {
            padding: rem-calc($s);
        }

        &_loyaltyRegistration {
            padding: 0;
            background-color: $surface-white;
            gap: rem-calc($s);
        }

        &-header {
            @include flex(column, initial, initial);
            gap: rem-calc($xxs);

            &-subtitle {
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                font-size: rem-calc(getFontSize('textSmall'));
                letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                line-height: rem-calc(getLineHeight('textSmall'));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize('textMedium'));
                    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                    line-height: rem-calc(getLineHeight('textMedium'));
                }
            }
        }

        &-list {
            @include flex(column, initial, initial);
            gap: rem-calc($xxs);
            margin: 0;

            &-item {
                @include flex(row, initial, center);
                gap: rem-calc($xs);

                &-label {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textSmall'));
                    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                    line-height: rem-calc(getLineHeight('textSmall'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('textMedium'));
                        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                        line-height: rem-calc(getLineHeight('textMedium'));
                    }
                }
            }
        }
    }

    &-regulation {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);
        margin-top: rem-calc($xxs);
        @include breakpoint(large up) {
            margin-top: 0;
        }

        &-linkAnchor {
            height: unset;
        }
    }

    &-fieldset {
        @include flex(column, initial, initial);
        gap: rem-calc($xxs);
        margin-bottom: rem-calc($xxs);
        @include breakpoint(large up) {
            margin-bottom: 0;
        }

        &-grid {
            @include flex(row, initial, initial);
            gap: rem-calc($xxs);

            &_modal {
                flex-direction: column;
                @include breakpoint(large up) {
                    flex-direction: row;
                }
            }

            &-item {
                flex: 1;
            }
        }

        &-legend {
            margin-bottom: rem-calc($xxs);
        }
    }

    &-clubCard {
        @include flex(column, initial, initial);
        gap: rem-calc($xxs);

        &-grid {
            @include flex(column, initial, initial);
            gap: rem-calc($xxs);

            @include breakpoint(large up) {
                flex-direction: row;
            }

            &-item {
                flex: 1;
            }
        }
    }
}

.cc-resetPwd {
    &-title {
        margin-bottom: 0;
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textMedium'));
        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
        line-height: rem-calc(getLineHeight('textMedium'));

        @include breakpoint(large up) {
            font-size: rem-calc(getFontSize('textLarge'));
            letter-spacing: rem-calc(getLetterSpacing('textLarge'));
            line-height: rem-calc(getLineHeight('textLarge'));
        }
    }

    &-container {
        @include flex(column, initial, initial);
        gap: rem-calc($s);
    }

    &-form {
        @include flex(column, initial, initial);
        gap: rem-calc($m);

        &-wrapper {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);
        }

        &-CTAs {
            @include flex(column, center, center);
            gap: rem-calc($xs);

            &-extended {
                width: 100%;
            }
        }
    }

    &-pwd {
        position: relative;

        &-icon {
            display: inline-flex;
            padding: rem-calc($xs);
            justify-content: center;
            align-items: center;
            background: $surface-white;
            position: absolute;
            top: calc(50% - #{rem-calc(4)});
            right: rem-calc(1);
            transform: translateY(-50%);
            cursor: pointer;

            &_layer {
                top: rem-calc(28);
            }
        }

        &-labelContainer {
            @include flex(column, initial, initial);
            gap: rem-calc($xxxs);
        }
    }

    &-helper {
        @include flex(row, initial, center);
        gap: rem-calc($xxxs);
        margin-top: 0;

        &-wrapper {
            @include flex(column, initial, initial);
            gap: rem-calc($xxxs);
            margin: 0;
        }

        &_valid {
            color: $text-validation;

            .cc-resetPwd-helper-icon_valid,
            .cc-resetPwd-helper-label_valid {
                display: inline-flex;
            }
        }

        &_error {
            color: $text-error;

            .cc-resetPwd-helper-icon_error,
            .cc-resetPwd-helper-label_error {
                display: inline-flex;
            }
        }
    }
}

.cc-registrationFlow {
    &-grid {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);
    }

    &-loyaltyCard {
        @include flex(column, initial, initial);
        gap: rem-calc($s);

        @include breakpoint(large up) {
            gap: rem-calc($xs);
        }
    }

    &-CTAs {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);

        &-extended {
            width: 100%;
        }
    }

    &-benefits {
        @include flex(column, initial, initial);
        gap: rem-calc($xxs);

        &-wrapper {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);
        }

        &-item {
            @include flex(row, initial, center);
            gap: rem-calc($xs);
        }

        &-label {
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textSmall'));
            letter-spacing: rem-calc(getLetterSpacing('textSmall'));
            line-height: rem-calc(getLineHeight('textSmall'));

            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('textMedium'));
                letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                line-height: rem-calc(getLineHeight('textMedium'));
            }
        }
    }

    &-footerArea {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: rem-calc($xs);
        width: 100%;
        background: $surface-white;
        border-top: rem-calc(1) solid $border-medium-grey;
        margin-top: 0;

        @include breakpoint(large up) {
            padding: rem-calc($xs $m);
        }
    }

    &-stepTitle {
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textMedium'));
        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
        line-height: rem-calc(getLineHeight('textMedium'));

        @include breakpoint(large up) {
            font-size: rem-calc(getFontSize('textLarge'));
            letter-spacing: rem-calc(getLetterSpacing('textLarge'));
            line-height: rem-calc(getLineHeight('textLarge'));
        }
    }

    &-stepSubtitle {
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textSmall'));
        letter-spacing: rem-calc(getLetterSpacing('textSmall'));
        line-height: rem-calc(getLineHeight('textSmall'));

        @include breakpoint(large up) {
            font-size: rem-calc(getFontSize('textMedium'));
            letter-spacing: rem-calc(getLetterSpacing('textMedium'));
            line-height: rem-calc(getLineHeight('textMedium'));
        }
    }

    &-inputWrapper {
        @include flex(row, initial, initial);
        gap: rem-calc($xxs);

        &_email,
        &_personalInfo,
        &_loyaltyCard {
            flex-direction: column;
        }

        &-password {
            .errors-list.filled {
                display: none;
            }
        }
    }

    &-container {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);

        @include breakpoint(large up) {
            gap: rem-calc($s);
        }

        &-topPhoneStep {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);
        }

        &_final {
            gap: rem-calc($s);
        }

        &-header {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);

            @include breakpoint(large up) {
                gap: rem-calc($s);
            }

            &_lessSpacing {
                gap: rem-calc($xxs);

                @include breakpoint(large up) {
                    gap: rem-calc($xs);
                }

                &_modal {
                    margin-bottom: rem-calc($xs);

                    @include breakpoint(large up) {
                        margin-bottom: rem-calc($xxs);
                    }
                }
            }

            &_modal {
                gap: rem-calc($xs);
            }

            &_loyalty {
                margin-bottom: rem-calc($xs);
            }

            &-text {
                @include flex(column, initial, initial);
                gap: rem-calc($xxs);
            }
        }

        &-form {
            @include flex(column, initial, initial);
            gap: rem-calc($xxs);
        }

        &-CTAs {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: rem-calc($xs);
            width: 100%;
            background: $surface-white;
            border-top: rem-calc(1) solid $border-medium-grey;
            margin-top: 0;
            z-index: 1;

            @include breakpoint(large up) {
                position: static;
                border-top: 0;
                padding: 0;
            }

            &-extended {
                width: 100%;
            }
        }

        &_modal {
            padding-bottom: rem-calc($xxl);
            gap: rem-calc($xs);

            @include breakpoint(large up) {
                padding-bottom: 0;
            }

            .cc-tooltip {
                &_pwd {
                    position: absolute;
                    top: rem-calc(20);
                    right: rem-calc(10);
                }

                &-content {
                    right: rem-calc(-24);
                    left: unset;
                    top: rem-calc(-8);
                    transform: translate(0, -100%);
                }
            }
        }
    }

    &-back {
        &-link {
            @include flex(row, initial, center);
            gap: rem-calc($xxs);
            height: rem-calc(44);
            display: inline-flex;
        }
    }

    &-wrapper {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);

        @include breakpoint(large up) {
            gap: rem-calc($m);
            padding-bottom: 0;
        }

        &_loyaltyCardStep {
            @include breakpoint(large up) {
                gap: rem-calc($s);
            }
        }

        .cc-feedbackMessage-container {
            display: none;

            &:has(.cc-feedbackMessage-message:not(:empty)) {
                display: inherit;
            }
        }
    }

    &-input {
        &_prefix {
            width: 23.32361516%;
        }
        &_telephone {
            flex-grow: 1;
        }
    }

    &-opposition:not(.hidden) {
        @include flex(row, initial, center);
        gap: rem-calc($xxxs);

        .cc-registrationFlow-opposition-label {
            flex: 1;
        }

        .cc-tooltip {
            height: rem-calc(16);
            &-content {
                right: rem-calc(-5);
                transform: translate(0%, -100%);
                left: unset;
                top: rem-calc(-$xxs);
                z-index: 1;
                max-width: rem-calc(345);
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: rem-calc(-7);
                    width: rem-calc(12);
                    height: rem-calc(12);
                    background:inherit;
                    transform: translateX(-50%) rotate(45deg);
                    border-right: solid rem-calc(1) $border-medium-grey;
                    border-bottom: solid rem-calc(1) $border-medium-grey;
                }
            }
        }
    }

    &-phoneOtp {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);

        &-label {
            margin: 0;
        }
    }

    &-personalInfoStep {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);

        @include breakpoint(large up) {
            gap: rem-calc($s);
        }
    }

    &-modal {
        &-box {
            &[data-registration-modal-stage="email"] {
                .cc-registrationFlow-modal-phoneStep,
                .cc-registrationFlow-modal-personalInfoStep,
                .cc-registrationFlow-modal-waitingRoomStep {
                    display: none;
                }

                &.cc-isRegistrationWithEmail {
                    .cc-registrationFlow-modal-emailStepBox {
                        display: block;
                    }
                }

                &.cc-isRegistrationWithLoyaltyCard {
                    .cc-registrationFlow-loyaltyCardStepBox {
                        display: block;
                    }
                }
            }

            &[data-registration-modal-stage="phone"] {
                .cc-registrationFlow-modal-emailStep,
                .cc-registrationFlow-modal-personalInfoStep,
                .cc-registrationFlow-modal-waitingRoomStep {
                    display: none;
                }
            }

            &[data-registration-modal-stage="personal-info"] {
                .cc-registrationFlow-modal-emailStep,
                .cc-registrationFlow-modal-phoneStep,
                .cc-registrationFlow-modal-waitingRoomStep {
                    display: none;
                }
            }

            &[data-registration-modal-stage="waiting-room"] {
                .cc-registrationFlow-modal-emailStep,
                .cc-registrationFlow-modal-phoneStep,
                .cc-registrationFlow-modal-personalInfoStep {
                    display: none;
                }
            }
        }

        &-otp {
            margin: 0;
            padding: rem-calc($xxs);
            text-align: center;
            border: rem-calc(1) solid $border-medium-grey;
            &:focus {
                border: rem-calc(1) solid $border-black;
            }

            .cc-invalid & {
                border-color: $border-error;
            }

            &-header {
                @include flex(column, initial, initial);
                gap: rem-calc($xs);

                &-title {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textMedium'));
                    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                    line-height: rem-calc(getLineHeight('textMedium'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('title'));
                        letter-spacing: rem-calc(getLetterSpacing('title'));
                        line-height: rem-calc(getLineHeight('title'));
                    }
                }

                &-subtitle {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textSmall'));
                    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                    line-height: rem-calc(getLineHeight('textSmall'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('textMedium'));
                        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                        line-height: rem-calc(getLineHeight('textMedium'));
                    }
                }
            }

            &-wrapper {
                @include flex(row, initial, initial);
                gap: rem-calc($xs);
                padding: rem-calc($xs 0);
            }
        }

        &-CTAs {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: rem-calc($xs);
            width: 100%;
            background: $surface-white;
            border-top: rem-calc(1) solid $border-medium-grey;

            @include breakpoint(large up) {
                padding: rem-calc($xs $m);
            }

            &-extended {
                width: 100%;
            }
        }

        &-startProfileEnrichmentStep {
            @include flex(column, initial, initial);
            gap: rem-calc($m);
        }

        &-success {
            &-profileEnrichBox {
                @include flex(column, initial, initial);
                gap: rem-calc($xs);
                padding: rem-calc($xs);
                border: rem-calc(1) solid $border-grey;

                &-title {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textLarge'));
                    letter-spacing: rem-calc(getLetterSpacing('textLarge'));
                    line-height: rem-calc(getLineHeight('textLarge'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('title'));
                        letter-spacing: rem-calc(getLetterSpacing('title'));
                        line-height: rem-calc(getLineHeight('title'));
                    }
                }
            }

            &-header {
                @include flex(column, initial, initial);
                gap: rem-calc($xxs);

                &-title {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textLarge'));
                    letter-spacing: rem-calc(getLetterSpacing('textLarge'));
                    line-height: rem-calc(getLineHeight('textLarge'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('title'));
                        letter-spacing: rem-calc(getLetterSpacing('title'));
                        line-height: rem-calc(getLineHeight('title'));
                    }
                }

                &-subtitle {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('textSmall'));
                    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                    line-height: rem-calc(getLineHeight('textSmall'));

                    @include breakpoint(large up) {
                        font-size: rem-calc(getFontSize('textMedium'));
                        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                        line-height: rem-calc(getLineHeight('textMedium'));
                    }
                }
            }
        }
    }
}

.cc-loyaltyRegistration {
    .tingle-modal-box__content {
        @include flex(column, initial, initial);
    }

    &-wrapper {
        overflow: hidden;
        gap: 0;

        .cc-loyaltyRegistration-registrationFlow,
        .cc-loyaltyRegistration-body {
            padding-bottom: rem-calc(130);
        }

        &-body {
            overflow: auto;
            height: 100%;
        }
    }

    &-registrationFlow {
        padding: rem-calc($s $xs);

        @include breakpoint(large up) {
            padding: rem-calc($s $m);
        }

        &-card {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);

            &-radio {
                @include flex(row, initial, initial);
                gap: rem-calc($xxs);
            }
        }

        &-container {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);

            @include breakpoint(large up) {
                gap: rem-calc($s);
            }
        }

        &-thankYou {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);
            padding: rem-calc($s $xs);

            @include breakpoint(large up) {
                padding: rem-calc($m);
            }

            &-subtitle {
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                font-size: rem-calc(getFontSize('textLarge'));
                letter-spacing: rem-calc(getLetterSpacing('textLarge'));
                line-height: rem-calc(getLineHeight('textLarge'));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize('textMedium'));
                    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                    line-height: rem-calc(getLineHeight('textMedium'));
                }
            }
        }
    }

    .cc-registrationFlow-modal-box {
        &[data-registration-modal-stage="content"] {
            .cc-loyaltyRegistration-registrationFlow,
            .cc-loyaltyRegistration-registrationFlow-thankYou {
                display: none;
            }

            .cc-registrationModalTitle {
                margin-left: 0;
            }
        }
        &[data-registration-modal-stage="registration"]{
            .loyalty-registration-modal-content,
            .cc-loyaltyRegistration-registrationFlow-thankYou {
                display: none;
            }

            /* TODO: Fix position */
            .opposition {
                .tooltip {
                    z-index: $zIndex-default;
                    @include breakpoint (small down) {
                        left: rem-calc(50);
                    }
                    @include breakpoint (medium up) {
                        left: rem-calc(100);
                    }
                }
            }
        }
        &[data-registration-modal-stage="loyalty-thankyou-page"] {
            .cc-loyaltyRegistration-registrationFlow {
                display: none;
            }
        }
    }

    &-banner {
        position: relative;

        &-image {
            width: 100%;
        }

        &-text {
            padding: rem-calc($m);
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('displayMedium'));
            letter-spacing: rem-calc(getLetterSpacing('displayMedium'));
            line-height: rem-calc(getLineHeight('displayMedium'));

            @include breakpoint(large up) {
                font-size: rem-calc(56);
                letter-spacing: rem-calc(getLetterSpacing('displayBig'));
                line-height: rem-calc(64);
            }
        }
    }

    &-CTAs {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: rem-calc($xs);
        width: 100%;
        background: $surface-white;
        border-top: rem-calc(1) solid $border-medium-grey;
        z-index: $zIndex-default;
        gap: rem-calc($xxs);

        @include breakpoint(large up) {
            padding: rem-calc($xs $m);
        }

        &-extended {
            width: 100%;
        }
    }
}

.cc-socialLogin {
    &-container {
        @include flex(column, initial, initial);
        gap: rem-calc($xs);

        .cc-loginPage & {
            gap: rem-calc($s);
        }
    }

    &-CTAs {
        @include flex(row, center, center);
        margin-top: rem-calc($xs);

        .cc-loginPage & {
            margin-top: rem-calc($s);
        }
    }

    &-terms {
        margin-bottom: 0;

        .cc-registrationFlow-footerArea & {
            display: none;
        }
    }
}

.cc-urlNotValid,
.cc-thankYouPage {
    flex: 1;

    &-top {
        @include flex(column, initial, initial);
        gap: rem-calc($s);
        padding: rem-calc($m $xs);

        @include breakpoint(large up) {
            padding: rem-calc($xxl $s);
            text-align: center;
        }

        &-titles {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);

            &-main {
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                font-size: rem-calc(getFontSize('textLarge'));
                letter-spacing: rem-calc(getLetterSpacing('textLarge'));
                line-height: rem-calc(getLineHeight('textLarge'));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize('title'));
                    letter-spacing: rem-calc(getLetterSpacing('title'));
                    line-height: rem-calc(getLineHeight('title'));
                }
            }

            &-subtitle {
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                font-size: rem-calc(getFontSize('textSmall'));
                letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                line-height: rem-calc(getLineHeight('textSmall'));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize('textMedium'));
                    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                    line-height: rem-calc(getLineHeight('textMedium'));
                }
            }
        }
    }

    &-CTAs {
        @include flex(column, center, initial);
        gap: rem-calc($xs);

        @include breakpoint(large up) {
            flex-direction: row;
        }
    }
}

.cc-registrationFlow-Japan {
    .gender-form-group {
        order: 0;
    }

    .cc-form-item:has(#registration-form-fname) {
        order: 2;
    }

    .cc-form-item:has(#registration-form-lname) {
        order: 1;
    }

    .cc-form-item:has(#registration-form-password) {
        order: 3;
    }

    .cc-form-item:has(#registration-form-password-confirm) {
        order: 4;
    }
    .cc-conditionPrivacyPolicy-wrapper {
        order: 5;
    }
}