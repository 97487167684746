.container  {
    &.content-asset-container {
        width: 100%;
    }
}


$padding-top-page-home-gender-d: rem-calc(120);
$padding-top-page-home-gender-m: rem-calc(62);

.page-home-gender{
    padding-top: $padding-top-page-home-gender-d;
    @include breakpoint (small down) {
        padding-top: $padding-top-page-home-gender-m;
    }
}