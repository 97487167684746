svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    height: 100%;
    fill: currentColor;

    &:not(:root) {
        overflow: hidden;
    }
}

.button,
a {
    i {
        display: inline-flex;
        vertical-align: baseline;
    }
}

.button, a, i {
    svg {
        width: rem-calc(20);
        height: rem-calc(20);

        &.outline {
            stroke-width: rem-calc(2);
            stroke: currentColor;
        }

        &.hollow {
            fill: transparent;
        }

        &.wide {
            width: 2.5em;
        }
    }

    &:hover {
        svg {
            &.hollow {
                fill: currentColor;
            }
        }
    }
}

i {
    display: inline-flex;
    font-weight: normal;
    font-style: normal;

    svg {
        &.tiny {
            width: rem-calc(10);
            height: rem-calc(10);
        }

        &.verysmall {
            width: rem-calc(14);
            height: rem-calc(14);
        }

        &.small {
            width: rem-calc(16);
            height: rem-calc(16);
        }

        &.medium {
            width: rem-calc(20);
            height: rem-calc(20);
        }

        &.large {
            width: rem-calc(24);
            height: rem-calc(24);
        }

        &.huge {
            width: rem-calc(30);
            height: rem-calc(30);
        }
    }
}
