.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

svg.big {
    width: rem-calc(30);
    height: rem-calc(30);
}

svg.small {
    width: rem-calc(20);
    height: rem-calc(20);
}

svg.verysmall {
    width: rem-calc(14);
    height: rem-calc(14);
}

svg.xsmall {
    width: rem-calc(10);
    height: rem-calc(10);
}

svg.xxsmall {
    width: rem-calc(6);
    height: rem-calc(6);
}

.lazyload,
.lazyloading {
    opacity: 0;
}

img.lazyload:not([src]) {
    visibility: hidden;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.image-container {
    position: relative;
    overflow: hidden;
    background: #f8f9fa;

    img {
        &.loading,
        &.lazyload,
        &.lazyloaded {
            opacity: 1;
            transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }

    &.ratio-1-1 {
        padding-top: 100%;
    }
}
