.category-top-banner {
    .header-message {
        background: $black;
        color: $white;
        text-align: center;
        padding: rem-calc(8.1 0);
        min-height: rem-calc(35);
        height: auto;

        .close-button {
            color: $white;
            position: absolute;
            right: 0;
            top: 45%;
            margin-top: rem-calc(-2);
            transform: translateY(-50%);
            cursor: pointer;
        }

        a {
            color: $white;
        }

        p {
            margin-bottom: 0;
            font-size: rem-calc(13);
            padding: rem-calc(0 60);
            line-height: rem-calc(19);

            @include breakpoint(small down) {
                padding: rem-calc(0 60 0 12);
                font-size: rem-calc(12);
            }
        }
    }
}