@import "../components/privacyPolicy";

/*
 * @import "../pages/login.scss";
 * @import "../pages/myaccount.scss";
 * TODO: these imports above should not be present as the files are already included directly in ISML templates.
 * This change might break the login/registration in modal.
 */

.cc-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  &-home{
    animation: fadeIn 0.8s;
    @keyframes fadeIn {
      0% { opacity: 0; top: rem-calc(57);}
      100% { opacity: 1; top: 0;}
    }
    @include breakpoint(large up) {
      @keyframes fadeIn {
        0% { opacity: 0; top: rem-calc(160);}
        100% { opacity: 1; top: 0;}
      }
    }
  }
  &-horizontal{
    .cc-homepage & {
      display: none;
    }
    transform: translateY(-100%);
    transition: transform 800ms;

    &.cc-headerAnimation {
      transform: translateY(0);
    }

    .cc-isPageLogin & {
      transform: translateY(0);
    }
  }
  .cc-onMouseenter a {
    @include breakpoint (large up) {
      color: $text-medium-grey;

      &:hover, &:focus {
        color: $text-black;
      }
    }
  }
  &.cc-overlay-gradient{
    background: linear-gradient(180deg, rgba($surface-dark, 0.4) 50%, rgba($surface-dark, 0) 100%);
    .cc-header-nav{
      &:not(.cc-fnMenu-horizontalNav_Show) {
        background: transparent;
      }
      &-logo {
        &_white{
          display: block;
        }
        &_black{
          display: none;
        }
      }
      &-icons_padding, & .cc-main-menu a, &-icons_menu, &-icons-quantity {
        color: $text-white;
      }
      &-icons_dot {
        background: $surface-white;
      }
      .cc-onMouseenter a {
        @include breakpoint (large up) {
          color: $text-medium-grey;

          &:hover, &:focus {
            color: $text-white;
          }
        }
      }
    }
    @include breakpoint(large up) {
      height: rem-calc(277);
    }
  }
  &-nav {
    position: relative;
    z-index: 999;
    height: rem-calc(57);
    background: $surface-white;
    border-bottom: 1px solid $border-medium-grey;
    padding: rem-calc(6 6 6 $xs);
    @include breakpoint(large up) {
      height: rem-calc(53);
      border-bottom: 1px solid $border-grey;
      padding: rem-calc($xxxs $xs $xxxs $m);
    }
    &-link{
      min-width: rem-calc(44);
      min-height: rem-calc(44);
      display: flex;
      align-items: center;
      width: fit-content;
    }
    &-logo {
      height: rem-calc(15);
      @include breakpoint(large up) {
        height: rem-calc(19);
      }
      &_white{
        display: none;
      }
      &_black{
        display: block;
      }
    }
    &-container{
      position: relative;
      display: inline-block;
    }
    &-icons {
      justify-content: flex-end;
      align-items: baseline;
      text-align: right;
      &_padding{
        margin-right: rem-calc(20);
        margin-right: 0;
        width: rem-calc(44);
        height: rem-calc(44);
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(large up) {
          margin-right: 0;
          width: rem-calc(44);
          height: rem-calc(44);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @include breakpoint(medium down) {
          & svg.small{
            width: rem-calc(24);
            height: rem-calc(24);
          }
        }
      }
      &_dot{
        width: rem-calc($xxxs);
        display: block;
        height: rem-calc($xxxs);
        background: $surface-dark;
        position: absolute;
        right: rem-calc(10);
        top: rem-calc(14);
      }
      .header-search {
        text-align: left;
      }
      &-quantity {
        position: absolute;
        left: rem-calc(33);
        bottom: rem-calc(12);
        font-size: rem-calc(10);
        line-height: rem-calc(18);
        font-weight: $global-weight-normal;
        @include breakpoint (large up){
          font-size: rem-calc(8);
          bottom: rem-calc(12);
          left: rem-calc(31);
        }
        &.hidden {
          display: none;
        }
      }
      &_cart{
        display: inline-block;
      }
    }
    &-home{
      border-bottom: none;
      @include breakpoint(large up) {
        height: auto;
        padding: rem-calc($xs $xs $s $m);
        align-items: flex-start;
        &-icons{
          display: flex;
          justify-content: flex-end;
          .cc-header-nav-icons_dot{
            right: rem-calc(9);
          }
        }
        &.cc-header-nav .cc-main-menu{
          flex-direction: column;
          gap: rem-calc($xxs);
          display: grid;
          justify-content: flex-start;
        }
        .cc-header-nav-logo{
          margin-top: rem-calc($xs);
          margin-bottom: rem-calc($s);
          height: rem-calc(29);
        }
      }
    }
  }
  @include breakpoint(large up) {
    .cc-main-menu{
      gap: rem-calc($m);
      justify-content: center;
      &-link {
        cursor: pointer;
        &.not-active{
          color: $text-grey;
        }
      }
    }
  }
  &.top-strip-in {
    top: rem-calc(40); // Sovraheader height
  }
  .search-toggler {
    cursor: pointer;
    color: currentColor;
  }

  &-search {
    font-size: 14px;

    @include breakpoint(medium up) {
      margin-right: rem-calc(10);
      position: relative;
      display: inline-block;

      input {
        height: rem-calc(23);
        line-height: rem-calc(20);
        font-size: rem-calc(14);
        letter-spacing: rem-calc(0.6);
        margin-bottom: 0;
        padding-right: rem-calc(30);
        padding-top: rem-calc(2);
        padding-bottom: rem-calc(2);
        padding-left: 0;
        max-width: rem-calc(110);
        border: none;
        border-bottom: 1px solid $black;
        text-align: left;
        font-weight: $global-weight-dark;
      }
    }
    @include breakpoint(large down) {
      margin-right: 0;
    }
  }

  // SERVICE MENU HELP
  .dnone {
    display: none;
  }

  .dblock {
    display: block;
  }

  .bg-white-nav {
    background-color: $white;
  }
}

.cc-headerProfile {
  display: block;
  position: fixed;
  top: rem-calc(53);
  right: 0;
  width: rem-calc(376);
  background: $surface-white;
  text-align: left;
  box-shadow: rem-calc(-5 2 10) rgba(0, 0, 0, 0.15);
  @include breakpoint (medium down) {
    display: none;
  }
  &-title{
    padding: rem-calc($xs $s);
    @include flex(row, space-between, center);
    background: $surface-light-grey;
  }
  &-container{
    padding: rem-calc($s);
    &-box {
      padding-bottom: rem-calc($s);
      margin-bottom: rem-calc($s);
      border-bottom: rem-calc(1) solid $border-medium-grey;
    }
  }
}

.main-header-login {
  // Hoverable profile container
  .main-header-profile {
    position: relative;
    display: inline-block;

    $layer-profile-top: rem-calc(85);
    .loading-bar {
      width: 100%;
      border: rem-calc(1) solid black;
      border-radius: rem-calc(5.5);
      margin-top: rem-calc(14);

      &-loaded {
        background: $orange1;
        border-radius: rem-calc(5.5);
        height: rem-calc(8);
      }
    }
  }
}
