@import "../setup/settings";
@import "../layout/spacing";

.footer {
    background-color: $white;
    position: relative;

    &.cc-main-footer{
        display: flex;
        flex-direction: column;
        gap: rem-calc(48);
        margin-top: auto;
        @include breakpoint (medium up){
            gap: rem-calc(96);
        }
    }
    .cc-main-footer{
        &-divider{
            max-width: 100vw;
        }
        &-logo{
            padding: rem-calc(48 16 0);
            @include breakpoint (medium up){
                padding: rem-calc(64 32 0);
            }
            &_icon{
                width: rem-calc(172);
                @include breakpoint (large up){
                    width: rem-calc(229);

                }
            }
        }
        &-columnsContainer{
            padding: rem-calc(0 16);
            @include breakpoint (medium up){
                padding: rem-calc(0 32);
            }
        }
        &-columns{
            display: flex;
            justify-content: space-between;
            @include breakpoint (small down){
                flex-direction: column;
            }
            & .cc-qr-tr {
                display: none;
                @include breakpoint (small down) {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    padding: rem-calc($m 0 0 0);
                }
            }
            & .cc-accordion-title_borderBottom{
                padding: rem-calc(18 0);
            }
        }
        &-column1Content{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            @include breakpoint (small down){
                margin-bottom: rem-calc(36);
            }
        }
        &-column1,
        &-columnFollowUs{
            width: 100%;
            @include breakpoint (medium up){
                width: 24vw;
                max-width: rem-calc(345);
            }
        }
        &-column2,
        &-column3,
        &-column4{
            width: 100%;
            @include breakpoint (medium up){
                width: 15.83333333vw;
                max-width: rem-calc(228);
            }
        }
        &-column{
            &_company,
            &_careers{
                flex: 1 1 25%;
            }
        }
        &-newsletters,
        &-findInStore{
            margin-bottom: rem-calc(16);
        }
        &-columnFollowUs,
        &-footerInfoChangeCountry{
            padding: rem-calc(0 16);
            @include breakpoint (medium up){
                padding: rem-calc(0 32);
            }
        }
        &-footerInfoChangeCountry{
            @include breakpoint (small down){
                padding-bottom: rem-calc(48);
            }
            &_elements{
                display: flex;
                flex-direction: row;
                @include breakpoint (small down){
                    flex-direction: column;
                }
            }
        }
        &-formItem{
            width: 100%;
            .localities-empty-button {
                display: none;
            }
        }
        &-newslettersInput{
            @include breakpoint (small down){
                margin-bottom: rem-calc(36);
            }
        }
        &-linksCategory{
            display: flex;
            flex: 1 1 25%;
            justify-content: center;
            gap: rem-calc(3);
            padding: rem-calc(18 0);
            text-align: center;
            margin-bottom: 0;
            height: rem-calc(55);
            &:not(.cc-main-footer-linksCategory_last){
                border-bottom: 0;
            }
            @include breakpoint (medium up){
                margin-bottom: rem-calc(48);
                &:not(.cc-main-footer-linksCategory_last){
                    border-right: 0;
                    border-bottom: rem-calc(1) solid $border-medium-grey;
                }
            }
        }
        &-rotateIconSelector{
            transform: rotate(180deg);
            transition: all 0.2s ease;
        }
        &-chooseCountryHeader{
            &_title{
                @include breakpoint (large up){
                    margin-bottom: 0;
                }
            }
        }
        &-languageList,
        &-countrySelectorInner{
            display: flex;
            align-items: center;
        }
        &-countrySelectorInner{
            &_text{
                margin-top: rem-calc(2);
            }
        }
        &-countryListContainer{
            flex: 1 1 25%;
        }
        &-inputGroup{
            &-label{
                margin-top: rem-calc(2);
            }
            &-input{
                display: none;
            }
        }
        &-bottomInfo{
            padding: rem-calc(0 32 48);
            text-align: center;
            @include breakpoint (small down){
                padding: rem-calc(0 16 48);

            }
           .ca-footer_column_companyInfo{
            margin: 0;
            @include breakpoint (small down){
                margin: rem-calc(0 16);
            }
           }
        }
    }
    // content assets [footer_column_(1,2,3,followUs,careers,company)])
    .ca-main-footer{
        &-columnTitle{
            margin-bottom: rem-calc(16);
        }
        &-columnLinkActions{
            display: flex;
            flex-direction: column;
        }
        &-linkAction{
            margin: rem-calc(10 0);
        }
        &-followUsBox{
            display: flex;
            align-items: center;
            gap: rem-calc(24);
            & .cc-stock-restriction-symbol{
                min-width: rem-calc($xs);
            }
        }
        &-followIcons{
            display: flex;
            justify-content: unset;
            gap: rem-calc(16);
            line-height: normal;
            margin: rem-calc(1 0 0);
        }
        &-linksCategory{
            display: flex;
            justify-content: center;
            padding: rem-calc(18 0);
            text-align: center;
            margin-bottom: 0;
            height: rem-calc(55);
            border-bottom: 0;
            @include breakpoint (medium up){
                margin-bottom: rem-calc(64);
                border-bottom: rem-calc(1) solid $border-medium-grey;
                border-right: 0;
            }
        }
    }

    & .chat-container {
        cursor: pointer;
    }
    & .close-button {
        position: absolute;
        top: rem-calc(10);
        right: rem-calc(10);
        font-size: 2rem;
    }
    .footer-details {
        background-color: $grey6;
        width: 100%;
        @include breakpoint(large up){
            height: rem-calc(129);
        }
        .footer-description-grid{
            @include breakpoint(large up){
                padding-top: rem-calc(40);
            }
            @include breakpoint(medium down){
                padding-top: rem-calc(30);
            }
            @include breakpoint(small down){
                padding-top: rem-calc(30);
            }
            .footer-grid-item {
                display: flex;
                flex-direction: column;
                margin-top: 0;

                @include breakpoint(medium down){
                    display: block;
                }
                a {
                    display: contents;
                    font-weight: 400;
                    @include breakpoint(medium down){
                        display: flex;
                        align-items: center;
                        margin-bottom: rem-calc(30);
                    }
                }
                img {
                    @include breakpoint(medium down){
                        display: inline;
                        margin: 0;
                    }
                    height: rem-calc(20);
                    width: rem-calc(20);
                    margin: 0 auto rem-calc(15) auto;
                }
                .footer-cell-description {
                    @include breakpoint(medium down){
                        display: inline;
                        margin-left: rem-calc(15);
                    }
                    @include breakpoint(small down){
                        text-align: left;
                    }
                    text-align: center;
                    line-height: rem-calc(14);
                    font-size: rem-calc(14);
                    color: $black;
                    font-family: 'Messina Sans Light', sans-serif;
                }
            }
        }
    }
    &-overlay {
        width: 100%;
        left: -1px;
        bottom: rem-calc(73);
        background-color: $white;
        z-index: 4;
        @include breakpoint(large up) {
            position: absolute;
            bottom: rem-calc(120);
            height: 76.25vh;
            max-height: 76.25vh;
        }
        &-language {
            bottom: rem-calc(120);
            left: -1px;
            width: 100%;
        }
        @include breakpoint(medium down) {
            bottom: rem-calc(73);
        }
        &-container {
            .box-overlay-countries {
                background-color: $white;
                padding: rem-calc(32);
                border-top: 1px solid $grey4;
                width: 100%;
                @include breakpoint(large down) {
                    padding-left: rem-calc(64);
                }
                @include breakpoint(medium down) {
                    padding: 0;
                    width: 100%;
                    border-top: 0;
                    border-right: 0;
                }
            }
            .title-overlay-choose-country {
                position: relative;
                @include breakpoint(large up) {
                    margin-right: rem-calc(50);
                }
                @include breakpoint(medium down) {
                    font-size: rem-calc(22);
                }
                h4 {
                    margin-bottom: rem-calc(20);
                    letter-spacing: .0125rem;
                }
                .country-title-cell{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .online-store-element {
                    margin-bottom: rem-calc(20);
                    margin-top: rem-calc(6);
                    color: rgba(0, 0, 0, 0.5);
                    svg {
                        height: rem-calc(16);
                        width: rem-calc(16);
                        opacity: 0.5;
                        margin-right: rem-calc(5);
                    }
                }
            }
        }
        .footer-inner-content-privacy {
            height: 100%;
            font-size: rem-calc(14);
            letter-spacing: rem-calc(0.53);
            padding: rem-calc(40 0);
            form {
                height: 75%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .content-privacy-form-box {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .content-privacy-header {
                margin-bottom: rem-calc(15);
            }
            .content-privacy-description {
                margin-bottom: rem-calc(15);
                line-height: rem-calc(22);
            }
            .keep-touch-text {
                font-size: rem-calc(12);
                letter-spacing: rem-calc(0.5);
                font-weight: 400;
                a {
                    text-transform: uppercase;
                    font-weight: 400;
                    margin-bottom: rem-calc(10);
                }
            }
            .privacy-footer-form {
                label {
                    line-height: rem-calc(20);
                }
            }
        }
        & .footer-inner-content {
            margin-top: 0;
            margin-bottom: rem-calc(19);
            ul {
                @include breakpoint(large up) {
                    padding: rem-calc(0 25);
                    &:last-child {
                        border-right: 0;
                        padding-right: 0;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                }
                @include breakpoint(medium down) {
                    li.language-inner-container-mobile{
                        padding: 0;
                        position: relative;
                    }
                    &:last-child {
                        li {
                            &:last-child {
                                .country-container-internal,
                                .language-container-internal {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            @include breakpoint(small down) {
                margin-top: rem-calc(32);
            }
        }
        & .keep-in-touch-panel {
            border-top: 1px solid $grey4;
        }
        & .footer-input-group {
            border-bottom: 1px solid $grey3;
        }
        .footer-input-group input {
            margin-bottom: 0;
        }
        & .lang-selector {
            border-top: rem-calc(1) solid $grey4;
            border-bottom: 0;
            padding: rem_calc(32);
            @include breakpoint(medium down) {
                border-top: 0;
                padding: 0;
            }
            @include breakpoint(large up) {
                border-left: rem-calc(1) solid $grey4;
                border-right: rem-calc(1) solid $grey4;
            }
            h4 {
                font-size: rem-calc(20);
                text-transform: initial;
                letter-spacing: .0125rem;
            }
        }
        & .country-selector {
            margin-top: rem-calc(32);
            margin-bottom: rem-calc(12);
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-flow: nowrap;
            @include breakpoint(medium down) {
                -webkit-overflow-scrolling: touch;
                margin-bottom: 0;
                margin-top: 0;
                &#country-inner-content{
                    height: 40vh;
                    li.fix-padding-medium-down{
                        padding-right: rem-calc(40);
                        padding-left: rem-calc(40);
                        @include breakpoint(small down){
                            padding-right: rem-calc(20);
                            padding-left: rem-calc(20);
                        }
                    }
                }
            }
            &-legenda {
                border-top: rem-calc(1) solid #cccccc;
                padding-top: rem-calc(24);
                padding-bottom: rem-calc(24);
                margin-right: rem-calc(10);
                @include breakpoint(small down) {
                    padding-top: rem-calc(12);
                    padding-bottom: rem-calc(12);
                    margin-right: 0;
                }
            }
            & .cc-language-container{
                flex-direction: column;
            }
            & li a {
                display: inline;
            }
        }
        & .country-selector li {
            font-size: rem-calc(12);
            line-height: rem-calc(14);
            padding-bottom: rem-calc(20);
            @include breakpoint (medium down){
                padding: rem-calc(15 0);
            }
            @include breakpoint(large up) {
                padding-top: rem-calc(10);
                padding-bottom: rem-calc(10);
            }
            &:last-of-type {
                border-bottom: 0;
            }
            &.force-hover-footer {
                @include breakpoint(medium down) {
                    position: relative;
                    &:before {
                        background-color: $grey6;
                        content: '';
                        position: absolute;
                        height: 100%;
                        right: -100%;
                        left: -100%;
                        z-index: 0;
                        top: 0;
                    }
                }
            }

            .language-container-internal {
                @include breakpoint(medium down) {
                    border-bottom: 0;
                    padding: rem-calc(15 40);
                }
                @include breakpoint(small down){
                    padding: rem-calc(15);
                }
            }

            label {
                @include breakpoint(medium down) {
                    span {
                        font-size: rem-calc(14);
                        font-weight: 400;
                    }
                }
            }
            i {
                margin-left: rem-calc(8);
                @include breakpoint(medium down) {
                    margin-left: rem-calc(14);
                }
                svg {
                    height: rem-calc(14);
                    width: rem-calc(14);
                }
            }
            & .placeholder {
                width: 14px;
                height: 14px;
            }
            label {
                margin-bottom: 0;
                &.boolean-field {
                    input[type="radio"]:checked + .radio-input:after {
                        transform: none;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &-box {
        @include breakpoint(medium down) {
            &:first-child{
                border-bottom: 1px solid $grey4;
            }
        }
        padding-top: rem-calc(40);
        @include breakpoint(large up) {
            &:first-child {
                padding-left: 0;
            }
            &:nth-child(2) {
                padding-left: rem-calc(40);
            }
        }
        &-small {
            padding-top: rem-calc(24);
            padding-bottom: rem-calc(24);
        }
        &-sides {
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);
            @include breakpoint(medium up) {
                padding-left: rem-calc(40);
                padding-right: rem-calc(40);
            }
        }
        &-left {
            padding-left: rem-calc(16);
            @include breakpoint(medium up) {
                padding-left: rem-calc(40);
            }
        }
        &-right {
            padding-right: rem-calc(16);
            @include breakpoint(medium up) {
                padding-right: rem-calc(40);
            }
        }
    }
    &-action-box {
        border-top: 1px solid $border-gray;
        &:nth-child(3),
        &:nth-child(4) {
            border-bottom: 1px solid $border-gray;
        }
        &:nth-child(odd) {
            border-right: 1px solid $border-gray;
        }
        @include breakpoint(medium up) {
            border-top: 0;
            border-bottom: 0;
            &:nth-child(3),
            &:nth-child(4) {
                border-top: 0;
                border-bottom: 0;
            }
            &:not(:last-child) {
                border-right: 1px solid $border-gray;
            }
        }
    }
    &-contacts {
        .input-group {
            margin-bottom: 0;
        }
        .footer-box {
            font-family: 'Messina Sans Light', sans-serif;
            @include breakpoint (medium down){
                border-right: 0 !important;
            }
            .title-box-contacts {
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                margin-bottom: rem-calc(7);
                font-weight: 300;
                font-family: "MessinaSansWeb", Arial, sans-serif;
                @include breakpoint(small down) {
                    font-size: rem-calc(20);
                }
                &.strong {
                    @include breakpoint(small down) {
                        font-size: rem-calc(20);
                        font-weight: $global-weight-dark;
                    }
                }
            }
            .text-box-contacts {
                font-size: rem-calc(12);
                line-height: rem-calc(12);
            }
            .input-newsletter {
                max-width: 100%;
                outline-width: 0;
                opacity: 0.4;
                @include breakpoint(medium down){
                    max-width: 100%;
                }
            }
        }
        .form-footer-center-small {
            @include breakpoint(medium down) {
                justify-content: left;
            }
        }
        .box-hide-for-small-general {
            @include breakpoint (medium down) {
                display: none;
            }
        }
    }
    &-bottom {
        .cell {
            padding-bottom: rem-calc(24);
        }
        padding: rem-calc(25 0);
        border-top: rem-calc(1) solid $medium-gray;
        background-color: $white;
        .cell {
            padding-bottom: 0;
        }
    }
    &-links {
        .footer-link-boxes {
            font-size: rem-calc(12);
            line-height: rem-calc(12);
            color: $black;
            font-family: 'Messina Sans Regular', sans-serif;
            @include breakpoint (large up) {
                padding: rem-calc(40 0);
            }
            &:first-of-type {
                > .content-asset {
                    @include breakpoint (large up) {
                        padding-left: rem-calc(40);
                    }
                }
            }
            &:not(:first-of-type) {
                > .content-asset {
                    @include breakpoint (large up) {
                        padding-left: rem-calc(30);
                    }
                }
            }
            &.active {
                .footer-links-category {
                    border: 0;
                }
            }
            @include breakpoint (medium down) {
                font-size: rem-calc(14);
            }
            .follow-us-box {
                width: 100%;
                @include breakpoint (medium down){
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: rem-calc(22 0);
                    .footer-links-category{
                        padding: 0 !important;
                    }
                }
                @include breakpoint(small down){
                    padding: rem-calc(20 0);
                }
            }
            &.legal-cell {
                display: flex;
                justify-content: center;
            }
            .footer-links-category {
                display: block;
                text-transform: uppercase;
                line-height: rem-calc(12);
                @include breakpoint (medium down) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(14);
                    &.footer-links-category-padding{
                        padding: rem-calc(25 0);
                        display: flex;
                        align-items: center;
                    }
                }
                @include breakpoint (small down) {
                    &.footer-links-category-padding{
                        padding: rem-calc(23 0);
                    }
                }
                @include breakpoint (large up) {
                    margin-bottom: rem-calc(20);
                }
                &.links-display-none-general-footer {
                    @include breakpoint (medium down) {
                        display: none;
                    }
                }
            }
            a[data-name="chat_with_us"] {
                display: flex;
                align-items: center;
                .available-chat,
                .not-available-chat {
                    list-style-position: outside;
                    list-style-type: none;
                    box-sizing: inherit;
                    line-height: 1rem;
                    padding: 0;
                    text-rendering: optimizeLegibility;
                    font-weight: normal;
                    margin: 0;
                    position: relative;
                    padding-left: .71429rem;
                    color: $success;
                    font-family: "MessinaSansWeb",Arial,sans-serif;
                    font-size: .85714rem;
                    letter-spacing: .01429rem;
                    margin-left: 10px;
                    margin-bottom: 28px;
                    @include breakpoint (large up) {
                        margin-top: 15px;
                        margin-bottom: 0;
                        line-height: 1;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: 48%;
                        left: 0;
                        transform: translateY(-50%);
                        width: .42857rem;
                        height: .42857rem;
                        background: $success;
                        border-radius: 1.42857rem;
                    }
                }
                .not-available-chat {
                    color: #FF0000;
                    &:before {
                        background: #FF0000;
                    }
                }
            }
            a.with-tooltip {
                display: flex;
                .icon-info-black {
                    border: none;
                    width: auto;
                    height: auto;
                    line-height: 1rem;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: "MessinaSansWeb", sans-serif;
                    letter-spacing: 0.2px;
                    margin-top: 0px;
                    padding-left: 10px;
                    margin-bottom: 30px;
                    transform: translateY(1.5px);
                    @include breakpoint (large up) {
                        margin-top: 15px;
                        margin-bottom: 0;
                        line-height: 12px;
                        transform: none;
                    }
                }
                .tooltip {
                    top: 5px;
                    filter: drop-shadow(1px 1px 10px #a2a2a2);
                    &-bottom-arrow {
                        $arrow-border: rem-calc(15);
                        content: '';
                        position: absolute;
                        left: rem-calc(60);
                        bottom: rem-calc(-15);
                        border-top: $arrow-border solid $white;
                        border-left: $arrow-border solid transparent;
                        border-right: $arrow-border solid transparent;
                        z-index: 20;
                    }
                }
            }
            .footer-links-actions{
                margin-top: rem-calc(15);
                margin-bottom: 0;
                line-height: rem-calc(12);
                @include breakpoint(medium down){
                    font-size: rem-calc(14);
                    margin-bottom: rem-calc(30);
                    margin-top: 0;
                    line-height: rem-calc(14);
                }
            }
            .footer-links-section {
                a {
                    font-weight: 400;
                    display: block;
                    &[data-name="contact_us"]{
                        display: none;
                    }
                }
                &.links-display-none-general-footer {
                    @include breakpoint (medium down) {
                        display: none;
                    }
                }
            }
            &.links-display-none-general-footer {
                @include breakpoint (medium down) {
                    display: none;
                }
            }
            &.qr-tr-flc-div {
                display: none;
                @include breakpoint (medium down) {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    padding: rem-calc(5 0 25);
                }
            }
        }
    }
    &-links-pre-home {
        @include breakpoint (large up) {
            padding: rem-calc(42 0);
        }
        @include breakpoint (medium down) {
            padding-bottom: rem-calc(30);
        }
        .footer-link-boxes {
            font-size: rem-calc(13);
            letter-spacing: rem-calc(0.6);
            line-height: rem-calc(22);
            @include breakpoint (medium down) {
                padding-left: rem-calc(17);
                padding-top: rem-calc(55);
                &:first-child,
                &:nth-child(2) {
                    padding-top: 0;
                }
            }
            .footer-links-category {
                display: block;
                font-weight: 500;
                @include breakpoint (large up) {
                    margin-bottom: rem-calc(15);
                    font-size: rem-calc(16);
                }
                @include breakpoint (medium down) {
                    font-size: rem-calc(16);
                    line-height: rem-calc(40);
                    letter-spacing: rem-calc(0.91);
                }
                &.prehome-display-none {
                    display: none;
                }
            }
            .footer-links-section {
                a {
                    font-weight: 500;
                    display: block;
                    @include breakpoint (medium down) {
                        font-size: rem-calc(14);
                        line-height: rem-calc(37);
                        letter-spacing: rem-calc(1.17);
                    }
                }
                .todays-promo {
                    margin-top: rem-calc(15);
                }
                .prehome-display-none {
                    display: none;
                }
            }
        }
        .prehome-display-none {
            display: none;
        }
    }
    .prehome-country-selector {
        border-top: 1px solid $border-gray;
        .box-footer-legal-right {
            @include breakpoint(medium down) {
                display: none;
            }
        }
        .link-boxes-prehome {
            text-align: center;
            justify-content: flex-end;
            @include breakpoint(large up) {
                height: 100%;
            }
            @include breakpoint(medium down) {
                width: 100%;
                border-bottom: 1px solid $border-gray;
            }
            .prehome-footer-card-box {
                display: inline-block;
                @include breakpoint(large up) {
                    height: 100%;
                    max-width: rem-calc(196);
                    border-left: 1px solid $border-gray;
                    padding: rem-calc(8 0);
                    &:first-child {
                        border-left: 0;
                    }
                }
                @include breakpoint(medium down) {
                    padding: rem-calc(14 18);
                    &:first-child {
                        border-right: 1px solid $border-gray;
                    }
                }
                .img-box-card {
                    width: rem-calc(18);
                    height: rem-calc(18);
                    margin-right: rem-calc(8);
                    display: inline-block;
                    @include breakpoint(medium down) {
                        margin: auto;
                        display: block;
                        margin-bottom: rem-calc(7);
                    }
                    img {
                        @include breakpoint(large up) {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }
                a {
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: 500;
                    line-height: rem-calc(15);
                    letter-spacing: rem-calc(0.5);
                    text-transform: uppercase;
                    font-size: rem-calc(12);
                    @include breakpoint(large down) {
                        font-size: rem-calc(10);
                    }
                    @include breakpoint (medium down) {
                        font-size: rem-calc(12);
                    }
                }
            }
        }
    }
    .prehome-footer-bottom {
        @include breakpoint(large up) {
            .follow-us-box {
                padding-right: rem-calc(30);
            }
            .follow-us-title {
                display: inline-block;
                text-transform: uppercase;
                font-size: rem-calc(12);
                line-height: rem-calc(14);
                font-weight: 500;
                margin-right: rem-calc(30);
            }
            .icon-footer-follow-mobile {
                margin-right: rem-calc(30);
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .footer-contacts-pre-home {
        .footer-contacts {
            background-color: $white;
        }
        .form-footer-center-small-general {
            @include breakpoint(medium down) {
                justify-content: left;
            }
        }
        .visible-small-only-prehome-footer {
            display: block;
        }
        .visible-only-general-footer {
            display: none;
        }
        .footer-box {
            margin: 0;
            padding-top: rem-calc(29);
            padding-bottom: rem-calc(10);
            @include breakpoint(medium down) {
                padding: rem-calc(18);
                padding-bottom: 0;
                &:first-child {
                    border-bottom: 1px solid $border-gray;
                }
                .title-box-contacts {
                    font-size: rem-calc(17);
                    text-align: left;
                    line-height: rem-calc(24);
                }
            }
        }
        .box-hide-for-small-general {
            @include breakpoint (medium down) {
                display: block;
            }
        }
    }
    &-actions {
        a {
            i {
                margin-right: rem-calc(12);
                @include breakpoint(small down) {
                    display: block;
                    margin-right: 0;
                    margin-bottom: rem-calc(6);
                }
            }
        }
    }
    &-change-country-social {
        &.border-top {
            @include breakpoint(medium down){
                border-top: 0 !important;
            }
        }
        .footer-box-small {
            @include breakpoint(large up){
                &:not(:first-of-type){
                    padding-left: rem-calc(30);
                }
                border-right: 1px solid $border-gray;
                cursor: pointer;
            }

            text-transform: uppercase;
            &.js-country{
                @include breakpoint(large up){
                    padding-left: rem-calc(40);
                }
            }
            @include breakpoint(medium down) {
                border-right: none !important;
                padding: rem-calc(20 40);
                border-bottom: 0 !important;
                border-top: 0 !important;
            }
            @include breakpoint(small down){
                padding: rem-calc(20 15);
            }
            i {
                margin-left: rem-calc(40);
            }
        }
        a {
            display: block;
            position: relative;
        }
        span {
            font-size: rem-calc(12);
            letter-spacing: .0125rem;
            @include breakpoint(large up) {
                display: inline;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            @include breakpoint(medium down){
                font-size: rem-calc(14);
            }
        }
    }
    .icon-toggle-top {
        width: rem-calc(16);
        height: rem-calc(16);
        margin-left: rem-calc(5);
        @include breakpoint(medium down){
            right: rem-calc(40);
        }
        @include breakpoint(small down){
            right: rem-calc(20);
        }
    }
    .box-footer-legal-right {
        text-align: right;
        .footer-box-small-legal-company {
            padding: rem-calc(12 30);
            display: inline-block;
            text-align: center;
            @include breakpoint(large up) {
                width: 44%;
                height: 100%;
                padding: rem-calc(12 0);
            }
            @include breakpoint(medium down) {
                display: block;
                text-align: center;
                border-bottom: 1px solid $border-gray;
            }
            &:first-child {
                @include breakpoint(large up) {
                    border-right: 1px solid $border-gray;
                }
            }
            a {
                font-weight: 500;
                text-transform: uppercase;
                font-size: rem-calc(12);
                @include breakpoint(large down) {
                    font-size: rem-calc(10);
                }
                @include breakpoint(medium down) {
                    font-size: rem-calc(12);
                }
            }
            &.company {
                @include breakpoint(large up) {
                    width: 56%;
                    float: left;
                }
            }
        }
    }
    .country-width-row {
        @include breakpoint(large up) {
            position: relative;
        }
        @include breakpoint(medium down) {
            width: 100%;
        }
    }
    &-social {
        min-height: rem-calc(68);
        a {
            &:not(:last-of-type) {
                margin-right: rem-calc(16);
            }
        }
    }
    .icon-country {
        margin-right: rem-calc(8);
    }
    .icon-toggle {
        @include triangle(rem-calc(6), $black);
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        right: rem-calc(24);
    }
    .icon-footer-follow-mobile {
        @include breakpoint(medium down) {
            padding: rem-calc(0 24);
        }
    }
    &-actions,
    &-change-country-social,
    &-bottom {
        p {
            margin-bottom: 0;
        }
    }
    @include borders($border-gray, medium up);
    .card-box-footer {
        border-right: rem-calc(1) solid $border-gray;
        border-left: rem-calc(1) solid $border-gray;
        justify-content: center;
        @include breakpoint(medium down) {
        border-top:  rem-calc(1) solid $border-gray;
        }
        .card-box {
            text-align: center;
            @include breakpoint(medium down) {
                padding: rem-calc(15 0);
            }
            &:first-child {
                margin-bottom: rem-calc(40);
                @include breakpoint(medium down) {
                    border-right: rem-calc(1) solid $border-gray;
                    margin-bottom: 0;
                }
            }
            img {
                width: rem-calc(18);
                margin-bottom: rem-calc(13);
            }
            .card-box-title {
                text-transform: uppercase;
                font-size: rem-calc(12);
                line-height: rem-calc(15);
                letter-spacing: rem-calc(0.5);
                font-weight: 500;
                margin-bottom: 0;
                display: block;
                @include breakpoint(large down) {
                    font-size: rem-calc(10);
                }
                @include breakpoint(medium down) {
                    font-size: rem-calc(12);
                }
            }
        }
    }
    .follow-us-footer-box {
        padding: rem-calc(0 48);
        .follow-us-title {
            text-transform: uppercase;
            font-size: rem-calc(12);
            line-height: rem-calc(15);
            letter-spacing: rem-calc(0.5);
            font-weight: 500;
            margin-bottom: rem-calc(30);
            @include breakpoint(medium down) {
                font-size: rem-calc(12);
            }
            @include breakpoint(large down) {
                font-size: rem-calc(10);
            }
        }
        a {
            &:not(:last-of-type) {
                margin-right: rem-calc(30);
            }
        }
        @include breakpoint (large down) {
            padding: rem-calc(0 20);
            a {
                &:not(:last-of-type) {
                    margin-right: rem-calc(9);
                }
            }
        }
    }
    .footer-company-info {
        text-transform: uppercase;
        p {
            line-height: rem-calc(17);
        }
    }
    .footer-close-button {
        position: absolute;
        top: rem-calc(13);
        right: rem-calc(26);
        svg {
            color: black;
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }
    .payments-method-footer {
        img {
            width: rem-calc(625);
            height: auto;
        }
        padding-right: rem-calc(25);
    }
}

// Country and Language Selector Modal
.tingle-modal{
    &.cc-main-footer-countryChooserModalContainer,
    &.cc-main-footer-languageChooserModalContainer{
        background: rgba(0, 0, 0, 0.40);
    }
    .cc-main-footer{

        &-countryChooserModal,
        &-languageChooserModal{
            background-color: $white;
            @include breakpoint (large down){
                max-width: 80vw;
                min-height: 20vh;
            }
            @include breakpoint (small down){
                width: 100vw;
                max-width: 100vw;
                min-height: 70vh;
                height: 75vh;
                margin-bottom: 0;
            }
            .tingle-modal-box__content{
                background-color: $white;
                padding: rem-calc(0 32 32);
                @include breakpoint (small down){
                    padding: rem-calc(0 16 48);
                }
            }
            & .cc-language-container{
                flex-direction: column;
            }
        }
        // applied in the mobile with show-for-small-only for positioning
        &-mobileHeaderSpacer{
            width: rem-calc(14);
        }
        &-chooseCountryHeader,
        &-chooseLanguageHeader{
            @include breakpoint (medium down){
                position: sticky;
                top: 0;
                background-color: $white;
                padding: rem-calc($xs);
                z-index: 1;
                border-bottom: rem-calc(1) solid $border-medium-grey;
                margin-left: rem-calc(-$xs);
                width: 100dvw;
                &_title{
                    margin-bottom: 0;
                }
            }
        }
        &-countrySelectorInnerContainer{
            @include breakpoint (medium down){
                display: unset;
            }
        }
        &-countrySelectorInner{
            @include breakpoint (medium down){
                margin: rem-calc(10 0);
            }
        }
        &-languageList{
            display: flex;
            align-items: center;
            height: rem-calc(44);
            &-icon {
                margin-left: rem-calc($xxs);
            }
        }
        &-inputGroup{
            &-input{
                display: none;
            }
        }
    }
}

.scrollbar-custom {
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: rem-calc(2);
    }
    &::-webkit-scrollbar-thumb {
        background-color: $black;
        border-radius: rem-calc(0);
        height: rem-calc(200);
    }
}

.footer-bottom-checkout {
    padding: rem-calc(17);
    border-top: 1px solid $border-gray;
    position: relative;
    background: $white;
    margin-top: rem-calc(36);
    @include breakpoint(small down) {
        margin-top: rem-calc(46);
    }
    p {
        margin-bottom: 0;
        line-height: rem-calc(17);
        font-size: rem-calc(9);
    }
}

.footer-bottom {
    &.border-top {
        padding-bottom: rem-calc(25);
    }
}

.only-visible-prehome-links {
    .link-boxes-prehome {
        display: none;
    }
}

.overlay-footer-language {
    bottom: rem-calc(155);
    right: 0;
    left: 0;
    height: 100%;
    z-index: 801;
    display: none;
}

.footer-contacts-background {
    border-bottom: rem-calc(1) solid $grey4;
}

.footer-container-nopadding-mobile {
    @include breakpoint(large up) {
        &.grid-container {
            padding: 0;
        }
    }
}

.wrapper-gray-footer-prehome {
    border-top: 1px solid $border-gray;
}

$include-fonts: false;
@import "../setup/settings";
@import "../mixins/helpers";

.marginPrivacy {
    margin-bottom: rem-calc(14);
}

.checkboxMarginMobileDesktopTargeted {
    @include breakpoint (large up) {
        width: 30%;
    }
}

#newsletterCountry.is-invalid-input:not(:focus) {
    background-position: right;
    background-repeat: no-repeat;
}

.cc-targetedNewsAndPromotions-container {
    &.disabled-input {
        p {
            color: $medium-more-gray;
        }

        .slider {
            background-color: $medium-gray;
            cursor: not-allowed;
        }
    }
}

// Rules for newsletter modal && page
div[data-action="Newsletter-Showpage"],
.cc-newsLetterModal {
    .cc-registrationFormStepOne-content {
        &_description {
            margin-bottom: rem-calc($s);
            @include breakpoint(large up) {
                margin-bottom: rem-calc($xs);
            }
        }
        
        &_descriptionUsed {
            display: none;
            margin: 0;
            @include breakpoint(large up) {
                display: block;
            }
        }

        &_fields {
            @include flex(column, initial, initial);
            gap: rem-calc($xxs);
            margin-bottom: rem-calc($s);
            @include breakpoint(large up) {
                margin: rem-calc($xs 0);
            }
            .cc-form-item {
                input {
                    margin-bottom: rem-calc($xxs);
                }
            }
            #newsletterGender, #newsletterCountry {
                margin-bottom: rem-calc($xxs);
            }
            .cc-genderField,
            .cc-countryField {
                .cc-input-label {
                    display: none;
                }
                .cc-label-inside-active {
                    .cc-input-label {
                        display: block;
                    }
                }
            }
        }

        .cc-boolean-field {
            padding-right: 0;
            margin-bottom: rem-calc($s);
            @include breakpoint(large up) {
                margin-bottom: rem-calc($xs);
            }
        }
    }

    .cc-registrationFormStepTwo,
    .cc-registrationFormStepTwoSuccess {
        &-content {
            .cc-feedbackMessage-container {
                margin-bottom: rem-calc($s);
                @include breakpoint(large up) {
                    margin-bottom: rem-calc($xs);
                }
            }

            &_email,
            &_country {
                @include flex(column, initial, initial);
            }

            &_email {
                margin-bottom: rem-calc($s);
            }
        }
    }
}

// Rules for newsletter modal
.cc-newsLetterModal {
    .tingle-modal-box {
        bottom: 0;
        min-height: unset;
        @include breakpoint(large up) {
            min-height: 100%;
        }

        &__content {
            padding: 0;
            background: $surface-white;
        }
    }

    &-wrapper {
        @include flex(column, initial, initial);
        position: relative;
        height: 100%;
    }

    &-header {
        @include flex(column, center, center);
        background-color: $surface-white;
        border-bottom: rem-calc(1) solid $border-medium-grey;
        height: rem-calc(56);
        padding: rem-calc($xs);
        flex: 0 0 auto;
        position: sticky;
		top: 0;
        z-index: 1;

        @include breakpoint(large up) {
            height: rem-calc(52);
            padding: rem-calc($xxs $s);
        }

        &-label {
            font-size: rem-calc(getFontSize('textLarge'));
            letter-spacing: rem-calc(getLetterSpacing('textLarge'));
            line-height: rem-calc(getLineHeight('textLarge'));
        }
    }

    &-closeButton {
        @include flex(row, initial, initial);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: rem-calc(44);
        height: rem-calc(44);
        justify-content: center;
        align-items: center;
        right: 0;
        color: $text-black;
    }

    .cc-registrationFormStepOne,
    .cc-registrationFormStepTwo,
    .cc-registrationFormStepTwoSuccess {
        @include flex(column, initial, initial);
        height: calc(100vh - #{rem-calc(46)} - #{rem-calc(56)});

        @include breakpoint(large up) {
            height: calc(100vh - #{rem-calc(52)});
        }

        &.hidden {
            display: none;
        }

        &-content {
            @include flex(column, initial, initial);
            flex: 1;
            overflow-y: auto;
            overscroll-behavior: contain;
            padding: rem-calc($s $xs);

            @include breakpoint(large up) {
                padding: rem-calc($m);
            }

            @include breakpoint(small only) {
                padding-bottom: rem-calc(74);
            }

            &-policyLinks {
                margin-top: auto;
                &_text {
                    margin: 0;
                }
            }
        }
        &-footer {
            background-color: $surface-white;
            border-top: rem-calc(1) solid $border-medium-grey;
            flex: 0 0 auto;
            padding: rem-calc($xs);
            @include breakpoint(large up) {
                padding: rem-calc($xs $m);
            }
        }
    }
}

// Rules for newsletter page
div[data-action="Newsletter-Showpage"] {
    .cc-newsletterPage-imageForSmall {
        @include breakpoint(large up) {
            display: none;
        }
    }
    .cc-newsletterPage {
        flex-grow: 1;
        width: 100%;
        margin-bottom: rem-calc($s);
        margin-left: auto;
        margin-right: auto;
        padding: rem-calc(0 $xs);
        @include breakpoint(large up) {
            padding: rem-calc(0 $l);
        }

        &-wrapper {
            display: flex;
            flex-flow: row wrap;

            &_imageForLarge {
                display: none;
                @include breakpoint(large up) {
                    display: block;
                    flex: 0 0 auto;
                    min-height: 0;
                    min-width: 0;
                    width: 50%;
                }   
            }

            &-mainContent {
                flex: 0 0 auto;
                min-height: 0;
                min-width: 0;
                width: 100%;
                margin-top: rem-calc($s);
                @include breakpoint(large up) {
                    width: 33.33333%;
                    margin-left: 8.33333%;
                }
            }
        }

        .cc-registrationFormStepTwo {
            &-content {
                margin: rem-calc($xs 0);
            }
            &-content-policyLinks,
            &-footer {
                display: none;
            }
        }
    }
}

.cc-newsletterConfirm {
    @include flex(column, initial, center);
    padding: rem-calc(0 $xs);
    height: 100vh;
    &-container {
        @include flex(column, center, center);
        gap: rem-calc($xs);
        width: 100%;
        text-align: center;
        @include breakpoint(large up) {
            width: 30%;
        }
    }
}

.cc-newsletterPageError {
    @include flex(column, center, center);
    padding: rem-calc(0 $xs);
    margin-top: rem-calc(64);
}

.cc-conditionPrivacyPolicy-modal {
    z-index: 3011;
}

.tingle-modal-form-request {
    .tingle-modal-box.rightModalAttribute{
        overflow-y: auto !important;
        min-height: 100% !important;
        height: 100% !important;
        display: unset !important;
        .tingle-modal-box__content{
            @include breakpoint(large up){
                padding: rem-calc(0 0 40);
                @include breakpoint(large up){
                    padding: 0;
                    height: calc(100% - 94px);
                }
                @media screen and (max-width: 1367px) {
                    height: 100%;
                }
            }
            @include breakpoint(medium down){
                padding: 0;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            height: 100%;
        }
    }
    .close-button-modal {
        position: absolute;
        top: rem-calc(5);
        right: 0;
    }
    @include breakpoint(medium up){
        .padding-fix-right{
            padding-right: rem-calc(5);
        }
        .padding-fix-left{
            padding-left: rem-calc(5);
        }
    }
    .text-area-web-form-request {
        border: 1px solid $grey3;
        resize: none;
        padding: rem-calc(20);
        background-color: $white;
    }
    .cell-for-required-field-info {
        display: flex;
        align-items: flex-end;
    }
    .more-info-text-form {
        margin-bottom: 0;
        font-size: rem-calc(9);
    }
    .link-margin-right-form {
        margin-right: rem-calc(10);
    }
    @include breakpoint (medium down) {
        &.tingle-modal {
            &.tingle-modal--visible {
                justify-content: flex-end;
                background: none;
            }
        }
    }
    @include breakpoint(medium only){
        .contact-form{
            height: calc(100vh - 100px);
        }
        .form-group{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .hover-style:hover{
        background-color: transparent;
    }
}

.pos-relative {
    position: relative;
}

.seo-description-full {
    padding: rem-calc(0 40);
    text-align: center;
}

.no-padding-left {
    padding-left: 0 !important;
}

.padding-bottom-10 {
    padding-bottom: rem-calc(10) !important;
}

.order-status-image {
    width: 100%;
}

#request-result-message-success , #request-result-message-error {
    padding: rem-calc(0 40);

    @include breakpoint (medium down) {
        padding: rem-calc(20);
    }
}

.newsletter-complete-thanks{
    padding-top: rem-calc(60);
    text-align: center;
    height: 100vh;
    @include breakpoint(medium down){
        height: 50vh;
    }
    @include breakpoint(small down){
        height: 45vh;
        padding-top: 0;
    }
    .text-confirm{
        margin-bottom: rem-calc(30);
        line-height: rem-calc(22);
    }
}

.page[data-action="Return-SubmitRefund"]{
    .footer-checkout{
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}
.footer_column_loyalty{
    @include breakpoint (medium down){
        margin-left: rem-calc(-15);
        margin-right: rem-calc(-15);
        width: 100vw;
    }
    width: rem-calc(379);
    position: relative;
    height: 100%;

    &-img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top left;
    }
    &-container{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem-calc(31);
    }
    &-text{
        color: $white;
        font-size: rem-calc(12);
        letter-spacing: rem-calc(0.2);
        line-height: rem-calc(18);
        text-align: left;
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(20);
    }
    &-link{
        color: $white;
        font-size: rem-calc(14);
        letter-spacing: rem-calc(0.2);
        text-align: left;
        line-height: rem-calc(14);

        &.force-hover{
            border-bottom: rem-calc(1) solid $white;
            &:not(.no-hover):not(.button):hover{
                color: $white;
            }
        }
    }
}

.cc-privacyFlagEnable{
    .tingle-modal-box__content {
        padding: 0;
    }

    &-header {
        @include flex(row, center, center);
        padding: rem-calc($xs);
        flex: 0 0 auto;
        position: relative;
        border-bottom: rem-calc(1) solid $border-medium-grey;
        margin-bottom: rem-calc($s);
        @include breakpoint(large up) {
            margin-bottom: rem-calc($m);
        }
    }

    &-closeButton {
        @include flex(row, initial, initial);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: rem-calc(44);
        height: rem-calc(44);
        justify-content: center;
        align-items: center;
    }

    &-item {
        margin: rem-calc(0 $s);

        @include breakpoint(large up) {
            margin: rem-calc(0 $m);
        }
    }
}

.cc-simpleFooter {
    padding: rem-calc($s $xs);
    border-top: rem-calc(1) solid $border-grey;
    border-bottom: rem-calc(1) solid $border-grey;
    @include breakpoint(large up) {
        padding: rem-calc($xs $m);
    }

    &-content {
        /* Content asset code */
        p {
            margin-bottom: 0;
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textSmall'));
            letter-spacing: rem-calc(getLetterSpacing('textSmall'));
            line-height: rem-calc(getLineHeight('textSmall'));
        }
    }
}

// Footer Order Status Form
.cc-orderStatusForm{
    &-container-imageMobile, &-inner-image{
        // content asset "order-status-image"
        .ca-order-status-image{
            width: 100%;
        }
    }
    &-inner{
        display: flex;
        &-image{
            width: 44.4445vw;
        }
        &-formInfo{
            width: 55.5566vw;
            display: flex;
            justify-content: center;
            @include breakpoint (medium down){
                width: 100%;
                padding: rem-calc($m $xs);
            }
        }
    }
    &-formContainer{
        width: rem-calc(464);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &-form{
        display: flex;
        flex-direction: column;
        gap: rem-calc($xs);
    }
    &-buttonSubmit{
        &_button{
            @include breakpoint (medium down){
                width: 100%;
            }
        }
    }
    &-buttonLogin{
        @include breakpoint (medium down){
            text-align: center;
        }
    }
    &-headerMobile{
        @include breakpoint (small down){
            &_backButton{
                height: rem-calc(44);
                display: flex;
                align-items: center;
                gap: rem-calc($xxs);
                margin: rem-calc(0 $xs);
            }
        }
    }
}