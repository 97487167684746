/* gradient */
$styleList-overlayGradient: (
    gradient: linear-gradient(180deg, rgba($surface-dark, 0) 50%, rgba($surface-dark, 0.4) 100%),
    blackOpacityMedium: rgba($surface-dark, 0.4)
);
@function getOverlayGradient($value) {
    @return map-get($styleList-overlayGradient, $value);
}
@each $typeStyle in map-keys($styleList-overlayGradient) {
    .cc-overlay-#{$typeStyle} {
        background: getOverlayGradient($typeStyle);
    }
}