@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello/fontello.eot');
    src: url('../fonts/fontello/fontello.eot#iefix') format('embedded-opentype'),
        url('../fonts/fontello/fontello.woff2') format('woff2'),
        url('../fonts/fontello/fontello.woff') format('woff'),
        url('../fonts/fontello/fontello.ttf') format('truetype'),
        url('../fonts/fontello/fontello.svg#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*=" icon-"]:after,
[class*=" icon-"]:before,
[class^=icon-]:after,
[class^=icon-]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow_right:before {
    content: '\e800';
}

/* '' */
.icon-googleplus:before {
    content: '\e801';
}

/* '' */
.icon-customer_service:before {
    content: '\e802';
}

/* '' */
.icon-icon-care-wetcleaning_55:before {
    content: '\e803';
}

/* '' */
.icon-not_available_item:before {
    content: '\e804';
}

/* '' */
.icon-icon-care-wetcleaning_57:before {
    content: '\e805';
}

/* '' */
.icon-quantity_minus:before {
    content: '\e806';
}

/* '' */
.icon-icon-care-wetcleaning_56:before {
    content: '\e807';
}

/* '' */
.icon-yahoo:before {
    content: '\e808';
}

/* '' */
.icon-icon-care-wetcleaning_58:before {
    content: '\e809';
}

/* '' */
.icon-wish:before {
    content: '\e80a';
}

/* '' */
.icon-return:before {
    content: '\e80b';
}

/* '' */
.icon-vk:before {
    content: '\e80c';
}

/* '' */
.icon-addresses:before {
    content: '\e80d';
}

/* '' */
.icon-quantity_plus:before {
    content: '\e80e';
}

/* '' */
.icon-icon-care-ironing_21:before {
    content: '\e80f';
}

/* '' */
.icon-icon-care-ironing_22:before {
    content: '\e810';
}

/* '' */
.icon-icon-care-machinewash_23:before {
    content: '\e811';
}

/* '' */
.icon-icon-care-machinewash_25:before {
    content: '\e812';
}

/* '' */
.icon-icon-care-machinewash_24:before {
    content: '\e813';
}

/* '' */
.icon-info:before {
    content: '\e814';
}

/* '' */
.icon-instagram:before {
    content: '\e815';
}

/* '' */
.icon-loading_heart.svg:before {
    content: '\e816';
}

/* '' */
.icon-login:before {
    content: '\e817';
}

/* '' */
.icon-menu:before {
    content: '\e818';
}

/* '' */
.icon-messenger:before {
    content: '\e819';
}

/* '' */
.icon-newsletter:before {
    content: '\e81a';
}

/* '' */
.icon-shipping {
    transform: scaleX(-1);
}
.icon-shipping:before {
    content: '\e81b';
}

/* '' */
.icon-search:before {
    content: '\e81c';
}

/* '' */
.icon-plus:before {
    content: '\e81d';
}

/* '' */
.icon-share:before {
    content: '\e81e';
}

/* '' */
.icon-store:before {
    content: '\e81f';
}

/* '' */
.icon-shopping_bag_white:before {
    content: '\e820';
}

/* '' */
.icon-shopping_bag:before {
    content: '\e821';
}

/* '' */
.icon-tryon_01:before {
    content: '\e822';
}

/* '' */
.icon-twitter:before {
    content: '\e823';
}

/* '' */
.icon-arrow_down_white:before {
    content: '\e824';
}

/* '' */
.icon-arrow_down:before {
    content: '\e825';
}

/* '' */
.icon-arrow_left_01:before {
    content: '\e826';
}

/* '' */
.icon-arrow_left_02:before {
    content: '\e827';
}

/* '' */
.icon-arrow_right_01:before {
    content: '\e828';
}

/* '' */
.icon-arrow_right_01_white:before {
    content: '\e829';
}

/* '' */
.icon-arrow_right_02:before {
    content: '\e82a';
}

/* '' */
.icon-arrow_up:before {
    content: '\e82b';
}

/* '' */
.icon-call:before {
    content: '\e82c';
}

/* '' */
.icon-chat:before {
    content: '\e82d';
}

/* '' */
.icon-minus:before {
    content: '\e82e';
}

/* '' */
.icon-check:before {
    content: '\e82f';
}

/* '' */
.icon-facebook:before {
    content: '\e830';
}

/* '' */
.icon-gift_card:before {
    content: '\e831';
}

/* '' */
.icon-icon-care-bleaching_11:before {
    content: '\e832';
}

/* '' */
.icon-icon-care-drycleaning_5:before {
    content: '\e833';
}

/* '' */
.icon-icon-care-drying_13:before {
    content: '\e834';
}

/* '' */
.icon-icon-care-drycleaning_6:before {
    content: '\e835';
}

/* '' */
.icon-icon-care-hangout_18:before {
    content: '\e836';
}

/* '' */
.icon-icon-care-hangout_16:before {
    content: '\e837';
}

/* '' */
.icon-icon-care-ironing_20:before {
    content: '\e838';
}

/* '' */
.icon-icon-care-ironing_19:before {
    content: '\e839';
}

/* '' */
.icon-gift-wrap:before {
    content: '\e83a';
}

/* '' */
.icon-icon-care-machinewash_27:before {
    content: '\e83b';
}

/* '' */
.icon-icon-care-machinewash_26:before {
    content: '\e83c';
}

/* '' */
.icon-icon-care-wetcleaning_59:before {
    content: '\e83d';
}

/* '' */
