@import "fontello";
@import "sangbleu-sunrise";
@import "messina-sans";
@import "noto-sans";
@import "noto-serif";

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

[class*=" icon-"]:after,
[class*=" icon-"]:before,
[class^=icon-]:after,
[class^=icon-]:before {
    font-size: 1.5rem;

    &.fe-lg {
        font-size: 1.75rem;
    }

    &.fe-2x {
        font-size: 2rem;
    }

    &.fe-3x {
        font-size: 3rem;
    }
}

.icon-shipping {
    &:before,
    &:after {
        font-size: 1.25rem;
    }
}
