// SLICK ARROW CSS FOR ALL CAROUSEL EXCEPT PDP AND QUICKVIEW
.carousel-einstein-recently-viewed,
.hp-four-slider-tabs,
.hp-four-slider,
.hp-hero-banner-title-cta,
.js-three-slider-arrow,
.js-three-slider-dots,
.js-four-slider-arrow,
.js-six-slider-arrow {

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        height: rem-calc(20);
        width: rem-calc(20);
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        //background: black;
        //color: #000000;
        padding: 0;
        border: none;
        outline: none;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: rem-calc(23);
            height: rem-calc(36);
            background-size: 50% 50%;
            background-repeat: no-repeat;
        }
    }

    .slick-prev {
        @include breakpoint (medium down) {
            left: rem-calc(-25);
            z-index: 500;
        }

        @include breakpoint (small down) {
            left: rem-calc(-25);
            z-index: 500;
        }

        &:before {
            background-image: url('../images/ico-arrow-left.svg');
            background-size: rem-calc(14);
            background-position: rem-calc(0 6);

        }
    }

    .slick-next {
        @include breakpoint (medium down) {
            right: rem-calc(-30);
            z-index: 500;
        }

        @include breakpoint (small down) {
            right: rem-calc(-30);
            z-index: 500;
        }

        &:before {
            background-image: url('../images/ico-arrow-right.svg');
            background-size: rem-calc(14);
            background-position: 8% 6px;
        }
    }

}

/* arrow for Carousel (no PDP and quickview) */

// GENERIC COLOR AND DIMENSION (ONLY)
.slick-dots {
    li {
        button {
            &:before {
                width: rem-calc(6);
                height: rem-calc(6);
                border: rem-calc(1) solid $more-light-gray;
                background: $more-light-gray;

            }
        }

        &.slick-active {
            button {
                &:before {
                    width: rem-calc(6);
                    height: rem-calc(6);
                    border: rem-calc(1) solid $black;
                    background: $black;

                }
            }
        }
    }
}

// GENERIC HREF FOR DIV
a {
    &.all-div-block {
        display: block;
        padding: 0;
        border: 0;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}


.component3img3link3price {

    text-align: center;
    margin: 0 auto;
    width: 100%;
    text-align: center;

    @include breakpoint (small down) {
        width: 80%;
    }

    @include breakpoint (medium down) {
        width: 80%;
    }

    h3 {
        font-weight: 500;
        text-align: left;

        @include breakpoint (small down) {
            font-size: rem-calc(18);
        }
    }

    /*  .slick-dots {
        position: relative;
        bottom: rem-calc(16);
        margin-top: rem-calc(20);
    //  right: rem-calc(20);
        width: inherit;
        @include breakpoint (small down) { 
            right: inherit;
            width: 100%;
            bottom: 0;
        }
        li  {
            button {
                &:before {
                    width: rem-calc(6);
                    height: rem-calc(6);
                    border: rem-calc(1) solid $more-light-gray;
                    background: $more-light-gray;
                    @include breakpoint (small down) {
                        border: rem-calc(1) solid $more-light-gray;
                        background: $more-light-gray;
                        opacity: 1;
                    }
                }
            }
            &.slick-active {
                button {
                    &:before {
                        width: rem-calc(6);
                        height: rem-calc(6);
                        border: rem-calc(1) solid $black;
                        background: $black;   
                        @include breakpoint (small down) {
                            border: rem-calc(1) solid $black;
                            background: $black;   
                            opacity: 1;
                        }           
                        }
                }
            }


        }
        
        
    } */

    .three-slider-arrow {

        // width: 80%;
        .product {
            margin: rem-calc(18);

            @include breakpoint (small down) {
                margin: rem-calc(9);

            }

            .pdp-link {
                font-size: rem-calc(13);
                font-weight: 400;
                text-align: left;
                padding-top: rem-calc(4);
            }

            .price-container {
                font-size: rem-calc(13);
                font-weight: 500;
                text-align: left;
                padding-top: rem-calc(15);

            }

            .product-tile__strip {
                display: none;
            }

            .promo-message {
                height: rem-calc(21);
            }

        }
    }

    /*     .slick-list{ } */
}

//--------

@keyframes auto-progress {
    0% {
        width: 0%;
    }

    100% {
        width: 98%;
    }
}

.progress-bar {
    background-color: #1a1a1a;
    height: 1px;
    width: 450px;
    margin: 50px auto;
    border-radius: 5px;
    box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
}


.progress-bar-inner {
    display: block;
    height: 4px;
    width: 0%;
    background-color: black;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, .5) inset;
    position: relative;
    animation: auto-progress 10s infinite linear;
}

//------------


.slider-todays-promo {
    &.slick-dotted {
        .slick-dots {
            li {
                button {
                    &:before {
                        background: none;

                    }
                }
            }
        }
    }

    .slick-dots {
        display: table;
        width: 44%;
        table-layout: fixed;
        position: absolute;
        top: rem-calc(79);
        left: rem-calc(30);
        left: 2%;
        right: 2%;

        @include breakpoint (small down) {
            width: 96%;

        }

        li {
            display: table-cell;

            button {
                width: 98% !important;
                height: rem-calc(1) !important;
                background: $black;

                &:before {
                    content: '';
                    width: 0 !important;
                    height: rem-calc(1);
                    border-radius: 0;
                    border: 0;
                    background: none;
                    opacity: 1;
                }
            }

            &.slick-active {
                button {
                    &:before {
                        content: '';
                        width: 98% !important;
                        height: rem-calc(3) !important;
                        border-radius: 0;
                        background: $black;
                        margin-top: 0;
                        animation: auto-progress 7s linear;
                    }
                }
            }
        }

        &.slick-dots-white {
            li {

                &.slick-active button:before {
                    background: $white;
                }

                button {
                    &:before {
                        background: $white;
                    }
                }
            }
        }
    }
    /* TEMPORIZZATORE */
}

// HOMEPAGE
.hp-hero-banner-title-cta {
    height: calc(100vh - #{$padding-top-page-home-gender-d});

    @include breakpoint (small down) {
        height: calc(100vh - #{$padding-top-page-home-gender-m});
    }

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;

            .slick-slide {
                height: 100%;
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                    z-index: 1;
                }

                .slider-center-content {
                    position: absolute;
                    bottom: rem-calc(60);
                    text-align: center;
                    left: 20%;
                    right: 20%;
                    z-index: 2;

                    @include breakpoint (small down) {
                        left: 5%;
                        right: 5%;
                    }

                    p {
                        font-size: rem-calc(35);
                        font-weight: 500;
                        line-height: rem-calc(40);
                        letter-spacing: 0.5;
                        margin: 0 auto;
                        text-align: center;
                        padding-bottom: rem-calc(15);

                        &.white {
                            color: $white;
                            font-weight: 600;
                        }

                        @include breakpoint (small down) {
                            font-size: rem-calc(24);
                            color: $white;
                            line-height: rem-calc(26);
                        }
                    }

                    .button {
                        width: rem-calc(186);
                        font-size: rem-calc(14);
                        text-transform: uppercase;
                        padding: rem-calc(15);
                        line-height: rem-calc(20);
                        text-transform: uppercase;


                        @include breakpoint (small down) {
                            width: rem-calc(131);
                            height: rem-calc(40);
                            padding: rem-calc(10);
                        }

                    }
                }
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: rem-calc(30) !important;

        li {
            button:before {
                background: $white !important;
                opacity: 1 !important;

            }

            &.slick-active button:before {
                background: $white !important;
                opacity: 1 !important;

            }
        }
    }
}

// HOMEPAGE 2
.hp-image-category-title-no-full-height {
    height: calc(90vh - #{$padding-top-page-home-gender-d});
    position: relative;

    @include breakpoint (small down) {
        height: calc(90vh - #{$padding-top-page-home-gender-m});

    }

    .content-wrapper {
        height: 100%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
            object-position: top;
        }

        .wrapper-center-content {
            position: absolute;
            top: 45%;
            text-align: center;
            left: 20%;
            right: 20%;
            z-index: 2;

            @include breakpoint (small down) {
                left: 5%;
                right: 5%;
            }

            p {
                font-size: rem-calc(35);
                font-weight: 500;
                line-height: rem-calc(40);
                letter-spacing: 0.5;
                margin: 0 auto;
                text-align: center;
                padding: 0;

                &.white {
                    color: $white;
                    font-weight: 600;
                }

                @include breakpoint (small down) {
                    font-size: rem-calc(25);
                    color: $white;
                    line-height: rem-calc(28);
                }
            }

        }
    }
}

.hp-three-image-title {
    height: 100%;

    .content-wrapper {
        height: 100%;
        position: relative;
        padding-left: rem-calc(18);
        padding-right: rem-calc(18);

        @include breakpoint (small down) {
            height: calc(50vh - #{$padding-top-page-home-gender-m});
            padding-left: 0;
            padding-right: 0;
            margin-bottom: rem-calc(1);
        }

        img {
            width: 100%;
            height: 100%;
            z-index: 1;

            @include breakpoint (small down) {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 100% 0;
                z-index: 1;
            }
        }

        .wrapper-center-content {
            position: absolute;
            top: 45%;
            text-align: center;
            left: 20%;
            right: 20%;
            z-index: 2;

            @include breakpoint (small down) {
                left: 5%;
                right: 5%;
            }

            p {
                font-size: rem-calc(25);
                font-weight: 500;
                line-height: rem-calc(28);
                letter-spacing: 0.5;
                margin: 0 auto;
                text-align: center;
                padding: 0;

                @include breakpoint (small down) {
                    font-size: rem-calc(25);
                    color: $white;
                    line-height: rem-calc(28);
                }

                &.white {
                    color: $white;
                    font-weight: 600;
                }
            }
        }
    }
}

.hp-two-block-img-title-cta {
    @include breakpoint (small down) {
        padding: 0;
    }

    .content-wrapper {
        &.add-margin-left {
            margin-right: rem-calc(18);

            @include breakpoint (small down) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &.add-margin-right {
            margin-left: rem-calc(18);

            @include breakpoint (small down) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .img-wrapper {
            height: auto;
            margin: 0 auto;
            text-align: center;

            @include breakpoint (small down) {
                min-height: rem-calc(195);
                height: auto;
                position: relative;
            }

            img {
                width: 100%;
                height: 100%;
                z-index: 1;

                @include breakpoint (small down) {
                    // position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 100% 0;
                    z-index: 1;
                }
            }
        }

        .wrapper-center-content {
            text-align: center;
            position: relative;
            padding-top: rem-calc(15);
            margin: 0 auto;
            margin-bottom: rem-calc(30);
            max-width: rem-calc(402);
            width: auto;

            h1 {
                padding-bottom: rem-calc(15);
                margin-bottom: 0;

                @include breakpoint (small down) {
                    line-height: rem-calc(26);
                }
            }

            p {
                font-size: rem-calc(20);
                line-height: rem-calc(23);
                padding-bottom: rem-calc(23);
                margin-bottom: 0;
                font-weight: 500;

                @include breakpoint (small down) {
                    font-size: rem-calc(18);
                }
            }
        }
    }
}

.hp-four-slider-tabs {
    .slick-slide {
        .wrapper-image {
            position: relative;
            margin: rem-calc(0 18);

            @include breakpoint (small down) {
                margin: rem-calc(0 4);
            }

            p {
                position: absolute;
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                color: $white;
                bottom: rem-calc(18);
                left: rem-calc(20);
                right: rem-calc(16);
                font-weight: 500;

                @include breakpoint (small down) {
                    font-size: rem-calc(15);
                    left: rem-calc(10);
                    right: rem-calc(10);
                    line-height: rem-calc(20);
                    margin-bottom: rem-calc(10);
                    bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}

.hp-tabs {
    @include breakpoint (small down) {
        padding: 0;
    }

    .container-tabs ul.tabs {
        margin-bottom: rem-calc(5);
        background-image: none;
        border-bottom: rem-calc(1) solid $border-table-gray;

        @include breakpoint (small down) {
            overflow-x: auto;
            white-space: nowrap;
        }

        li.current {
            border-bottom: rem-calc(2) solid $black;
        }
    }
}

.background-component-hp-four-slider {
    padding: rem-calc(30 0);
    display: flex;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
    background: #F9F9F9;

    .title-home-component {
        font-size: rem-calc(30);
        padding-bottom: rem-calc(30);
        text-align: center;
    }
}

.hp-four-slider {
    .slick-list {
        @include breakpoint (large up) {
            margin: rem-calc(0 50);
        }
    }

    .slick-prev {
        left: rem-calc(25);
    }

    .slick-next {
        right: rem-calc(25);
    }

    .slick-slide {
        .wrapper-image {
            margin: rem-calc(0 18);

            @include breakpoint (small down) {
                margin: rem-calc(0 4);
            }

            .pdp-link,
            .price-container {
                font-weight: 400;
            }

            .pdp-link {
                margin-top: rem-calc(5);
                font-weight: 400;
                height: rem-calc(40);
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.componentHpFourProductTitle {
    @include breakpoint (small down) {
        padding: 0;
    }

    h2 {
        text-align: center;
        padding: rem-calc(10) auto;
        font-weight: 500;
    }
}

a {
    &.product-tile {
        outline: 0;
    }
}