.cc-tabs {
    margin: 0;
    @include flex(row, center, center);

    &-container {
        width: 100%;
    }

    &-link {
        list-style: none;
        color: $black;
        border-bottom: rem-calc(1) solid $border-grey;
        cursor: pointer;
        flex: 1;
        text-align: center;
        padding: rem-calc(12 0);

        &.cc-current {
            color: $text-black;
            border-bottom: rem-calc(1) solid $border-black;
        }

        &_light {
            border-color: $border-medium-grey;
        }
    }

    &-content {
        display: none;
        background: none;

        &.cc-current {
            display: initial;
        }
    }
}

