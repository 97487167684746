.sub-title-header, .cc-sub-header {
    width: 100%;
    padding-bottom: rem-calc(60);
    margin-bottom: rem-calc(20);
    min-height: rem-calc(50);
    line-height: rem-calc(50);
    margin-top: rem-calc(20);

    .cc-sub-header{
        margin-top: 0;
    }

    &.hide-hairline {
        border-bottom: none;
    }

    @include breakpoint (small down) {
        padding-left: rem-calc($xs);
        padding-right: rem-calc($xs);
        padding-top: rem-calc(15);
        margin-top: 0;
        margin-bottom: 0;
    }

    h1, h3, a {
        display: block;
        margin: 0;
    }

    .title-container {
        display: flex;
        align-items: center;
        @include breakpoint (medium only){
            flex-direction: column;
        }

        .left, .right {
            width: 50%;
            float: left;
        }

        .right {
            text-align: right;
        }
        h3{
            font-weight: 300;
        }
    }

    .caret-left {
        float: left;
        display: block;
        svg {
            top: 0;
            margin: 0;
            max-height: rem-calc(20);
            width: rem-calc(10);
        }
    }

    span.title {
        text-decoration: none;

        .image-container {
            position: relative;
            width: rem-calc(30);
            height: rem-calc(30);

            margin-right: rem-calc(20);
            margin-left: rem-calc(20);

            .image {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .image {
            vertical-align: middle;
            max-width: rem-calc(20);
            max-height: rem-calc(20);
            width: auto;
            height: auto;
            margin-top: rem-calc(2);
        }
    }
}

.cc-sub-header {

    &.detail {
        margin-top: rem-calc(-2);
        padding-bottom: rem-calc(2);
    }

    @include breakpoint (small down) {
        padding-top: rem-calc($m);
        padding-bottom: rem-calc($m);
        margin-bottom: 0;

        &.detail {
            display: flex;
            align-items: center;
            gap: rem-calc($xxs);
            background: $white;
            position: fixed;
            top: rem-calc(57);
            right: 0;
            width: 100%;
            max-width: 100%;
            min-height: rem-calc(50);
            margin: 0;
            padding: 0;
            border-bottom: 1px solid $border-gray;
            z-index: 3;
            padding-left: rem-calc($xs);
            padding-right: rem-calc($xs);
        }
    }
}