$light-swatch-colors: (
    'beige': #f5f5dc,
    'white': white
);

$other-swatch-colors: (
    'black': black,
    'blue': #0400FF,
    'brown': #D2AE9A,
    'green': #0F8E04,
    'grey': #828282,
    'navy': #000080,
    'orange': #ffa500,
    'pink': $primary,
    'purple': #5A0275,
    'red': #f00,
    'yellow': #F2FF00
);

$swatch-colors: map-merge($light-swatch-colors, $other-swatch-colors);

// Colored checkbox inputs
@each $color in map-keys($swatch-colors) {
    $colorValue: #{map-get($swatch-colors, $color)};

    .boolean-field {
        .checkbox-input {
            &-#{$color} {
                &:before {
                    background-color: $colorValue;
                }
            }
        }
    }
}

// Miscellaneous
.boolean-field {
    .checkbox-input {
        &-miscellaneous {
            &:before {
                background: linear-gradient(45deg, #FF95B8, #4BB6D2);
            }
        }
    }
}

// Borders for light-colored checkbox inputs
@each $lightColor in map-keys($light-swatch-colors) {
    .boolean-field {
        .checkbox-input {
            &-#{$lightColor} {
                &:before {
                    border-color: $medium-light-gray;
                }
            }
        }
    }
}
