@charset "UTF-8";

$include-fonts: false;
@import "./setup/fonts";
@import "./setup/settings";
@import "./setup/colorclasses";
@import "foundation";

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-forms;
@include foundation-button;
@include foundation-typography;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-sticky;
@include foundation-flex-classes;
@include foundation-reveal;

@import "mixins/helpers";

@include extend-palette($brand-palette);
@include helpers();
// @include full-container();

@import "layout/layout";
@import "utilities/spacing";

@include brand-text($brand-palette);
@include brand-links($brand-palette);

@import "components/components";
@import "components/prehome";

@import "plugins/foundation-datepicker";
@import "pages/content";
@import "plugins/slick";
@import "plugins/woosmap";

@import "pages/plp/categoryTopBanner";

@import 'components/widgets.scss';

.tingle-modal.tingle-modal--visible.multilanguage-popup {
    z-index: 99999999;
}

* {
    @media screen and (max-width: 1024px) {
        -webkit-tap-highlight-color: transparent;
    }
}


html[lang="el-GR"], html[lang="ru-RU"] {
    body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .hero, .hero-2, p, .p, label, input, a, span {
        font-family: $body-font-family-noto !important;
    }
}