$brand-palette: (
    sales: #E6453C,
    outlet:#000000,
    promo: #FF6666,
    newin: #D72359,
    black: #000000,
    dark-gray: #333333,
    medium-gray: #E8E8E8,
    gray-balance: #ebece6,
    gray-yellow: #F8F7F2,
    medium-more-gray: #C8C8C8,
    darkish-gray: #6d6d6d,
    //darkish-gray: #828282,
    medium-light-gray: #999999,
    more-light-gray: #F7F7F7,
    light-gray: #F2F0EB,
    bg-bra-gray: #f7f6f2,
    bg-lightgray: #FAFAFA,
    ivory: #f6f6f5,
    border-table-gray: #E0E0E0,
    component-grey: #F7F7F7,
    over-gray:#F9F9F9,
    light-over-gray:#F1F1F1,
    white: #FFFFFF,
    white-rgba: rgba(255, 255, 255, 0.7),
    alert: #FF8066,
    alert-light: #FDF4F3,
    error: #DD2B2E,
    christmas: #ca1617,
    success: #DCEDDE,
    info: #537af8,
    brand-green: #8AC492,
    free-shipping: #6295F7,
    brand-blue: #2C73D2,
    orange: #FD891D,
    orange-new: #F88B00,
    gold: #BD9E32,
    note-blue: #D2DEF5,
    desert-storm: #F0E6DC,
    free-shipping-message: #C65019,
    gray-separator: #DDDDDD,
    accordion-separator: #F6F6F5,
    light-green: #50A12A
);

$foundation-palette: (
    primary: #000000,
    secondary: #85715D,
    success: #8AB349,
    warning: #FFA12E,
    alert: #DD2B2E,
);

$buttons-palette-black-text: (
    primary: map-get($brand-palette, white),
    christmas: map-get($brand-palette, christmas),
    secondary: map-get($brand-palette, light-gray)
);

$buttons-palette-white-text: (
    black: map-get($brand-palette, black)
);

$primary: map-get($brand-palette, sales);
$sales: $primary;
$outlet: map-get($brand-palette, outlet);
$secondary: map-get($brand-palette, promo);
$promo: $secondary;
$tertiary: map-get($brand-palette, newin);
$newin: $tertiary;
$shadow-text: map-get($brand-palette, shadow-text);
$black: map-get($brand-palette, black);
$dark-gray: map-get($brand-palette, dark-gray);
$medium-dark-gray: map-get($brand-palette, darkish-gray);
$medium-gray: map-get($brand-palette, medium-gray);
$gray-balance: map-get($brand-palette, gray-balance);
$bg-bra-gray: map-get($brand-palette, bg-bra-gray);
$gray-yellow: map-get($brand-palette, gray-yellow);
$medium-more-gray: map-get($brand-palette, medium-more-gray);
$medium-light-gray: map-get($brand-palette, medium-light-gray);
$more-light-gray: map-get($brand-palette, more-light-gray);
$light-gray: map-get($brand-palette, light-gray);
$border-table-gray: map-get($brand-palette, border-table-gray);
$border-gray: $medium-gray;
$accordion-separator: map-get($brand-palette, accordion-separator);
$border-light-gray: lighten($more-light-gray, 10%);
$white: map-get($brand-palette, white);
$white-rgba: map-get($brand-palette, white-rgba);
$note-blue: map-get($brand-palette, note-blue);
$desert-storm: map-get($brand-palette, desert-storm);
$free-shipping-message: map-get($brand-palette, free-shipping-message);
$gray-separator: map-get($brand-palette, gray-separator);
$light-green: map-get($brand-palette, light-green);

$success: map-get($brand-palette, success);
$gold: map-get($brand-palette, gold);
$orange-new: map-get($brand-palette, orange-new);
$loyalty: map-get($brand-palette, gold);
$info: map-get($brand-palette, info);
$warning: map-get($brand-palette, alert);
$alert: map-get($brand-palette, error);
$alert-light: map-get($brand-palette, alert-light);
$christmas: map-get($brand-palette, christmas);
$brand-green: map-get($brand-palette, brand-green);
$brand-blue: map-get($brand-palette, brand-blue);
$over-gray: map-get($brand-palette, over-gray);
$light-over-gray: map-get($brand-palette, light-over-gray);

$component-grey: map-get($brand-palette, component-grey);
$pink-banner: map-get($brand-palette, pink-banner);
