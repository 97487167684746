hr {
  width: 100%;
  height: 1px;
  margin: rem-calc(0);
  border-bottom: rem-calc(1) solid;
  &.cc-divider{
    &_black{
      border-color: $border-black;
    }
    &_grey{
      border-color: $border-medium-grey;
    }
  }
}