$spinner-default-size: 50px;
$firstSpinnerColor: #f0e6dc;
$secondSpinnerColor: #d3cbc9;
$thirdSpinnerColor: #9f847e;

.veil {
  position: absolute;
  z-index: 9999;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $surface-dark-40;
  }

  .spinner {
    content: url('../../images/loader.svg');
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem-calc(144);
  }
}

.thank-you-image-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: rem-calc(50);

  .landing_page_loader_img {
    border: rem-calc(8) solid #dddddd;
    border-radius: 50%;
    border-top: rem-calc(8) solid #575757;
    width: rem-calc(60);
    height: rem-calc(60);
    -webkit-animation: landing_page_loader_spin 2s linear infinite;
    animation: landing_page_loader_spin 2s linear infinite;
  }

  @-webkit-keyframes landing_page_loader_spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes landing_page_loader_spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@keyframes spinner-anim {
  0% {
    transform: scale(0);
  }
  34% {
    background-color: $secondSpinnerColor;
  }
  67% {
    background-color: $thirdSpinnerColor;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
