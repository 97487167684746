.fnPersModal-options-accordion-body {
    display: none;
    padding-top: rem-calc(15);
    &.open {
        display: block;
    }
    .labelForRadio {
        display: inline-flex;
        position: relative;
    }
    input[type="radio"] {
        height: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 0;
        &:checked+.customButtonInput {
            color: #fff;
            background-color: #000;
        }
        &:checked+.customColorButton .customColorButton-border {
            background-color: #000;
        }
    }
    .customButtonInput {
        background: #fff;
        border-radius: rem-calc(20);
        color: #000;
        cursor: pointer;
        font-size: rem-calc(15);
        font-weight: 400;
        line-height: rem-calc(15);
        margin-bottom: rem-calc(14);
        margin-right: rem-calc(14);
        padding: rem-calc(13 30 12);
        &.fontButton {
            font-weight: 600;
            font-size: rem-calc(21);
            line-height: rem-calc(21);
            border-radius: rem-calc(23);
        }
    }
    .customColorButton {
        width: rem-calc(43);
        height: rem-calc(43);
        @include flexGenerator(column, center, center);
        margin-bottom: rem-calc(5);
        &-core {
            height: rem-calc(20);
            width: rem-calc(20);
            border-radius: 50%;
            display: inline-block;
            content: '';
            cursor: pointer;
        }
        &-border {
            margin-top: rem-calc(3);
            height: 1px;
            background-color: transparent;
            width: rem-calc(20);
        }
    }
    &-inputContainerText {
        position: relative;
        display: none;
        &.active {
            display: block;
        }
        .limitNumberCounter {
            position: absolute;
            right: 0;
            top: rem-calc(30);
            color: #777777;
            font-size: rem-calc(16);
            font-weight: 300;
            line-height: rem-calc(16);
            @include flexGenerator(row);
        }
        .badWords {
            font-size: rem-calc(16);
            line-height: rem-calc(16);
            color: #CC3447;
            display: none;
        }
        &.badInput {
            input {
                background-color: #fff;
                border-bottom-color: #CC3447;
            }
            .input-label, .limitNumberCounter, input {
                color: #CC3447 !important;
            }
        }
        .input-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: calc(100% - 35px);
            width: calc(100% - 35px);
            max-width: calc(100% - 35px);
        }
    }
    .fontFamilySection {
        padding-top: rem-calc(15);
        @include flexGenerator(column);
    }
    .subLabel {
        font-size: rem-calc(14);
        line-height: rem-calc(22);
        padding-bottom: rem-calc(15);
        span {
            font-weight: 300;
            font-size: rem-calc(14);
            line-height: rem-calc(22);
        }
    }
    .buttonRow {
        @include flexGenerator(row);
        flex-wrap: wrap;
    }
}