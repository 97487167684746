// MEDIA AVATAR images and text
.media {
    display: flex;
    align-items: flex-start;
    background: $light-gray;
    padding: rem-calc(17);
    margin-bottom: rem-calc(10);

    @include breakpoint(small down) {
        margin-left: rem-calc(10);
        margin-right: rem-calc(10);
    }

    a {
        font-weight: 500;
        display: inline;
    }

    img {
        margin: rem-calc(7 7 0 0);
    }

    svg {
        width: rem-calc(21);
        height: rem-calc(21);
        margin: rem-calc(7 7 0 0);
    }

    .align-self-center {
        align-self: center !important;
    }

    .mr-7 {
        margin-right: rem-calc(7);
    }

    .media-body {
        flex: 1;
        margin-left: rem-calc(10);
    }

    .media-title {
        text-align: left;
        margin: 0;
    }

    span {
        margin-bottom: 0;
    }

}