/* 
.content-asset class dynamically generated by salesforce for content assets 
*/
.content-asset {

    h1.title {
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 600;
        margin: 0;
        text-align: left !important;
        width: 100%;
        height: rem-calc(56);
        border-bottom: rem-calc(1) solid $medium-gray;
        background-size: rem-calc(20);
        background-position: rem-calc(0 8);
        background-repeat: no-repeat;
        margin-bottom: 0;

        @include breakpoint (small down) {
            height: rem-calc(56);

        }
    }

    .wrapper-gray-fullwidth {
        width: 100%;
        background-color: $more-light-gray;
        padding: rem-calc(40);

        @include breakpoint (small down) {
            padding: rem-calc(20);
        }
    }

    .careOfTheLeaders,
    .boxGrayMoreInformation {
        background-color: $more-light-gray;
        padding: rem-calc(30);
        text-align: center;
        margin-bottom: rem-calc(70);

        p {
            max-width: rem-calc(400);
            margin: 0 auto;
            padding-bottom: rem-calc(20);
        }
    }

    .boxGrayMoreInformationPdp {
        background-color: $more-light-gray;
        padding: rem-calc(7 37);
        text-align: center;
        margin-bottom: rem-calc(20);

        p {
            margin: 0 auto;
            padding-bottom: 0;
        }

        a {
            font-weight: 400;
        }
    }


    .component-find-your-size {
        text-align: center;

        .wrapper-box {
            max-width: rem-calc(294);
            margin: 0 auto;

            h1 {
                text-align: center
            }

            p {
                font-size: rem-calc(14);
                padding-bottom: rem-calc(30);
                margin: 0;
                text-align: center;
            }
        }

        .subtitle {
            padding-top: rem-calc(40);

            @include breakpoint (small down) {
                padding-top: 0©;
            }
        }

        .p26 {
            padding: rem-calc(23 26 0 26);

            @include breakpoint (small down) {
                padding: rem-calc(30 18 42 18);
            }
        }
    }
}

.table-scroll {
    overflow-x: scroll;

    &::-webkit-scrollbar {
        height: rem-calc(5);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $medium-gray;
        border-radius: rem-calc(5/2);
    }

    .table-find-your-size,
    .table-find-your-size-table {
        border-spacing: 0;

        // max-width: rem-calc(860); 
        min-width: rem-calc(380);
        width: 100%;
        // white-space: nowrap;
        text-align: center;

        tr {

            th,
            td {
                width: calc(100% / 7);
                text-align: center;
                border-bottom: rem-calc(1) solid $border-table-gray;

                &.size-legend {
                    background-color: $light-over-gray;
                }

                &.size-active {
                    background-color: $black;
                    color: $white;
                }

                &.size-same {
                    background-color: $medium-gray;
                }
            }

            th {
                line-height: rem-calc(22);
            }

            td {
                line-height: rem-calc(38);
            }
        }
    }

    .table-find-your-size-table {
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(30);
        text-align: center;

        tr {

            th,
            td {
                width: auto;





            }

            th {
                font-size: rem-calc(11);

                @include breakpoint (small down) {
                    font-size: rem-calc(10);
                }
            }
        }


    }

}

.marT41 {
    margin-top: rem-calc(41);

}


.todays-promo-right-slider-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    
    .todays-promo-left-title {
        position: absolute;
        top: 30px;
        margin-left: 20%;
        
    }
    .todays-promo-bottom-title {
        position: absolute;
        top: 100px;
        margin-left: 40%;
        
        
    }
    .todays-promo-left-subtitle {
        position: absolute;
        top: 40px;
        margin-left: 4%;
    }
}


.todays-promo-left-slider-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    
    .todays-promo-left-title {
        position: absolute;
        top: 30px;
        margin-left: 20%;
        
    }
    .todays-promo-bottom-title {
        position: absolute;
        top: 100px;
        margin-left: 40%;
        
        
    }
    .todays-promo-left-subtitle {
        position: absolute;
        top: 40px;
        margin-left: 4%;
    }
}

.page {
    &[data-querystring="cgid=INT_Woman_Todays-promo"] {
        @include breakpoint(large up) {
            padding-top: rem-calc(120);
        }
    }
}

.page {
    &[data-querystring="cgid=INT_Man_Today's-promo"] {
        @include breakpoint(large up) {
            padding-top: rem-calc(120);
        }
    }
}



.slider-todays-promo {
    h4 {
        margin-top: rem-calc(50);
        font-weight: $global-weight-dark;
        line-height: rem-calc(21);

        @include breakpoint (small down) {
            font-size: rem-calc(14);
        }

        &.title-slide-grey {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            color: $medium-light-gray;

            @include breakpoint (small down) {
                margin-top: rem-calc(29);
            }
        }

    }



    .wrapper-slider-image-title {
        position: relative;

        .wrapper-title-box-shadow {
            position: absolute;
            top: 0;
            left: 0;
            text-align: left;
            right: 0;
            height: rem-calc(113);
            background: -moz-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* ff3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(81%, rgba(2, 2, 3, 0)));
            /* safari4+,chrome */
            background: -webkit-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* safari5.1+,chrome10+ */
            background: -o-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* opera 11.10+ */
            background: -ms-linear-gradient(91deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);
            /* ie10+ */
            background: linear-gradient(359deg, rgba(2, 2, 3, 0) 19%, rgba(0, 0, 0, 0.5) 100%);

            /* w3c */
            &.no-box-shadow {
                background: none;
            }

            h1 {
                top: rem-calc(23);
                left: rem-calc(29);
                text-align: left;
                position: absolute;
            }
        }

        .wrapper-title-bottom {
            position: absolute;
            bottom: rem-calc(0);
            left: 0;
            right: 0;
            

            h4 {
                text-align: center;
                margin: 0;
                padding-bottom: rem-calc(70);
                font-size: rem-calc(20);

                @include breakpoint (small down) {
                    font-size: rem-calc(18);
                    line-height: rem-calc(20);
                    padding-bottom: rem-calc(20);
                }
            }

            h2 {
                padding-bottom: rem-calc(20);
                text-align: center;
                margin: 0;
                font-size: rem-calc(30);

                @include breakpoint (small down) {
                    font-size: rem-calc(22);
                    line-height: rem-calc(25);
                    padding-bottom: rem-calc(10);
                }
            }

            .read-more {
                @include breakpoint (small down) {
                    text-align: center;
                    margin: rem-calc(20 0 30 0);
                }

                .button-link-anchor {
                    img {
                        margin: 0 auto;
                    }
                }
            }

        }

        img {
            &.position-img-promo {
                @include breakpoint (small down) {
                    height: 95vh;
                    object-fit: cover;
                }
            }
        }
    }

    .wrapper-slider-text-right {
        margin-right: 11%;

        @include breakpoint (small down) {
            margin-right: rem-calc(18);
            margin-left: rem-calc(18);
        }

        p {
            font-size: rem-calc(14);
            line-height: rem-calc(19);

            &.special-size {
                font-size: rem-calc(16);
                line-height: rem-calc(21);
                font-weight: $global-weight-dark;
            }

            &.final-claim {
                font-size: rem-calc(14);
                line-height: rem-calc(24);
                font-weight: $global-weight-dark;
                margin-bottom: rem-calc(40);
            }

            &.no-margin {
                margin-bottom: 0;
            }

            &.center-m {
                @include breakpoint (small down) {
                    text-align: center;
                }
            }

        }

        a {
            font-size: rem-calc(12);
            line-height: rem-calc(17);
            font-weight: $global-weight-normal;

        }

        ul {
            list-style-position: inside;
            margin-left: 0;
        }



    }

    .slick-next,
    .slick-prev {
        top: 50vh;
        right: rem-calc(25);
        background-color: $light-gray;
        opacity: 0.7;
        height: 60px;
        width: 40px;

        &:before {
            color: $medium-dark-gray;
        }
    }

    .slick-prev {
        left: rem-calc(0);
        z-index: 999;
    }

    .slick-next {
        right: rem-calc(0);
        z-index: 999;
    }

    /* PRODUCT CATEGORY */
    .wrapper-slider-text-right-product {
        margin-right: 11%;
    }

    .product {
        width: 50%;
        float: left;
        margin-bottom: 9%;

        .image-container {
            width: 88%;
            margin: 0 auto;
            text-align: center;

            .js-quickview-link { 
                float: left;
            }
        }

        .tile-body {
            text-align: left;

            // .pdp-link {}
            // .price {}
            // .promo-message {}
        }
    }

    .titleTodayPromo {
        text-align: center;
        margin-bottom: rem-calc(38);
        margin-top: rem-calc(32);
    }
}

