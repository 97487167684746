@import "chosen-js/chosen";
@import "../layout/spacing";

.chosen-single {
    .chosen-container-single &,
    .chosen-container-active.chosen-with-drop & {
        background: unset;
        border-radius: 0;
        border: rem-calc(1) solid $border-medium-grey;
        box-shadow: none;
        height: rem-calc(56);
        padding: rem-calc(16);
        text-decoration: none;
        color: $text-black;
        font-size: rem-calc(getFontSize('textMedium'));
        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
        line-height: rem-calc(getLineHeight('textMedium'));
        &:not(.no-hover):hover {
            text-decoration: none;
        }
        span {
            margin-right: 0;
        }
    }

    .chosen-disabled & {
        cursor: $input-cursor-disabled;
    }
}

.cc-label-inside-active .cc-form-item-title{
    transition: opacity 300ms, display 300ms;
    opacity: 1;
}

.chosen-container {
    width: 100% !important; // fix chosen bug
    margin-bottom: rem-calc($xxs);
    &:focus-within{
        border: rem-calc(1) solid $border-black;
        outline: none;
    }
    .amount-selected-gift-card ~ & {
        margin-bottom: rem-calc(12);  // Specific margin for gift card page
    }

    &.chosen-disabled {
        .chosen-single span {
            opacity: 0;
        }
        b {
            opacity: .5;
        }
    }

    .is-invalid-input + .errors-list + & {
        .chosen-single {
            border-color: $border-error;
            color: $text-error;
        }
        .chosen-drop{
            border-color: $border-error;
        }
    }

    .chosen-drop {
        background: $surface-white;
        border: rem-calc(1) solid $border-medium-grey;
        border-top: 0;
        box-shadow: none;
        border-radius: 0;
        top: calc(100% - #{rem-calc(4)});
        z-index: 9;
    }

    .chosen-results {
        padding: rem-calc(8 16 0 16);
        margin: 0;
        max-height: rem-calc(200);
        .amount-selected-gift-card ~ & {
            max-height: unset;  // Gift cards have few options and we do not want to show a scrollbar.
        }

        &::-webkit-scrollbar {
            background-color: $medium-gray;
            width: rem-calc(4);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $black;
        }

        li {
            margin: rem-calc(0 0 8 0);
            font-size: rem-calc(getFontSize('textMedium'));
            line-height: rem-calc(18);
            color: $text-black;
            height: rem-calc(32);
            &.result-selected,
            &.highlighted {
                color: $text-black;
                display: flex;
                gap: rem-calc(8);
            }
            &.highlighted {
                background: $surface-light-grey;
            }
            &.result-selected{
                background: $transparent;
                &::after{
                    content: "";
                    background: url("../images/check-new.svg") no-repeat 0 0;
                    width: rem-calc(24);
                    height: rem-calc(24);
                    display: block;
                }
            }
        }
    }
}
.is-invalid-input + .errors-list + .chosen-container + {
    .cc-form-item-title{
        color: $text-error;
    }
}


// Chosen images override
.chosen-rtl .chosen-search input[type="text"],
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
    background-image: url("../../images/chevron-down.svg") !important;
    background-size: rem-calc(16 16)!important;
    background-repeat: no-repeat !important;
}

select {
    background-image: url("../../images/chevron-down.svg") !important;
    background-size: rem-calc(16 16) !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% + 20px) center !important;
    padding-right: rem-calc(36);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem-calc(getFontSize('textMedium'));
    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
    line-height: rem-calc(getLineHeight('textMedium'));
    font-weight: $global-weight-normal;
    color: $text-black;

    &.cc-prefix {
        background-position: 100% center !important;
    }
}

.cc-form-item select {
    padding: rem-calc(16 36 16 16);
    margin-bottom: 0;
    height: rem-calc(56);
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
    .chosen-rtl .chosen-search input[type="text"],
    .chosen-container-single .chosen-single abbr,
    .chosen-container-single .chosen-single div b,
    .chosen-container-single .chosen-search input[type="text"],
    .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
    .chosen-container .chosen-results-scroll-down span,
    .chosen-container .chosen-results-scroll-up span {
      background-image: url("../../images/chevron-down.svg") !important;
      background-size: rem-calc(16 16) !important;
      background-repeat: no-repeat !important;
    }
}

.chosen-container-single .chosen-single div{
    right: rem-calc(16);
    width: rem-calc(16);
    b {
        background-position: rem-calc(0 20);
    }

}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: rem-calc(0 19);
    transform: rotate(180deg);
}
