$flag-icon-css-path: '../images';
@import 'flag-icon.scss';

.hidden {
    display: none;
}

.geoblockContainerBlur {
    position: fixed;
    width: 100%;
    z-index: 9999999;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 20px;

    .cell {
        // Backdrop
        background-color: rgba(255, 255, 255, 1);
        z-index: inherit;

        /* If backdrop support: very transparent and blurred */
        @supports ((-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))) {
            background-color: rgba(255, 255, 255, .8);
            -webkit-backdrop-filter: blur(30px);
            /* for Safari */
            backdrop-filter: blur(30px);
            /* for Chrome + enabled the "Experimental Web Platform Features" flag */
        }
    }

    /* &::before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $offcanvas-inner-shadow-color;
        .hidden {
            display: none;
        }
        }*/

    .geoblockContainer {
        width: 100%;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        list-style: none;
        margin-left: auto;
        margin-right: auto;
        max-width: rem-calc(980);

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .geoblock-copy {
        padding-right: 2%;
        display: inline-block;
        word-break: initial;

        @include breakpoint(medium down) {
            flex: 1 0 80%;
            max-width: 80%;
            margin-bottom: rem-calc(12);
            text-align: center;
        }

        @include breakpoint(small down) {
            flex: 1 0 90%;
            max-width: 90%;
        }
    }

    .geoblock-actions {
        display: inline-flex;

        @include breakpoint(medium down) {
            margin: 0 rem-calc(-5);
            flex: 1 0 100%;
            justify-content: center;

            button {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .geoblock-actions-item {
        display: inline-block;
        margin-left: rem-calc(25);

        &:first-child {
            margin-left: 0;
        }
    }

    .geoblock-close {
        font-size: rem-calc(20);
        line-height: rem-calc(20);

        @include breakpoint(medium down) {
            position: absolute;
            top: rem-calc(17);
            right: rem-calc(17);
        }

        @include breakpoint(small down) {
            right: rem-calc(13);
        }

        &:focus {
            @include shadow(none);
            outline: none;
        }
    }

    .button-shop {
        margin-bottom: 0;
    }
}
