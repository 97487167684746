$include-fonts: false;
@import "../setup/settings";

.prehome-img{
    width:100%;
    object-fit: cover;
    height: 100%;
    object-position: top;
}
.grid-full-viewport{
    @include breakpoint (large up){
        height:100vh;
    }
    .content-asset{
        width:100%;
        height:100%;
        .max-height-prehome-grid{
            height:100%;
        }
    }
    .small-viewport-cell{
        height:100%;
        position: relative;
        @include breakpoint (medium down){
            height:47vh;
        }

        .category-name-prehome{
            text-align: center;
            line-height: rem-calc(22);
            font-size:rem-calc(35);
            text-transform: uppercase;
            color: $white;
            position: absolute;
            top:50%;
            left:50%;
            bottom: 50%;
            margin-left:rem-calc(-70);
            @include breakpoint (medium down){
                font-size:rem-calc(22);
                margin-left:rem-calc(-44);
            }
        }
    }
}