.cc-marketing-budge {
  font-size: rem-calc(getFontSize('textSmall'));
  letter-spacing: rem-calc(getLetterSpacing('textSmall'));
  line-height: rem-calc(getLineHeight('textSmall'));
  &-textMedium{
    font-size: rem-calc(getFontSize('textMedium'));
    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
    line-height: rem-calc(getLineHeight('textMedium'));
  }
  &-container {
    padding: rem-calc(4 8);
    display: inline-block;
  }
}