/*******************************/
/*VECCHIA PALETTE DA ELIMINARE*/
/*****************************/
// Basic colors
$dullRed: #A83151;
$red1: #CD3347;
$orange1: #EA8F18;
$tuscany: #BC542E;
$brown1: #9F847E;
$brown2: #1f1d1c;
$black: #000000;
$white: #FFFFFF;
$grey1: #D3CBC9;
$grey2: #F0E6DC;
$grey3: #A2A2A2;
$grey4: #DDDDDD;
$grey5: #E7E7E7;
$grey6: #EDECEA;
$grey7: #F6F6F5;
$green1: #50A12A;
$fuxia1: #CC3447;
$desertStorm: #EFE6dc;

//Other colors
$grey8: #777777;
$grey9: #BFAFAB;
$grey10: #999999;
$orange2: #C75015;
$orange3: #D5A18F;
$yellow1: #F7D2A3;
$pink1: #DCADB9;
/*******************************/
/*****************************/

$brand-palette: (

    /* Restyle 2024 */
    black: #000000,
    grey: #757575,
    medium-grey: #C3C3C3,
    light-grey: #F6F6F6,
    white: #FFFFFF,
    felce: #696E64,
    organic: #E6DDD3,
    jeans: #C3CCD5,
    sale-rosa: #E2B6A1,
    terracotta: #B65041,
    blu-baltico: #3B5374,
    validation: #117E4F,
    error: #C82525,
    focused: #719AC8,

    /*******************************/
    /*VECCHIA PALETTE DA ELIMINARE*/
    /*****************************/
    divider: #DDDDDD,
    transparent: transparent,
    tuscany: #BC542E,
    desert-storm: #EFE6dc,
    ivory: #F6F6F5,
    romance: #EDECEA,
    green: #50A12A,
    alto: #D3CBC9,
    hemp: #9F847E,
    mule-fawn: #1f1d1c,
    sale: #A83151,
    red-alert: #CD3347,
    grey-dark: #A2A2A2,
    primary: #000000,
    secondary: #A83151,
    success: #50A12A,
    warning: #EA8F18,
    alert: #CD3347,
    male-blue: #16325C,
    female-pink: #E3CFC3,
);

$foundation-palette: (
    primary: #000000,
    secondary: #A83151,
    success: #50A12A,
    warning: #EA8F18,
    alert: #CD3347
);

$buttons-palette-black-text: (
    primary: map-get($brand-palette, white)
);

$buttons-palette-white-text: (
    black: map-get($brand-palette, black)
);

/* Restyle 2024 */
/* Primitive token */
$black: map-get($brand-palette, black);
$grey: map-get($brand-palette,grey);
$medium-grey: map-get($brand-palette, medium-grey);
$light-grey: map-get($brand-palette, light-grey);
$white: map-get($brand-palette, white);
$felce: map-get($brand-palette, felce);
$organic: map-get($brand-palette, organic);
$jeans: map-get($brand-palette, jeans);
$sale-rosa: map-get($brand-palette, sale-rosa);
$terracotta: map-get($brand-palette, terracotta);
$blu-baltico: map-get($brand-palette, blu-baltico);
$validation: map-get($brand-palette, validation);
$error: map-get($brand-palette, error);
$focused: map-get($brand-palette, focused);

/* text token */
$text-black: $black;
$text-grey: $grey;
$text-medium-grey: $medium-grey;
$text-light-grey: $light-grey;
$text-white: $white;
$text-felce: $felce;
$text-organic: $organic;
$text-jeans: $jeans;
$text-sale-rosa: $sale-rosa;
$text-terracotta: $terracotta;
$text-blu-baltico: $blu-baltico;
$text-validation: $validation;
$text-error: $error;
$text-focused: $focused;

/* surface token */
$surface-white: $white;
$surface-dark: $black;
$surface-medium-grey: $medium-grey;
$surface-grey: $grey;
$surface-light-grey: $light-grey;
$surface-organic: $organic;
$surface-felce: $felce;
$surface-jeans: $jeans;
$surface-sale-rosa: $sale-rosa;
$surface-terracotta: $terracotta;
$surface-blu-baltico: $blu-baltico;
$surface-error: $error;
$surface-validation: $validation;
$surface-focused: $focused;
$surface-dark-40: rgba($black, .4);
$surface-dark-20: rgba($black, .2);

/* border token */
$border-black: $black;
$border-white: $white;
$border-light-grey: $light-grey;
$border-medium-grey: $medium-grey;
$border-grey: $grey;
$border-organic: $organic;
$border-felce: $felce;
$border-jeans: $jeans;
$border-sale-rosa: $sale-rosa;
$border-terracotta: $terracotta;
$border-blu-baltico: $blu-baltico;
$border-error: $error;
$border-validation: $validation;
$border-focused: $focused;

/*******************************/
/*VECCHIA PALETTE DA ELIMINARE*/
/*****************************/
$divider: map-get($brand-palette, divider);
$transparent: map-get($brand-palette, transparent);
$tuscany: map-get($brand-palette, tuscany);
$male-blue: map-get($brand-palette, male-blue);
$female-pink: map-get($brand-palette, female-pink);
$desert-storm: map-get($brand-palette, desert-storm);
$ivory: map-get($brand-palette, ivory);
$romance: map-get($brand-palette, romance);
$green: map-get($brand-palette, green);
$alto: map-get($brand-palette, alto);
$hemp: map-get($brand-palette, hemp);
$mule-fawn: map-get($brand-palette, mule-fawn);
$sale: map-get($brand-palette, sale);
$red-alert: map-get($brand-palette, red-alert);
$grey-dark: map-get($brand-palette, grey-dark);
$primary: map-get($brand-palette, primary);
$secondary: map-get($brand-palette, secondary);