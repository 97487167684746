.cc-info {
    display: inline-block;
    position: relative;
    line-height: rem-calc(17);

    &-icon {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        cursor: pointer;

        &:hover + .tooltip {
            opacity: 1;
            z-index: 1;
            display: block;
        }
    }
    .tooltip {
        font-weight: $global-weight-normal;
        font-size: rem-calc(12);
        line-height: rem-calc(20);
        position: absolute;
        top: 0;
        left: rem-calc(120);
        width: rem-calc(300);
        transform: translate(-95%, -110%);
        &.info-rememberme {
            transform: translate(-35%, -110%);
        }
        background: $white;
        color: $black;
        border: 1px solid $medium-grey;
        padding: rem-calc(8);
        opacity: 0;
        display: none;
        transition: opacity .25s ease;
        pointer-events: none;
        .page[data-action="Checkout-Begin"] &#tooltip-giftcard {
            left: rem-calc(-45);
            transform: translate(10%, -110%);
            &:after {
                content: '';
                position: absolute;
                left: 12%;
                bottom: rem-calc(-7);
                width: rem-calc(12);
                height: rem-calc(12);
                background:inherit;
                transform: translateX(-50%) rotate(45deg);
                border-right: solid rem-calc(1) $medium-grey;
                border-bottom: solid rem-calc(1) $medium-grey;
                @include breakpoint (medium down) {
                    left: 9%;
                }
            }
            @include breakpoint (medium down) {
                left: rem-calc(-35);
            }
        }
        
        @include breakpoint(medium down) {
            &#tooltip-giftcard {
                transform: translate(-48%, -110%);
            }
        }

        &.cc-info-tooltip-shipping{
            top: rem-calc(-13);
            left: rem-calc(-250);
            transform: translate(30%, -100%);
            z-index: 1000;
            width: rem-calc(231);

            @include breakpoint(large down) {
                left: rem-calc(-255);
            }
            &:after {
                content: '';
                position: absolute;
                left: 82%;
                bottom: rem-calc(-7);
                width: rem-calc(12);
                height: rem-calc(12);
                background:inherit;
                transform: translateX(-50%) rotate(45deg);
                border-right: solid rem-calc(1) $border-medium-grey;
                border-bottom: solid rem-calc(1) $border-medium-grey;
                @include breakpoint(large down) {
                    left: 86%;
                }
            }
        }
    }
}
