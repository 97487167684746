@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/NotoSerif/NotoSerif-Regular.woff2') format('woff2'),
         url('../fonts/NotoSerif/NotoSerif-Regular.woff') format('woff'),
         url('../fonts/NotoSerif/NotoSerif-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/NotoSerif/NotoSerif-Italic.woff2') format('woff2'),
         url('../fonts/NotoSerif/NotoSerif-Italic.woff') format('woff'),
         url('../fonts/NotoSerif/NotoSerif-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/NotoSerif/NotoSerif-Bold.woff2') format('woff2'),
         url('../fonts/NotoSerif/NotoSerif-Bold.woff') format('woff'),
         url('../fonts/NotoSerif/NotoSerif-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
        
@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/NotoSerif/NotoSerif-BoldItalic.woff2') format('woff2'),
         url('../fonts/NotoSerif/NotoSerif-BoldItalic.woff') format('woff'),
         url('../fonts/NotoSerif/NotoSerif-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
}
