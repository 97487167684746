.cc-sizeModal,
.cc-notifyModal {
    &-wrapper {
        @include flex(column, initial, initial);
        height: 100%;
    }

    &-container {
        .tingle-modal-box {
            bottom: 0;
            min-height: unset;
            @include breakpoint(large up) {
                min-height: 100%;
            }

            &__content {
                padding: 0;
                background: $surface-white;
            }
        }
    }

    &-header {
        @include flex(column, center, center);
        padding: rem-calc($xs);
        flex: 0 0 auto;
        position: relative;

        @include breakpoint(large up) {
            border-bottom: rem-calc(1) solid $border-medium-grey;
        }

        &-label {
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textLarge'));
            letter-spacing: rem-calc(getLetterSpacing('textLarge'));
            line-height: rem-calc(getLineHeight('textLarge'));

            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('textMedium'));
                letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                line-height: rem-calc(getLineHeight('textMedium'));
            }
        }
    }

    &-closeButton {
        @include flex(row, initial, initial);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: rem-calc(44);
        height: rem-calc(44);
        justify-content: center;
        align-items: center;
        right: 0;
    }

    &-body {
        @include flex(column, initial, initial);
        gap: rem-calc($m);
        flex: 1;
        padding: rem-calc($xs);
        overflow-y: auto;

        @include breakpoint(large up) {
            gap: 0;
            padding: rem-calc($xs $m);
        }

        &-sizeWrapper,
        &-colorWrapper {
            @include flex(column, initial, initial);
            gap: rem-calc($xs);
        }
    }

    &-colorContainer {
        @include flex(row, initial, initial);
        gap: rem-calc($xxxs);
        flex-wrap: wrap;
    }

    &-colorIcon {
        img {
            width: rem-calc(49);
            height: rem-calc(58);
        }
    }

    &-changeColor {
        display: inline-flex;

        &_selected {
            border-bottom: rem-calc(1) solid $black;
        }
    }

    &-sizeContainer {
        @include flex(column, initial, initial);

        @include breakpoint(large up) {
            gap: rem-calc($xs);
        }
    }

    &-changeSize {
        @include flex(column, center, center);
        cursor: pointer;
        color: $text-black;
        padding: rem-calc($xs);
        border-bottom: rem-calc(1) solid $border-medium-grey;
        transition: border-color 350ms ease;
        min-height: rem-calc(74);
        width: calc(100% + #{rem-calc($m)});
        margin: 0 rem-calc(-$xs);
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textLarge'));
        letter-spacing: rem-calc(getLetterSpacing('textLarge'));
        line-height: rem-calc(getLineHeight('textLarge'));

        &:first-child {
            border-top: rem-calc(1) solid $border-medium-grey;
        }

        @include breakpoint(large up) {
            font-size: rem-calc(getFontSize('textMedium'));
            letter-spacing: rem-calc(getLetterSpacing('textMedium'));
            line-height: rem-calc(getLineHeight('textMedium'));
            border: rem-calc(1) solid $border-medium-grey;
            width: 100%;
            margin: 0;
            &:hover {
                border-color: $border-black;
            }

            &:focus-visible {
                outline: none;
                border: rem-calc(1) solid $border-focused;
            }
        }

        &_selected {
                border-top: rem-calc(1) solid $border-black;
                border-color: $border-black;
            &:first-child {
                border-color: $border-black;
            }
        }

        &-value {
            @include flex(row, initial, initial);
            position: relative;
        }

        &_selected {
            .cc-sizeModal-changeSize-value::after {
                content: url("../../images/check-new.svg");
                position: absolute;
                width: rem-calc(16);
                height: rem-calc(18);
                right: rem-calc(-$xxs);
                top: 50%;
                transform: translate(100%, -50%);
            }
        }

        &_notifyMe {
            cursor: default;
            color: $text-grey;

            .cc-sizeModal-notifyMeLabel {
                display: block;
            }
        }

        &_disabled {
            cursor: not-allowed;
        }
    }

    &-notifyMeLabel {
        display: none;

        &, &-link {
            height: unset;
            color: $text-black;
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textMedium'));
            letter-spacing: rem-calc(getLetterSpacing('textMedium'));
            line-height: rem-calc(getLineHeight('textMedium'));

            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('textSmall'));
                letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                line-height: rem-calc(getLineHeight('textSmall'));
            }
        }

        &-link {
            margin-left: rem-calc($xxs);
        }
    }

    &-checkIcon {
        display: none;
    }

    &-footerArea {
        @include flex(column, center, center);
        gap: rem-calc($xxs);
        background-color: $surface-white;
        border-top: rem-calc(1) solid $border-medium-grey;
        padding: rem-calc($xs);
        @include breakpoint(large up) {
            padding: rem-calc($xs $m);
        }
    }

    &-confirmButton {
        width: 100%;

        &_selectLabel {
            display: none;
        }

        &_addToCartLabel {
            display: block;
        }

        &_notSelected {
            .cc-sizeModal-confirmButton_selectLabel {
                display: block;
            }

            .cc-sizeModal-confirmButton_addToCartLabel {
                display: none;
            }
        }
    }

    &-backButton {
        width: 100%;
    }
}

.cc-notifyModal {
    &-body {
        @include breakpoint(large down) {
            padding-bottom: 0;
        }

        &-image {
            width: rem-calc(200);
            display: none;
            @include breakpoint(large up) {
                display: block;
            }
        }
    }

    &-privacy {
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textSmall'));
        letter-spacing: rem-calc(getLetterSpacing('textSmall'));
        line-height: rem-calc(getLineHeight('textSmall'));

        @include breakpoint(large up) {
            font-size: rem-calc(getFontSize('textMedium'));
            letter-spacing: rem-calc(getLetterSpacing('textMedium'));
            line-height: rem-calc(getLineHeight('textMedium'));
        }
    }

    &-description {
        @include flex(column, initial, initial);
        gap: rem-calc($xxxs);

        @include breakpoint(large up) {
            gap: rem-calc($xxs);
        }

        &Title {
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textLarge'));
            letter-spacing: rem-calc(getLetterSpacing('textLarge'));
            line-height: rem-calc(getLineHeight('textLarge'));

            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('title'));
                letter-spacing: rem-calc(getLetterSpacing('title'));
                line-height: rem-calc(getLineHeight('title'));
            }
        }

        &Text {
            color: $text-grey;

            @include breakpoint(large up) {
                color: $text-black;
            }
        }
    }

    &-footerArea {
        padding: rem-calc($xs);
        border-top: 0;
        @include breakpoint(large up) {
            border-top: rem-calc(1) solid $border-medium-grey;
            padding: rem-calc($m);
        }
    }
}