@import "../pages/personalization/fonts";
@import "../pages/personalization/changeOption";

.fnPersonalizationPageContainer {
    * {
        letter-spacing: 0.2px;
    }
    height: 100%;
    position: fixed;
    top: 0;
    right:0;
    width: 0;
    z-index: 2011;
    @include flexGenerator(row);
    @include fixHeight(100%);
    @include breakpoint (large up) {
        @include flexGenerator(row-reverse);
        background-color: rgba(0, 0, 0, 0.3);
    }
    .fnPersModal {
        background-color: #EDECEA;
        @include flexGenerator(column);
        @include fixHeight(100%);
        width: 100vw;
        @include breakpoint (large up) {
            max-width: 33.9vw;
        }
        @media screen and (min-width: 2000px) {
            max-width: rem-calc(678);
        }
        overflow-y: auto;
        &-header {
            height: rem-calc(60);
            max-height: rem-calc(60);
            background-color: #FFFFFF;
            position: relative;
            @include flexGenerator(row, center, center);
            @include breakpoint (medium up) {
                background-color: #EDECEA;
                @include flexGenerator(row, space-between, center);
                padding: rem-calc(40);
                padding-bottom: 0;
            }
            &-title {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
                @include breakpoint (medium up) {
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                }
            }
            &-close {
                svg.close {
                    height: rem-calc(20);
                    width: rem-calc(20);
                    cursor: pointer;
                }
                @include breakpoint (small only) {
                    position: absolute;
                    right: rem-calc(20);
                }
            }
        }
        &-body {
            padding: rem-calc(30 20);
            @include breakpoint (medium up) {
                padding: rem-calc(30 40 40);
            }
        }
        &-footer {
            padding-top: rem-calc(6);
            font-size: rem-calc(9);
            line-height: rem-calc(17);
            text-transform: uppercase;
            a {
                text-decoration: underline;
            }
        }
        &-typology {
            @include flex(row);
            margin-bottom: rem-calc(30);
            @include breakpoint (medium up) {
                margin-bottom: rem-calc(40);
            }
            &-tab {
                color: #bfafab;
                font-size: rem-calc(14);
                line-height: rem-calc(14);
                width: 50%;
                height: rem-calc(26);
                @include breakpoint (medium up) {
                    height: rem-calc(23);
                }
                border-bottom: 1px solid #dddddd;
                text-transform: uppercase;
                cursor: pointer;
                &.active {
                    border-bottom: 2px solid #9f847e;
                    color: #9f847e;
                }
            }
        }
        &-image {
            background-color: #FFFFFF;
            width: 100%;
            position: relative;
            img {
                width: 100%;
            }
        }
        &-options {
            padding-top: rem-calc(15);
            &-accordion {
                padding: rem-calc(15 0);
                &-header {
                    @include flexGenerator(row, space-between, center);
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    cursor: pointer;
                    svg.close {
                        display: inline-block;
                    }
                    svg.open {
                        display: none;
                    }
                    &.open {
                        svg.close {
                            display: none;
                        }
                        svg.open {
                            display: inline-block;
                        }
                        .selectHeaderOption {
                            display: none;
                        }
                    }
                    .selectHeaderOption {
                        font-size: rem-calc(16);
                        font-weight: 300;
                        line-height: rem-calc(24);
                    }
                }
            }
        }
        &-confirmButton {
            margin-top: rem-calc(15);
        }
    }
}

.canvasSection {
    width: 100%;
}

.selectSizeForCustomize {
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
    height: 100%;
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 2011;
    .size-mobile-modal {
        display: block;
        .sizeAttribute {
            border-bottom: 1px solid transparent;
            padding: 10px;
            display: block;
            margin-bottom: 5px;
            text-align: center;

            &.unselectable {
                color: $medium-light-gray;
            }
            &.coming-soon {
                color: $medium-light-gray;
            }

            &.selectable {
                svg {
                    &.alertNotify{
                        display: none;
                    }
                }
            }

            &.notifyMe {
                svg {
                    &.alertNotify{
                        display: block;
                        margin-top: -3px;
                        width: rem-calc(20);
                        height: rem-calc(20);
                    }
                }
            }
        }
    }
}

.fnPersButtonContainer {
    &.create {
        margin: rem-calc(15 20 16);
        @include breakpoint (medium up) {
            margin: rem-calc(40 40 16);
        }
        @include breakpoint (large up) {
            margin: rem-calc(2 8 20);
            width: 100%;
        }
        .fnPersButt {
            width: 100%;
            background-color: #D3CBC9;
            color: #000000;
            font-size: rem-calc(14);
            letter-spacing: 0.2px;
            line-height: rem-calc(14);
            margin-bottom: 0;
            border: unset;
        }
    }
}

.select-custom-PDP {
    .fnPersButtonContainer {
        &.create {
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: rem-calc(20);
            background: $white;

            .fnPersButt {
                width: 100%;
                background: $black;
                color: $white;
                font-size: rem-calc(14);
                letter-spacing: 0.2px;
                line-height: rem-calc(14);
                margin-bottom: 0;
                border: unset;

                &.disabled {
                    background: $grey3;
                    border-color: $grey3;
                    color: $white;
                }
            }
        }
    }
}

.fnCartOptions {
    &-personalization {
        padding-top: rem-calc(20);
        p {
            line-height: rem-calc(14);
            margin-bottom: rem-calc(10);
        }
        .fnPersButt {
            padding-top: rem-calc(10);
            padding-bottom: rem-calc(3);
            border-bottom: 1px solid #000000;
            color: #000000;
            font-size: rem-calc(14);
            letter-spacing: 0.2px;
            line-height: rem-calc(14);
            .quickcart & {
                margin-bottom: rem-calc(14);
            }
        }
        &-color {
            height: rem-calc(12);
            width: rem-calc(12);
            border-radius: 50%;
            display: inline-block;
            content: '';
            vertical-align: bottom;
            margin: 1px;

            &.personalization-color-border {
                border: rem-calc(1) solid $black;
            }
        }
    }
}
