$add-to-cart-message-height: rem-calc(100px);
$add-to-cart-message-margin: rem-calc(20px);
$add-to-cart-child-message-margin: rem_calc(10px);
$add-to-cart-max-messages-shown: 6;

.cc-quickCart{
    width: rem-calc(491);
    max-width: rem-calc(491);
    max-height: 100dvh;
    height: 100dvh;
    min-height: 100dvh;
    display: block;
    position: absolute;
    top: rem-calc(55);
    background: $surface-white;
    @include breakpoint (large up) {
        top: rem-calc(0);
    }
    &-bodyScroll{
        overflow: hidden;
        height: 100%;
    }
    &-blur{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: $surface-dark-40;
        top: 0;
        left: 0;
        z-index: -1;
        overflow-y: hidden;
    }
    &-title{
        margin: 0;
        padding: rem-calc(13 $xs 13 $s);
        border-bottom: rem-calc(1) solid $border-medium-grey;
        text-align: center;
    }
    &-close {
        font-size: unset;
        position: absolute;
        right: rem-calc(1);
        top: rem-calc($xxxs);
        width: rem-calc(44);
        height: rem-calc(44);
    }
    &-buttons {
        position: fixed;
        padding: rem-calc($xs);
        background: $surface-white;
        width: -webkit-fill-available;
        border-top: rem-calc(1) solid $border-medium-grey;
        @include breakpoint (large up) {
            padding: rem-calc($xs $m);
        }
        & .cc-button-primary_black, & .cc-button-primary_white {
            width: 100%;
        }
        &_text{
            @include flex(row, space-between, unset);
            margin-bottom: rem-calc($xxs);
        }
    }
    &-box{
        height: calc(100dvh - #{rem-calc(158)});
        overflow-y: auto;
        overflow-x: hidden;
        padding: rem-calc($s $xs 20 $xs);
        @include breakpoint (large up) {
            padding: rem-calc($m);
            height: calc(100vh - #{rem-calc(161)});
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &-cardBox{
        padding-bottom: rem-calc($xs);
        &-container{
            @include flex(row, space-between, start);
            width: 100%;
            text-align: left;
            &-image {
                max-width: rem-calc(83);
                margin-right: rem-calc($s);
            }
        }
        &-textBox{
            @include flex(row, space-between, center);
            flex: auto;
            width: 100%;
            text-align: left;
            &-text{
                min-height: rem-calc(97);
            }
        }
        &-price{
            margin-top: rem-calc(-6);
            &-totalWrapper{
                @include flex(row-reverse, unset, center);
                gap: rem-calc($xxs);
                &_disconut{
                    color: $text-grey;
                    font-size: rem-calc(getFontSize('textSmall'));
                    &_value{
                        font-size: rem-calc(getFontSize('textSmall'));
                        letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                        line-height: rem-calc(getLineHeight('textSmall'));
                    }
                }
            }
        }
        &-attribute{
            @include flex(row, unset, baseline);
            padding-top: rem-calc(2);
            gap: rem-calc($xs);
        }
        &-remove{
            width: rem-calc(44);
            height: rem-calc(44);
            margin-right: rem-calc(-14);
        }
    }
}


.wishlist-popover {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    min-width: rem-calc(33.44rem);
    max-width: rem-calc(33.44rem);
    min-height: rem-calc(6.25rem);
    display: none;

    &::before {
        left: auto;
        right: rem-calc(15px);
    }

    &::after {
        left: auto;
        right: rem-calc(16px);
    }

    &.show {
        display: block;

        @include breakpoint(small down) {
            display: none;
        }
    }

    & .product-info {
        padding-bottom: rem-calc(7);
        border-bottom: rem-calc(1) solid $medium-light-gray;
    }
}
.shifted-text-top {
    position: relative;
    top: rem-calc(-5px);
}
.promo {
    text-align: left;
    font-size: rem-calc(14);
}
.titleMiniCart {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(6) !important;
}
.minicart {
    .cc-price-info{
        &-additionalReduction{
            text-decoration: line-through;
        }
        &-pdp-container{
            &-omnibus{
                margin-top: rem-calc(-5);
                margin-bottom: rem-calc(5);
                @include breakpoint (large up){
                    margin-top: rem-calc(-7);
                }
                .price-info,
                .cc-price-info-pdp-container-omnibusOld-percentageFromRegularPrice{
                    margin-left: 0;
                }
            }
            &-omnibusOld{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: rem-calc(5);
                padding-top: rem-calc(2);
                &-title,
                &-regularPrice,
                &-percentageFromRegularPrice{
                    color: $text-grey;
                    font-size: rem-calc(getFontSize('textSmall'));
                    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                    line-height: rem-calc(getLineHeight('textSmall'));
                }
                &-percentageFromRegularPrice{
                    &.cc-omniColorSales{
                        color: $text-black;
                    }
                    &.cc-omniColorExtraordinary{
                        color: $text-black;
                    }
                    &.cc-omniColorLoyalty{
                        color: $text-black;
                    }
                }
            }
        }
    }
    .cc-price-info-pdp-container-omnibusOld-regularPrice{
        margin-top: 0;
    }

    .popover {
        .card {
            background: transparent;
            border: 0;
            margin: 0;
            .card-body {
                .strike-through {
                    display: inline;
                }
            }
        }
        .cc-grid-buttons-express.js-paypal-container {
            display: none;
        }
        .minicart-checkout-button {
            @include breakpoint (large up) {
                width: 100%;
            }
        }
        .shopping-continue {
            margin-top: rem-calc(10) !important;
        }
        // If a promo exists, card has no padding/margin
        & .cart.promoapplicable .card,
        & .cart.promoapplied .card {
            padding-top: 0;
            &.no-margin-padding-promo {
                margin: 0;
                .card-body {
                    padding: 0;
                }
            }
        }
        .estimated-total {
            margin-top: 0;
        }
        .bottom-box-fixed {
            position: absolute;
            padding: rem-calc(30);
            border: 1px solid #F6F6F5;
        }
        .rect-added-to-cart {
            position: absolute;
            height: rem-calc(55);
            left: 0;
            right: 0;
            top: 0;
            padding: rem-calc(20);
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 100;
            .close-popover-icon {
                cursor: pointer;
            }
        }
    }

    .popover-error-bottom {
        @include breakpoint(small down) {
            position: fixed !important;
            left: 0 !important;
            top: rem-calc(55) !important;
        }
        .rect-added-to-cart {
            position: absolute;
            height: rem-calc(55);
            left: 0;
            right: 0;
            top: 0;
            padding: rem-calc(20);
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 100;
            .close-popover-icon {
                cursor: pointer;
            }
        }
        .container-error-cart {
            background-color: #F6F6F5;
            @include breakpoint(medium up) {
                &:after, &:before {
                    bottom: 100%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    right: rem-calc(75);
                    border-color: transparent;
                    border-bottom-color: #BC542E;
                    border-width: rem-calc(10);
                    margin-left: rem-calc(-10);
                }

                &:before {
                    right: rem-calc(75);
                    border-color: transparent;
                    border-bottom-color: #BC542E;
                    border-width: rem-calc(11);
                    margin-left: rem-calc(-11);
                }

            }
            @include breakpoint(medium only) {
                &:after {
                    right: rem-calc(35);
                }

                &:before {
                    right: rem-calc(35);
                }
            }
        }
    }
    .line-item {
        &-price-info {
            .strike-through {
                display: inline-block;
            }
            .value {
                font-weight: 300;
            }

            .strike-through~.sales {
                color: $tuscany;
                margin-left: rem-calc(8);
                &.loyalty, &.loyalty1, &.loyalty2, &.loyalty3, &.loyalty4 {
                    color: $gold;
                }
                &.outlet {
                    color: $outlet !important;
                }
            }
        }
    }

    .isClubProductContainer {
        .line-item-price-info {
            .strike-through~.sales {
                color: $yellow1;
                &.loyalty, &.loyalty1, &.loyalty2, &.loyalty3, &.loyalty4 {
                    color: $yellow1;
                }
            }
        }
    }

    position: relative;
    display: inline-block;

    @include breakpoint (small down) {
        &.open {
            position: fixed;
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3000;
            h6 {
                font-weight: $global-weight-dark;
            }
        }

    }

    h4 {
        font-size: 1rem;
    }


    .closeButton {
        position: absolute;
        top: rem-calc(8);
        right: rem-calc(15);
        font-size: rem-calc(25);
        font-weight: 500;
    }

    .cart {
        padding: rem-calc(80 30 50);
        background-color: #F6F6F5;

        height: 90vh;
        &.set-products {
            padding-bottom: rem-calc(180);
        }

        @include breakpoint ( small only) {
            height: inherit; // search for 'url bar' in this file
        }

        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        // box-shadow: $prototype-box-shadow;

        &:after, &:before {
            bottom: 100%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &.cart-woman {
            &:after, &:before {
                 border-bottom-color: $female-pink;
            }
        }

        &.cart-man {
            &:after, &:before {
                border-bottom-color: $male-blue;
            }
        }

        &:after, &:before {
            right: rem-calc(75);
            border-color: transparent;
            border-width: rem-calc(10);
            margin-left: rem-calc(-10);

            @include breakpoint (medium only) {
                right: rem-calc(10);
            }
        }


        .banner-add-bonus,
        .banner-no-add-bonus {
            height: rem-calc(60);
        }

        // Minicart complete your look two-column
        .grid-three-container-for-medium-up>.grid-item {
            width: 48%;
            @include breakpoint (small only) {
                max-width: unset !important; // There is a more general implementation of max-width, which affects plp
                                             // which I do not have the time to investigate, so I am doing this as a workaround.
            }
        }

        &.promoapplicable, &.promoapplied, &.approaching, &.set-products {
            &::before {
                border-bottom-color: #BC542E;
            }
            &::after {
                border-bottom-color: #BC542E;
            }

            .promo {
                margin-bottom: rem-calc(20);
            }
        }

        &.promoapplicable {
            .promo {
                color: #BC542E;
            }
        }

        &.promoapplied {
            .promo {
                color: #50A12A;
            }
        }

        &.approaching {
            .promo {
                color: black;
            }
        }
    }

    .wishlist-minicart {
        &:after {
            right: 96px;
        }

        &:before {
            right: 95px;
        }
    }

    .remove-btn {
        color: $medium-gray;
        float: right;
        background-color: $white;
        border: none;
        font-size: rem-calc(1.625em);
        margin-top: rem-calc(-0.313em);
        padding: 0;
    }

    .product-summary {
        max-height: rem-calc(21.875em);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .price {
        font-weight: 500;
        color: $black !important;

        .strike-through~.sales {
            color: $primary;
            margin-left: rem-calc(10);

        }
    }
    .text-attributes-product-mini {
        margin: 0;
        line-height: rem-calc(12);
        margin-bottom: rem-calc(10) !important;
        &.double-currency-clearfix{
            margin-bottom: rem-calc(5) !important;
        }
        span{
            text-transform: capitalize;
        }
    }
    .text-attributes-product-mini-double-currency{
        .regular{
            font-size: rem-calc(12);
            line-height: rem-calc(14);
            font-weight: 300;
        }
    }

    .card-body {
        // padding: 0;
        padding-top: rem-calc(9);
        padding-bottom: rem-calc(15);
        padding-right: 0;
        padding-left: 0;
    }

    .price, .quantity-label {
        font-size: rem-calc(0.813em);
        span {
            font-size: rem-calc(12);
        }
    }

    .quantity {
        width: 100%;
    }

    .popover,
    .popover-error-bottom {
        position: absolute;
        top: rem-calc(55);
        left: auto;
        right: rem-calc(-40);
        display: none;
        z-index: 100;
        // border: 1px solid $border-gray;
        @include breakpoint (medium up) {
            min-width: rem-calc(30.44rem);
            max-width: rem-calc(30.44rem);
            min-height: rem-calc(4.25rem);

            &:before {
                left: auto;
                border-left: rem-calc(8) solid transparent;
                border-right: rem-calc(8) solid transparent;
                border-bottom: rem-calc(10) solid $white;

                position: relative;
                right: rem-calc(10);
                bottom: rem-calc(10);
            }
        }

        @include breakpoint (small down) {
            &.show {
                position: fixed;
                display: block;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                min-height: rem-calc(20);
                height: auto;
                margin: 0;

                top: 0;
                left: 0;
                right: 0;

                z-index: 3000;
                box-shadow: $shadow-default;
            }
        }


        &.show {
            display: block;
            height: 90vh;

            @include breakpoint(medium only){
                height: 85vh;
                right: rem-calc(-10);
                top: rem-calc(48);

                &::before, &::after{
                    right: rem-calc(10);
                }
            }

            @include breakpoint(small only) {
                position: fixed; // In this way a height is adjusting to the viewport
                height: 100%;    // of the device when url bar is not consistent, and is working in all browsers.
                transition: all 1s ease;
            }
        }

        .card-body {
            padding-bottom: 0;
            .product-line-item {
                margin-bottom: 0;
                border: 0;

                &.giftcard-line-item{
                    .giftcard-name{
                        margin-bottom: rem-calc(12);
                        display: block;
                    }
                    .line-item-product-info-taxt{
                        font-size: rem-calc(12);
                        line-height: rem-calc(16);
                        margin: rem-calc(24 0 10);
                    }
                }
            }
        }

    }

    .left-margin {
        left: rem-calc(1150);
    }

    .minicart-footer {
        @include breakpoint (medium up) {
            border-top: 1px solid $border-gray;
        }
    }

    .estimated-total {
        margin-top: rem-calc(0.625em);
        padding: rem-calc(0 10);
    }

    .button-grid-minicart {
        padding: rem-calc(0 10);

    }

    .sub-total-label {
        font-size: rem-calc(1em);
        font-weight: 600;
    }


    .line-item-divider {
        margin: rem-calc(0.625em) rem-calc(-0.625em) rem-calc(0.625em) rem-calc(-1.325em);
    }

    .line-item-name {
        width: 90%;
    }

    .checkoutButtons {
        a, button {
            width: 100%;
            min-width: 100%;
            margin: 0;
        }
    }
    &.custom-right-fix {
        right: rem-calc(50);
        .popover {
            right: 0;
            .text-attributes-product-mini {
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                span.small {
                    font-size: rem-calc(14);

                    #item-with-img {
                        font-size: rem-calc(14);
                        img {
                            width: rem-calc(14) !important;
                            height: rem-calc(14) !important;
                            vertical-align: text-top;

                        }
                    }

                }
                #miniCartImage {
                    vertical-align: text-top;
                }
                .customize__icon {
                    img {
                        width: rem-calc(16) !important;
                        height: rem-calc(16) !important;
                        vertical-align: text-top;

                    }
                }
            }
        }

    }
    #miniCartCrossSellContainer {
        padding: rem-calc(30 0 200 0);
        display: flex;
        justify-content: space-between;
        border-top: rem-calc(1) solid $divider;
        .complete-look-title {
            margin-bottom: rem-calc(30);
        }
        .price-info-pdp-container:not(.tooltip) {
            display: flex;
        }
        .order-2.price-container{
            display: flex;
            flex-direction: row;
            gap: rem-calc(5);
            font-weight: $global-weight-light;
            @include breakpoint (large up){
                flex-direction: row-reverse;
                justify-content: flex-end;
            }
        }

        .cc-price-info-pdp-container-omnibusOld-percentageFromRegularPrice{
            @include breakpoint (large up){
                margin-left: rem-calc(5);
            }
        }

        .crosssell-view{
            &.grid-item{
                .product-tile{
                    .product-tile-with-omnibus{
                        .isClubProductContainer{
                            .omnibusSalesLoggedPresent{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.price-info-pdp-container {
    .omnibus {
        .minicart &,
        .quickcart & {
            color: $grey8;
            font-size: rem-calc(10);
            line-height: rem-calc(16);
        }
        .minicart & {
            display: flex;
            flex-wrap: wrap;
            gap: rem-calc(5);
            margin: rem-calc(-5 0 15);
            @include breakpoint(medium down) {
                margin-bottom: rem-calc(10);
            }
        }
        .quickcart & {
            margin: rem-calc(-12 0 10);
        }
    }
}

.quickcart .price-info-pdp-container.cc-partiallyHiddenCountry .omnibus{
    display: none;
}

.rightModalAttribute {
    position: absolute;
    right: 0;
    width: 100%;
    .tingle-modal-box__content {
        width: 100%;
    }
    &.quick-cart-modal-no-padding .tingle-modal-box__content {
        padding-right: 0;
        padding-left: 0;
    }
    &.cc-quickCart {
        &.top-strip-padding {
            top: rem-calc(125);
        }
        .bonus-product-remove-btn{
            position: unset;
        }
    }
}

.miniWishlistProductCard {
    @include flexGenerator(row);
    * {
        letter-spacing: 0.2px;
    }
    &-image {
        @include fixWidth(29.07vw);
    }
    &-detail {
        margin-left: rem-calc(10);
        &.omniOldPresent{
            .miniWishlistProductCard-price{
                margin-top: rem-calc(-5);
                .omnibusSalesLoggedPresent{
                    display: none;
                }
            }
            .price-label{
                display: none;
            }
            .inner-price-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: rem-calc(5);
                .value{
                    font-size: rem-calc(14);
                    line-height: rem-calc(14);
                    letter-spacing: rem-calc(0.2);
                    white-space: nowrap;
                }
            }
            .price-info-pdp-container .omnibus{
                margin: 0;
            }
        }
    }
    &-label {
        font-size: rem-calc(10);
        line-height: rem-calc(10);
        margin-bottom: rem-calc(5);

        .cc-hideTagOnWishlist{
            display: none;
        }
    }
    &-name {
        font-size: rem-calc(12);
        line-height: rem-calc(16);
        margin-bottom: rem-calc(10);
        word-wrap: break-word;
    }
    &-price div.price {
        >span {
            display: flex;
            flex-direction: column;
            span+span {
                margin-left: rem-calc(0);
            }
        }
        @include breakpoint (small only) {
            span {
                font-size: rem-calc(12);
                line-height: rem-calc(12);
            }
        }
        .double-price-parent-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: left;
            .sale-percentage{
                font-weight: 300;
            }
            .double-currency-sales, .double-currency-list{
                font-size: rem-calc(12);
                line-height: rem-calc(14);
                font-weight: 300;
                display: inline-block;
                @include breakpoint (medium down) {
                    font-size: rem-calc(11);
                    line-height: rem-calc(11);
                    font-weight: 300;
                }
            }
        }
        .editproduct-d-none-label,
        .list{
            font-size: rem-calc(12);
            color: $medium-light-gray;
        }
        &:has(.cc-differentTemplate-price) {
            >span {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
                font-size: rem-calc(14);
                line-height: rem-calc(14);
                letter-spacing: rem-calc(0.2);
 
                @include breakpoint(small only) {
                    margin-bottom: rem-calc(8);
                    font-size: rem-calc(12);
                    line-height: rem-calc(12);
                    align-items: flex-end;
                }

                .editproduct-d-none-label {
                    display: none;
                }
            }
            .sales.extraordinaryprice {
                margin-bottom: 0;
            }
            .extraordinary-price-pdp-container {
                border-bottom: 0;
                padding: 0;
            }
            .club-msg {
                font-size: rem-calc(14);
                line-height: rem-calc(14);
                letter-spacing: rem-calc(0.2);
                margin-left: rem-calc(5);
                padding: 0;
                @include breakpoint(medium up) {
                    margin-left: rem-calc(10);
                    .cc-discount-msg {
                        margin-left: rem-calc(5);
                    }
                }
            }
            .inner-price-wrapper {
                @include breakpoint(medium up) {
                    gap: rem-calc(10);
                }
            }
            .cc-discount-msg, .value {
                font-size: rem-calc(12);
                line-height: rem-calc(12);
                letter-spacing: rem-calc(0.2);
                @include breakpoint(medium up) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(14);
                }
            }
        }
        .cc-discount-msg.cc-isCountryWithOmnibus.extraordinaryprice {
            display: none;
        }
    }
    &-price{
        .omniOldPresent{
            display: none;
        }
        .cc-loyaltyWithOmnibus{
            display: flex;
            gap: rem-calc(5);
            @include breakpoint (large up){
                gap: 0;
                font-size: rem-calc(14);
                margin-top: rem-calc(-5);
            }
        }
        .cc-salesLoggedWithOmnibus{
            display: flex;
            flex-direction: row;
            font-size: rem-calc(14);
            line-height: rem-calc(14);
            letter-spacing: rem-calc(0.2);
            &-price{
                font-size: rem-calc(14);
                line-height: rem-calc(14);
                letter-spacing: rem-calc(0.2);
            }
        }
    }
    .omnibusOldPDPPresent{
        display: none;
    }

    &-size {
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(10);
    }
    &-color {
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        margin-bottom: rem-calc(10);
        text-transform: capitalize;
    }
    &-id {
        font-size: rem-calc(10);
        line-height: rem-calc(10);
    }
    &-remove {
        margin-top: rem-calc(17);
        color: $black;
    }
    &-gotoWishlist {
        width: 100vw;
        margin: rem-calc(0 -20);
        margin-top: auto;
        padding: rem-calc(20);
        padding-bottom: rem-calc(30);
        background-color: #ffffff;
        >a.button {
            margin: 0;
        }
    }
    @include breakpoint (medium up) {
        &-image {
            @include fixWidth(30.47vw);
        }
        &-detail {
            margin-left: rem-calc(20);
        }
        &-name {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            margin-bottom: rem-calc(10);
        }
        &-gotoWishlist {
            width: 100vw;
            margin: rem-calc(0 -40);
            margin-top: auto;
            padding: rem-calc(40);
        }
    }

    .cc-price-info{
        &-additionalReduction{
            text-decoration: line-through;
        }
        &-miniWishlist-container{
            &-omnibus{
                margin-top: 0;
                @include breakpoint (large up){
                    margin-top: rem-calc(5);
                }
                .omnibus{
                    display: flex;
                    flex-wrap: wrap;
                    gap: rem-calc(5);
                    @include breakpoint (small down){
                        margin-bottom: 0;
                    }
                }
            }
            &-omnibusOld{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: rem-calc(5);
                padding-top: rem-calc(2);
                &-title,
                &-regularPrice,
                &-percentageFromRegularPrice{
                    color: $text-grey;
                    font-size: rem-calc(getFontSize('textSmall'));
                    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                    line-height: rem-calc(getLineHeight('textSmall'));
                }
                &-percentageFromRegularPrice{
                    &.cc-omniColorSales{
                        color: $text-black;
                    }
                    &.cc-omniColorExtraordinary{
                        color: $text-black;
                    }
                    &.cc-omniColorLoyalty{
                        color: $text-black;
                    }
                }
            }
        }
    }

    @include breakpoint (large up) {
        &-image {
            @include fixWidth(44.12%);
        }
        &-detail {
            max-width: calc(55.88% - #{rem-calc(10)});
            width: calc(55.88% - #{rem-calc(10)});
            min-width: calc(55.88% - #{rem-calc(10)});
            margin-left: rem-calc(10);
            @include flexGenerator(column, flex-start, flex-start);
        }
        &-price {
            >.small-margin, p {
                margin: 0;
            }
            span.strike-through {
                margin-right: rem-calc(5);
            }
            span.js-discount-msg {
                margin-left: 0;
            }
        }

        &-remove {
            margin-top: auto;
        }
        &-gotoWishlist {
            width: 100%;
            margin: 0;
            position: fixed;
            bottom: 0;
            right: 0;
            padding: rem-calc(40);
        }

        .omniOldPresent{
            .miniWishlistProductCard-remove{
                margin-top: rem-calc(17)
            }
        }
    }
}

.miniWishlistSizeProductCard {
    @include flexGenerator(row);
    @include breakpoint (large up){
        padding: rem-calc(0 40);
    }
    * {
        letter-spacing: 0.2px;
    }
    &-productLineItem{
        border-bottom: none;
        margin-bottom: rem-calc(40);
        @include breakpoint (small down){
            margin: rem-calc(20 20 0);
            border-bottom: rem-calc(1) solid $gray-separator;
            padding-bottom: rem-calc(30)
        }
    }
    &-image {
        @include fixWidth(29.07vw);
        height: rem-calc(128);
        @include breakpoint (large up){
            height: 26.3765555vh;
            margin-right: rem-calc(10);
        }
    }
    &-detail {
        margin-left: rem-calc(10);
    }
    &-label {
        font-size: rem-calc(10);
        line-height: rem-calc(10);
        margin-bottom: rem-calc(5);
    }
    &-name {
        font-size: rem-calc(12);
        line-height: rem-calc(16);
        margin-bottom: rem-calc(10);
        word-wrap: break-word;
    }
    &-price{
        margin-bottom: rem-calc(10);
        @include breakpoint (large up){
            margin-bottom: rem-calc(40);
        }
    }
    div.price {
        >span {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: rem-calc(5);
            span+span {
                margin-left: rem-calc(0);
            }
        }
        @include breakpoint (small only) {
            span {
                font-size: rem-calc(12);
                line-height: rem-calc(12);
            }
        }
        .double-price-parent-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: left;
            .sale-percentage{
                font-weight: 300;
            }
            .double-currency-sales, .double-currency-list{
                font-size: rem-calc(12);
                line-height: rem-calc(14);
                font-weight: 300;
                display: inline-block;
                @include breakpoint (medium down) {
                    font-size: rem-calc(11);
                    line-height: rem-calc(11);
                    font-weight: 300;
                }
            }
        }
        .sales.extraordinaryprice{
            color: #BC542E;
            font-size: rem-calc(12);
            font-weight: $global-weight-light;
            margin-bottom: rem-calc(5);
            @include breakpoint (medium down){
                margin-bottom: rem-calc(8);
            }
        }
        .editproduct-d-none-label,
        .list{
            font-size: rem-calc(12);
            color: $medium-light-gray;
        }
    }

    &-size {
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(10);
    }
    &-color {
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        margin-bottom: rem-calc(10);
        text-transform: capitalize;
    }
    &-id {
        font-size: rem-calc(10);
        line-height: rem-calc(10);
    }
    &-remove {
        margin-top: rem-calc(20);
    }
    &-gotoWishlist {
        width: 100vw;
        margin: rem-calc(0 -20);
        margin-top: auto;
        padding: rem-calc(20);
        padding-bottom: rem-calc(30);
        background-color: #ffffff;
        >a.button {
            margin: 0;
        }
    }
    @include breakpoint (medium up) {
        &-image {
            @include fixWidth(30.47vw);
        }
        &-detail {
            margin-left: rem-calc(20);
        }
        &-name {
            font-size: rem-calc(12);
            line-height: rem-calc(16);
            margin-bottom: rem-calc(10);
        }
        &-gotoWishlist {
            width: 100vw;
            margin: rem-calc(0 -40);
            margin-top: auto;
            padding: rem-calc(40);
        }
    }
    @include breakpoint (large up) {
        &-image {
            @include fixWidth(44.12%);
        }
        &-detail {
            max-width: calc(55.88% - 10px);
            width: calc(55.88% - 10px);
            min-width: calc(55.88% - 10px);
            margin-left: rem-calc(10);
            @include flexGenerator(column, flex-start, flex-start);
        }
        &-price {
            >.small-margin, p {
                margin: 0;
            }
            span.strike-through {
                margin-right: rem-calc(5);
            }
            span.js-discount-msg {
                margin-left: 0;
            }
        }

        &-remove {
            margin-top: auto;
        }
        &-gotoWishlist {
            width: 100%;
            margin: 0;
            position: fixed;
            bottom: 0;
            right: 0;
            padding: rem-calc(40);
        }
    }
}

.sales {
    &.outlet{
        color: $outlet !important;
    }
}

.text-attributes {
    line-height: rem-calc(12);
    margin-bottom: rem-calc(10);
}
.line-item-price-info {
    margin-bottom: rem-calc(10);
    .price-second-currency-wrapper{
        display: inline-block;
        margin-left: rem-calc(5);
        font-size: rem-calc(12);
        line-height: rem-calc(14);
        & .margin-clearfix{
            margin-left: 0;
        }
        .double-currency-list, .double-currency-sales{
            font-size: rem-calc(12);
            line-height: rem-calc(14);
            font-weight: 300;
            @include breakpoint (medium down) {
                font-size: rem-calc(11);
                line-height: rem-calc(11);
            }
        }
    }
}

.estimated-total {
    .total-price-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem-calc(20);
        .title-total-bottom {
            margin: 0;
        }
    }
    .iva-text {
        color: #999999;
        line-height: rem-calc(12);
        display: unset;
        bottom: 0;
        font-weight: 400;
    }
}
.no-border-card {
    border: 0;
    margin-top: rem-calc(20);
}
.cell .minicart-checkout-button {
    margin-bottom: rem-calc(10);
    @include breakpoint (large up) {
        width: 100%;
        min-width: 0;
        float: left;
        padding-left: 5px;
        padding-right: 5px;
    }
}
.cell .js-paypal-container {
    @include breakpoint (large up) {
        width: calc(50% - 5px);
        min-width: 0;
        float: right;
    }
}
.cart-label-extraordinaryprice,
.cart-label-employee, .cart-label-outlet {
    font-size: rem-calc(10);
    font-weight: 400;
    line-height: rem-calc(10);
}
.shopping-continue {
    margin-top: rem-calc(10);
    span {
        position: unset !important;
        font-size: rem-calc(14) !important;
        padding-bottom: rem-calc(2);
        border-bottom: 1px solid black;
        cursor: pointer;
    }
}
.card.minicart-card {
    @include breakpoint (small down) {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
.minicart-card {
    padding: rem-calc(0 0 30 0);
    margin: 0;
    background-color: #F6F6F5;
    .line-item-price-info {
        .strike-through {
            color: #A2A2A2;
            display: inline-block;
        }
    }
    @include breakpoint (medium down) {
        .product-line-item:first-child {
            margin-top: rem-calc(20);
        }
    }
    @include breakpoint (small down) {
        .product-title {
            font-size: rem-calc(12);
            line-height: rem-calc(16);
        }
        .line-item-price-info {
            font-size: rem-calc(12);
            .strike-through {
                color: #A2A2A2;
                display: inline-block;

                span {
                    font-size: rem-calc(12);
                    line-height: rem-calc(16);
                    white-space: nowrap;
                }
            }
        }
    }
    .product-line-item {
        border-bottom: 0;
        border-bottom: 1px solid $divider;
        padding-bottom: rem-calc(30);
        &--last {
            border-bottom: 0;
            padding-bottom: 0;
        }

        >.grid-x>.small-5 {
            width: 109px;
            @include breakpoint (large up) {
                width: 140px;
            }
        }
    }
    .line-item-price-info .strike-through~.sales {
        position: unset;
        margin-left: rem-calc(5);
        color: $sale;
        &.double-currency{
            margin-left: 0;
        }
        &.outlet{
            color: $outlet !important;
        }
        &.employee{
            color: $sale !important;
        }
    }
    .isClubProductContainer {
        .line-item-price-info {
            .strike-through~.sales {
                color: $sale;
                &.loyalty, &.loyalty1, &.loyalty2, &.loyalty3, &.loyalty4 {
                    color: $sale;
                }
            }
        }
    }

    .cart-line-item {
        padding-bottom: rem-calc(20);
        padding-top: rem-calc(30);
        border-top: 1px solid #DDDDDD;

        @include breakpoint(large up) {
            margin-right: rem-calc(60);
        }
        @include breakpoint(medium up) {
            padding-bottom: rem-calc(30);
        }

        .line-item-code-font {
            font-size: rem-calc(11);
        }
        .promotions-cell-margin {
            margin-top: rem-calc(-10);
            margin-bottom: rem-calc(20);
        }

        .quick-cart-view &.quickcart-bonus-item {
            border: 0;
            margin-right: 0;
            padding: 0;
            .fix-width-quickcart {
                width: 38%;
                @include breakpoint(medium only) {
                    width: 31.5%;
                }
            }
            .quickcart-remove-bonus-card-1 {
                @include breakpoint(large up) {
                    display: none;
                }
            }
            .quickcart-remove-bonus-card-2 {
                @include breakpoint(large up) {
                    width: 100%;
                    margin-top: rem-calc(20);
                    .line-item-header-bonus {
                        position: relative;
                    }
                }
                @include breakpoint(medium down) {
                    display: none;
                }
            }
            .line-item-product-info-taxt{
                font-size: rem-calc(12);
                line-height: rem-calc(16);
                margin: rem-calc(10 0 10);
                @include breakpoint(medium down) {
                    margin: rem-calc(0 0 10);
                }
            }
            .product-image {
                max-height: unset !important;
                width: 100%;
            }
            .bonus-product-card-description {
                .line-item-name p {
                    margin-bottom: 0;
                    @include breakpoint(small down) {
                        font-size: rem-calc(12);
                        line-height: rem-calc(12);
                    }
                }
                .line-item-attributes {
                    font-size: rem-calc(12);
                    line-height: rem-calc(12);
                    margin-bottom: rem-calc(10);
                }
                .line-item-pid p {
                    font-size: rem-calc(10);
                    line-height: rem-calc(10);
                }
                .line-item-price-info {
                    position: relative;
                    flex: 0 0 auto;
                    width: 100%;
                    text-align: left;
                    margin-bottom: rem-calc(20)!important;
                }
                .product-card-footer {
                    &-cart {
                        display: none;
                    }
                    &-quick {
                        display: block;
                    }
                }
            }
            .line-item-header-bonus {
                @include breakpoint(medium down) {
                    position: relative;
                }
            }
        }
    }
    .product-line-item {
        margin-left: rem-calc(40);
        margin-right: rem-calc(40);
        @include breakpoint(small down) {
            margin-left: rem-calc(20);
            margin-right: rem-calc(20);
        }
    }
    .bonus-product-card {
        padding: rem-calc(20 40);
        margin-bottom: rem-calc(30);
        @include breakpoint(small down) {
            padding: rem-calc(20);
            margin-bottom: 0;
        }
        .bonus-product-card-description {
            position: relative;
            padding-left: rem-calc(20);
        }
        p{
            text-align: left;
        }
    }
}
.shopping-rect {
    width: 100%;
    background-color: #9F847E;
    height: rem-calc(50);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem-calc(25);
}
.points-rect {
    width: 100%;
    background-color: #EA8F18;
    height: rem-calc(50);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align-last: center;
    margin-bottom: rem-calc(25);
    padding: rem-calc(10 40);
    font-size: rem-calc(11);
}
.approachingdiscount-rect {
    width: 100%;
    background-color: #BC542E;
    height: rem-calc(50);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align-last: center;
    margin-bottom: rem-calc(25);
    padding: rem-calc(10 40);
    font-size: rem-calc(11);
}
button {
    .remove-icon {
        svg {
            height: rem-calc(20);
            width: rem-calc(20);
            filter: brightness(0) saturate(100%);
        }
    }
    .delete-text {
        margin-left: rem-calc(5);
        color: black;
    }
}
.product-description-box {
    position: relative;
    .cart-label-extraordinaryprice{
        font-size: rem-calc(10);
        font-weight: 400;
        color: #BC542E;
        margin-bottom: rem-calc(5);
    }
    .cart-label-employee{
        font-size: rem-calc(10);
        font-weight: 400;
        color: #A83151;
        margin-bottom: rem-calc(5);
    }
    .cart-label-outlet {
        font-size: rem-calc(10);
        font-weight: 400;
        color: $outlet;
        margin-bottom: rem-calc(5);
    }
    .cc-product-code-id {
        font-size: rem-calc(10);
        font-weight: 400;
        margin-bottom: 0;
        line-height: rem-calc(12);
    }
}
.bottom-box-fixed {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.40);
    .price-box-padding {
        padding: rem-calc(30 40);
        @include breakpoint(small down) {
            padding: rem-calc(0 20 30 20);
        }
    }
}
.bottom-button-fixed {
    @include breakpoint(large up) {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        padding: rem-calc(30 40);
        background-color: $white;
    }

    @include breakpoint (medium down) {
        padding: 0;
    }
}
    .product-image {
        @include breakpoint(medium down) {
            width: 100%;
        }
    }

    .cc-price-info{
        &-additionalReduction{
            text-decoration: line-through;
        }
        &-pdp-container{
            &-omnibus{
                margin-top: rem-calc(10);
                .price-info{
                    margin-left: rem-calc(5);
                }
                .cc-price-info-pdp-container-omnibusOld-percentageFromRegularPrice{
                    margin-left: rem-calc(5);
                }
            }
            &-omnibusOld{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: rem-calc(5);
                padding-top: rem-calc(2);
                &-title,
                &-regularPrice,
                &-percentageFromRegularPrice{
                    color: $text-grey; 
                    font-size: rem-calc(getFontSize('textSmall'));
                    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                    line-height: rem-calc(getLineHeight('textSmall'));
                }
                &-percentageFromRegularPrice{
                    &.cc-omniColorSales{
                        color: $text-black; 
                    }
                    &.cc-omniColorExtraordinary{
                        color: $text-black;
                    }
                    &.cc-omniColorLoyalty{
                        color: $text-black;
                    }
                }
            }
        }
    }
.wishlist-minicart {
    height: calc(100% - 50px);
    >div.card {
        height: 100%;
        margin: 0;
    }
    &-body {
        height: 100%;
        margin-top: rem-calc(70);
        @include flexGenerator(column);
        @include breakpoint (large up) {
            margin-top: rem-calc(20);
            height: unset;
        }
    }
    .card {
        border: none !important;
        background-color: #F6F6F5;
        .title {
            font-size: rem-calc(20);
            background-color: #F6F6F5;
            @include breakpoint (medium down) {
                position: fixed;
                top: 0;
                left: 0;
                background: white;
                height: rem-calc(60);
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: rem-calc(1) solid #dddddd;
                &-label {
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    margin: rem-calc(0 10);
                }
            }
            svg {
                width: rem-calc(20);
                height: rem-calc(20);
            }
            .closeButton {
                float: right;
                @include breakpoint (medium down) {
                    position: fixed;
                    right: rem-calc(40);
                }
                @include breakpoint (small down) {
                    right: rem-calc(40);
                }
            }
        }
        .card-body {
            margin-top: rem-calc(70);
            padding: 0;
            @include flexGenerator(column);
            height: 100%;
            .product-line-item {
                border: none !important;
            }
            @include breakpoint (large up) {
                height: unset;
                margin-top: rem-calc(20);
            }
        }
    }
}

.cc-plpWishlist {
    .tingle-modal-box__content {
        padding: rem-calc(0);
    }
    .cc-sizeModal-body {
        gap: 0;
        @include breakpoint (large up) {
            padding: rem-calc($m);
        }
        .cc-wishlist-editSize_productDescription {
            color: $text-grey;
            padding-bottom: rem-calc($xxs);
            @include breakpoint (large up) {
                padding-bottom: rem-calc($xs);
            }
        }
    }
    &-content{
        margin: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: rem-calc($xxs);
        @include breakpoint (large up) {
            gap: rem-calc($xs);
        }
        .cc-sizeModal-changeSize {
            width: 100%;
            border: rem-calc(1) solid $border-medium-grey;
            margin: 0;
            padding: rem-calc($xxs $xs);
            @include breakpoint (large up) {
                padding: rem-calc($xs $s);
            }
            &_selected{
                border: rem-calc(1) solid $border-black;
            }
        }
    }
}

.select-wishlist-size {
    z-index: 3010 !important;
    .tingle-modal-box {
        margin-bottom: 0;
        width: 100%;
        border-radius: rem-calc(20 20 0 0);
    }
}

.minicart-card .marketing-budge {
    min-height: unset;
    margin-bottom: rem-calc(20);
    @include breakpoint(medium up) {
        margin-left: rem-calc(40);
        margin-right: rem-calc(40);
        margin-bottom: rem-calc(20);
    }
    @include breakpoint(small down) {
        margin-left: rem-calc(20);
        margin-right: rem-calc(20);
    }
    &_successBox {
        text-align: left;
        align-items: baseline;
        &_icon{
            padding-right: rem-calc(8);
        }
    }
    svg {
        margin-right: rem-calc(10);
    }
}

.header-modal {
    bottom: rem-calc(178);
    width: 50%;
    position: fixed;
    display: flex;
    &-hr {
        border: rem-calc(1) solid white;
        border-radius: rem-calc(7);
        height: rem-calc(2);
    }
}
.dw-apple-pay-button {
    width: 100%;
    height: 42px;
}
.pos-relative {
    position: relative;
}
.select-wishlist-size {
    .tingle-modal-box {
        @include breakpoint(medium down) {
            overflow-y: unset;
            max-width: 100% !important;
        }
    }
    .tingle-modal-box__content {
        border-radius: rem-calc(20 20 0 0);
        background-color: $white;
        padding: 0;

        &:before {
            @include breakpoint(medium down) {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -15px);
                width: rem-calc(200);
                height: rem-calc(5);
                background: #fff;
                border-radius: 2px;
            }
            @include breakpoint(small down) {
                width: rem-calc(100);
            }
        }

        h3 {
            border-radius: rem-calc(20 20 0 0);
            background-color: $ivory;
            padding: rem-calc(25 20);
            font-size: rem-calc(12);
            line-height: rem-calc(14);
        }

        ul {
            margin-top: 0;

            li {
                display: block;

                button {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    text-align: left;
                    margin: 0;
                    padding: rem-calc(30 25);
                    border-bottom: rem-calc(1) solid $grey6;
                    color: $black;

                    &.notifyMe {
                        color: $grey10;
                    }

                    .lowStockLabelPDP {
                        color: $orange1;
                    }
                }
            }
        }
    }
}

.product-tags-outlet {
    color:$outlet;
}
