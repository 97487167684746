@font-face {
    font-family: 'AltastGreeting';
    src: url('../fonts/AltastGreeting/AltastGreeting.ttf') format('truetype');
}
@font-face {
    font-family: 'Avante';
    src: url('../fonts/Avante/Avante.eot?') format('eot'),
        url('../fonts/Avante/Avante.woff') format('woff'),
        url('../fonts/Avante/Avante.ttf') format('truetype'),
        url('../fonts/Avante/Avante.svg#Avante') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GoudxLight';
    src: url('../fonts/GoudxLight/GoudxLight.ttf') format('truetype');
}

.goudyLightNew {
    font-family: 'GoudxLight';
}
.avante {
    font-family: 'Avante';
}
.altastGreeting {
    font-family: 'AltastGreeting';
}