/// Generate font-color & bg-color class helpers
///
/// @param {Map} $palette - colors map
@mixin extend-palette($palette) {
    @each $color, $hex in $palette {
        .cc-#{$color} {
            color: $hex !important;
        }
        .cc-bg-#{$color} {
            background-color: $hex !important;
        }
        .cc-border-#{$color} {
            border: 1px solid $hex;
        }
    }
}

/* text token */
.cc-text-black {
    color: $black;
}
.cc-text-white {
    color: $white;
}
.cc-text-grey {
    color: $grey;
}
.cc-text-medium-grey {
    color: $medium-grey;
}
.cc-text-light-grey {
    color: $light-grey;
}
.cc-text-felce {
    color: $felce;
}
.cc-text-organic {
    color: $organic;
}
.cc-text-jeans {
    color: $jeans;
}
.cc-text-sale-rosa {
    color: $sale-rosa;
}
.cc-text-terracotta {
    color: $terracotta;
}
.cc-text-blu-baltico {
    color: $blu-baltico;
}
.cc-text-validation {
    color: $validation;
}
.cc-text-error {
    color: $error;
}
.cc-text-focused {
    color: $focused;
}

/* surface token */
.cc-surface-white {
    background-color: $white;
}
.cc-surface-dark {
    background-color: $black;
}
.cc-surface-medium-grey {
    background-color: $medium-grey;
}
.cc-surface-grey {
    background-color: $grey;
}
.cc-surface-light-grey {
    background-color: $light-grey;
}
.cc-surface-organic {
    background-color: $organic;
}
.cc-surface-felce {
    background-color: $felce;
}
.cc-surface-jeans {
    background-color: $jeans;
}
.cc-surface-sale-rosa {
    background-color: $sale-rosa;
}
.cc-surface-terracotta {
    background-color: $terracotta;
}
.cc-surface-blu-baltico {
    background-color: $blu-baltico;
}
.cc-surface-error {
    background-color: $error;
}
.cc-surface-validation {
    background-color: $validation;
}
.cc-surface-focused {
    background-color: $focused;
}
.cc-surface-dark-40 {
    background-color: rgba($black, .4);
}
.cc-surface-dark-20 {
    background-color: rgba($black, .2);
}

/* border token */
.cc-border-black {
    border: rem-calc(1) solid $black;
}
.cc-border-white {
    border: rem-calc(1) solid $white;
}
.cc-border-light-grey {
    border: rem-calc(1) solid $light-grey;
}
.cc-border-medium-grey {
    border: rem-calc(1) solid $medium-grey;
}
.cc-border-grey {
    border: rem-calc(1) solid $grey;
}
.cc-border-organic {
    border: rem-calc(1) solid $organic;
}
.cc-border-flece {
    border: rem-calc(1) solid $felce;
}
.cc-border-jeans {
    border: rem-calc(1) solid $jeans;
}
.cc-border-sale-rosa {
    border: rem-calc(1) solid $sale-rosa;
}
.cc-border-terracotta {
    border: rem-calc(1) solid $terracotta;
}
.cc-border-blu-baltico {
    border: rem-calc(1) solid $blu-baltico;
}
.cc-border-validation {
    border: rem-calc(1) solid $validation;
}
.cc-border-error {
    border: rem-calc(1) solid $error;
}
.cc-border-focused {
    border: rem-calc(1) solid $focused;
}