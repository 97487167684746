/* SPACING */
$styleList-spacing: (
    xxxs: 4,
    xxs: 8,
    xs: 16,
    s: 24,
    m: 32,
    l: 48,
    xl: 64,
    xxl: 96,
    super: 128,
);

$xxxs: map-get($styleList-spacing, xxxs);
$xxs: map-get($styleList-spacing, xxs);
$xs: map-get($styleList-spacing, xs);
$s: map-get($styleList-spacing, s);
$m: map-get($styleList-spacing, m);
$l: map-get($styleList-spacing, l);
$xl: map-get($styleList-spacing, xl);
$xxl: map-get($styleList-spacing, xxl);
$super: map-get($styleList-spacing, super);

@function getSpacing($value) {
  @return map-get($styleList-spacing, $value);
}

@each $typeStyle in map-keys($styleList-spacing) {
  .cc-m-#{$typeStyle} {
      margin: rem-calc(getSpacing($typeStyle));
  }
  .cc-mt-#{$typeStyle} {
    margin-top: rem-calc(getSpacing($typeStyle));
  }
  .cc-mb-#{$typeStyle} {
    margin-bottom: rem-calc(getSpacing($typeStyle));
  }
  .cc-ml-#{$typeStyle} {
    margin-left: rem-calc(getSpacing($typeStyle));
  }
  .cc-mr-#{$typeStyle} {
    margin-right: rem-calc(getSpacing($typeStyle));
  }
  .cc-p-#{$typeStyle} {
    padding: rem-calc(getSpacing($typeStyle));
  }
  .cc-pt-#{$typeStyle} {
    padding-top: rem-calc(getSpacing($typeStyle));
  }
  .cc-pb-#{$typeStyle} {
    padding-bottom: rem-calc(getSpacing($typeStyle));
  }
  .cc-pl-#{$typeStyle} {
    padding-left: rem-calc(getSpacing($typeStyle));
  }
  .cc-pr-#{$typeStyle} {
    padding-right: rem-calc(getSpacing($typeStyle));
  }
}