// VARIABLES
$columnOpeningDuration: 800ms;
$rowLinkOpacityTransitionDuration: 200ms;

$columnLeftTransition: left $columnOpeningDuration ease;
$columnOpacityTransition: opacity $columnOpeningDuration ease;
$columnOpacityTransitionDelayed: opacity 0ms ease $columnOpeningDuration;
$rowLinkOpacityTransition: opacity $rowLinkOpacityTransitionDuration ease;
$catNameAppearanceTransition: top 300ms ease, opacity 300ms ease;

.cc-overlay-menu-open {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: $black, $alpha: 0.4);
    z-index: 2;
    width: 100vw;
    height: 100vh;
}

.bodyFixed, // TODO: remove this class when not being used anymore throughout the site
.cc-bodyFixed {
    overflow-y: hidden;
}

// MENU RESTYLE
.cc-fnMenu {
    @include flex(row);
    position: fixed;
    top: rem-calc(53); // Fallback value, but top is calculated in mainMenu.js as well as for .js-fnMenu-columnContainer
    left: -100vw;
    opacity: 0;
    z-index: $zIndex-menu;
    width: 100%;
    height: 100%;
    transition: opacity 500ms ease;

    @include breakpoint(large up) {
        transition: none;
    }

    &.cc-in {
        left: 0;
        opacity: 1;
       & .cc-fnMenu-columnFooter_desktop{
        visibility: visible;
       }
    }

    &.top-strip-in {
        top: rem-calc(93); // TODO: Sovraheader + header height - might delete later when sovraheader is developed and top value is calculated automatically
    }

    // MENU MOBILE HEADER
    &-mobileHeader {
        @include flex(row, space-between, center);
        position: absolute;
        top: 0;
        width: 100%;
        padding: rem-calc($xs);
        background: $surface-white;
        transform: translateY(-100%);
        border-bottom: rem-calc(1) solid $border-grey;
        @include breakpoint (large up) {
            display: none;
        }
    }

    // MENU CLOSE ICON
    &-closeButton {
        @include flex(row);
        gap: rem-calc($xxs);
        color: $text-black;

        @include breakpoint (medium down) {
            @include flex(row, center, center);
            width: rem-calc(44);
            height: rem-calc(44);
            position: absolute;
            top: rem-calc(5);
            right: rem-calc(4);
        }
    }

    // HIGHLIGHT BAR
    &-highlightBar {
        border-bottom: rem-calc(1) solid $border-medium-grey;
        margin-bottom: rem-calc($s);
        margin-top: rem-calc($xxs);

        @include breakpoint (large up) {
            margin-top: 0;
        }
    }

    // TOGGLE MENU - MOBILE
    &-toggleMenuMobile {
        color: $text-black;
        cursor: pointer;
        width: rem-calc(44);
        height: rem-calc(44);
    }

    // GO BACK - MOBILE
    &-columnGoBack {
        @include flex(row, start, center);
        gap: rem-calc($xxs);

        &-icon {
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }

    // MAIN HEADER MENU ITEM
    &-menuItem {
        &_inactive {
            color: $text-medium-grey;
        }
    }

    // CONTAINER OF ALL COLUMNS
    &-containerArea {
        @include flex(row);
    }

    // SINGLE COLUMN
    &-columnContainer {
        @include flex(column);
        width: 100vw;
        height: calc(100dvh - #{rem-calc(53)}); // Fallback value, height of the container is calculated automatically in mainMenu.js
        background-color: $surface-white;
        -webkit-transition: $columnLeftTransition;
        -moz-transition: $columnLeftTransition;
        -ms-transition: $columnLeftTransition;
        -o-transition: $columnLeftTransition;
        transition: $columnLeftTransition;
        margin-left: 0;
        position: fixed;
        padding: rem-calc(32 8 32 16);
        @include breakpoint (large up) {
            padding: rem-calc(32 16 32 32);
            border-right: rem-calc(1) solid $border-grey;
            width: 26.1vw;
            left: -26.1vw;

            // Add top spacing for expandable columns
            &:not([data-level="0"]) {
                .cc-fnMenu-columnBody {
                    margin-top: rem-calc(46); // "X Close" div height
                }

                .cc-fnMenu-rowLink,
                .cc-fnMenu-accordionCategory,
                .cc-fnMenu-categoryFooter-content,
                .cc-fnMenu-section-title {
                    opacity: 0;
                    transition: opacity 500ms ease;

                    &.cc-opacity1 {
                        opacity: 1;
                    }
                }
            }
        }

        &_mobile {
            @include breakpoint (large up) {
                display: none;
            }
        }

        &.cc-transitionOpacity {
            -webkit-transition: $columnLeftTransition, $columnOpacityTransition;
            -moz-transition: $columnLeftTransition, $columnOpacityTransition;
            -ms-transition: $columnLeftTransition, $columnOpacityTransition;
            -o-transition: $columnLeftTransition, $columnOpacityTransition;
            transition: $columnLeftTransition, $columnOpacityTransition;

            // Opacity is delayed when column is closing
            &-delay {
                opacity: 0;
                -webkit-transition: $columnLeftTransition, $columnOpacityTransitionDelayed;
                -moz-transition: $columnLeftTransition, $columnOpacityTransitionDelayed;
                -ms-transition: $columnLeftTransition, $columnOpacityTransitionDelayed;
                -o-transition: $columnLeftTransition, $columnOpacityTransitionDelayed;
                transition: $columnLeftTransition, $columnOpacityTransitionDelayed;
            }
        }

        &.cc-removeTransition {
            transition: none;
        }

        @for $i from -1 through 2 {
            &[data-level="#{$i}"] {
                z-index: $zIndex-menu + 3 - ($i - 1);
                @include breakpoint (large up) {
                    z-index: $zIndex-menu + 3 - $i;
                }
            }
        }

        &-overflow {
            @include flex(column);
            overflow: auto;
            padding-right: rem-calc($xxs);
            flex: 1 1 0;
            @include breakpoint (large up) {
                padding-top: rem-calc($xs);
                padding-right: rem-calc($xs);
            }

            &::-webkit-scrollbar {
                width: rem-calc(1);
            }
            &::-webkit-scrollbar-thumb {
                background: $black;
            }
            &::-webkit-scrollbar-track {
                background: $medium-grey;
            }
        }
    }

    // HEADER OF SINGLE COLUMN
    &-columnHeader {
        display: none;
        @include breakpoint (large up) {
            display: block;
            padding: rem-calc(14 0);
        }
    }

    // BODY OF SINGLE COLUMN
    &-columnBody {
        @include flex(column, flex-start, flex-start);
        margin-bottom: rem-calc($s);
        width: 100%;

        .cc-fnMenu-rowLink-anchor {
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textLarge'));
            letter-spacing: rem-calc(getLetterSpacing('textLarge'));
            line-height: rem-calc(getLineHeight('textLarge'));
        }

        &[data-level="0"] {
            .cc-fnMenu-rowLink {
                @include breakpoint (large up) {
                    margin-bottom: rem-calc($s);
                }
            }
            .cc-fnMenu-rowLink,
            .cc-fnMenu-highlightBar {
                @include breakpoint (large up) {
                    opacity: 0;
                    transition: $rowLinkOpacityTransition;
                    -webkit-transition: $rowLinkOpacityTransition;
                    -moz-transition: $rowLinkOpacityTransition;
                    -ms-transition: $rowLinkOpacityTransition;
                    -o-transition: $rowLinkOpacityTransition;

                    &.cc-opacityAnimation {
                        opacity: 1;
                    }
                }

                &-anchor {
                    @include breakpoint (large up) {
                        font-family: $body-font-family;
                        font-weight: $global-weight-normal;
                        font-size: rem-calc(getFontSize('title'));
                        letter-spacing: rem-calc(getLetterSpacing('title'));
                        line-height: rem-calc(getLineHeight('title'));
                    }
                }
            }
        }

        // Visible only for Mobile
        &[data-level="-1"] {
            .cc-fnMenu-rowLink {
                opacity: 0;
                transition: $rowLinkOpacityTransition;
                -webkit-transition: $rowLinkOpacityTransition;
                -moz-transition: $rowLinkOpacityTransition;
                -ms-transition: $rowLinkOpacityTransition;
                -o-transition: $rowLinkOpacityTransition;

                &.cc-opacityAnimation {
                    opacity: 1;
                }

                &-anchor {
                    font-family: $body-font-family;
                    font-weight: $global-weight-normal;
                    font-size: rem-calc(getFontSize('title'));
                    letter-spacing: rem-calc(getLetterSpacing('title'));
                    line-height: rem-calc(getLineHeight('title'));
                }
            }
        }
    }

    // MENU FOOTER CONTAINER
    &-columnFooter {
        @include flex(column, flex-start, flex-start);
        margin-top: auto;
        gap: rem-calc($xs);

        &_desktop {
            display: none;
            @include breakpoint(large up) {
                @include flex(column, flex-start, flex-start);
                visibility: hidden;
            }
        }
    }

    // MENU FOOTER ITEM
    &-linkFooter {
        @include flex(row);
        gap: rem-calc($xxs);

        &-logged {
            position: relative;
            &::after {
                content: '';
                width: rem-calc(4);
                height: rem-calc(4);
                background: $surface-dark;
                position: absolute;
                right: rem-calc(-$xxxs);
                top: 0;
                display: block;
            }
        }

        &-profile {
            @include flex(row, space-between, center);

            &, &-wrapper {
                width: 100%;
            }

            &-wrapper {
                @include flex(column, flex-start, flex-start);
                gap: rem-calc($xs);
                margin-top: rem-calc($xs);
            }
        }
    }

    &-expandColumn {
        @include breakpoint (large up) {
            &::after {
                content: '';
                opacity: 0;
                position: absolute;
                right: rem-calc(-$xxs);
                transition: opacity 0.3s ease, right 0.5s ease;
                width: rem-calc(16);
                height: rem-calc(16);
                background: url(../../images/chevron-right.svg) no-repeat center;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover, .cc-fnMenu-rowLink_active & {
                &::after {
                    opacity: 1;
                    right: 0;
                }
            }
        }
    }

    // MENU ELEMENT LINK
    &-rowLink {
        @include flex(row, space-between, center);
        position: relative;
        width: 100%;
        margin-bottom: rem-calc($xs);

        .cc-onMouseenter &:not(.cc-fnMenu-rowLink_active) > &-anchor {
            @include breakpoint (large up) {
                color: $text-medium-grey;

                &:hover {
                    color: initial;
                }
            }
        }

        &-catName {
            position: relative;
        }

        &-catTagName {
            position: absolute;
            top: rem-calc(-10);
            right: 0;
            transform: translateX(100%);
            opacity: 0;
            transition: $catNameAppearanceTransition;

            &.cc-tagIn {
                top: rem-calc(-15);
                opacity: 1;
            }
        }

        &_goBack {
            display: block;
            margin-bottom: rem-calc($s);
            padding: rem-calc(10 0);
            @include breakpoint (large up) {
                display: none;
            }
        }

        &_inactive {
            .cc-fnMenu-rowLink-anchor {
                @include breakpoint (large up) {
                    color: $text-medium-grey;
                }

                &:hover {
                    color: initial;
                }
            }
        }
    }

    &-accordionCategory {
        width: 100%;
        order: 2;
        margin-bottom: rem-calc($xs);
        @include breakpoint (large up) {
            margin-bottom: rem-calc($xxs);
        }

        &-item {
            border-bottom: rem-calc(1) solid $border-medium-grey;
            &.active {
                .cc-accordion {
                    &-title {
                        &::after {
                            background: url("../images/minus-new-grey.svg") no-repeat center;
                        }
                    }
                }
            }
        }

        &-title {
            text-align: left;
            padding-left: rem-calc($xxs);
            padding-top: rem-calc(18);
            padding-bottom: rem-calc(18);
            width: 100%;
            @include breakpoint (large up) {
                padding-top: rem-calc(10);
                padding-bottom: rem-calc(10);
            }

            &::after {
                background: url("../images/plus-new-grey.svg") no-repeat center;
                width: rem-calc(20);
                height: rem-calc(20);
                right: rem-calc(14);
            }
        }

        &-content {
            padding: rem-calc($xs $xxs $xxs);
        }

        &_margin {
            margin-top: rem-calc($m);
            @include breakpoint (large up) {
                margin-top: rem-calc($s);
            }
        }
    }

    // VERTICAL MENU LAYOUT
    &-verticalLayout {
        display: none;
        @include breakpoint (large up) {
            display: block;
        }
        &-container {
            @include flex(column);
        }
    }

    &-section {
        &-container {
            @include flex(column);
            width: 100%;
            gap: rem-calc($xxs);
        }
    }

    &-categoryFooter {
        &-content {
            &-link {
                @include flex(column, flex-start, flex-start);
                width: 100%;
                gap: rem-calc($xxs);
            }
        }
    }
}

.cc-horizontalHeader {
    .cc-fnMenu-verticalLayout {
        &-container {
            @include flex(row, center, center);

            // All menu items in horizontal header must be grey except the hovered one
            &.cc-onMouseenter {
                .cc-fnMenu-verticalLayout-anchor {
                    color: $text-grey;

                    &:hover {
                        color: $text-black;
                    }
                }
            }

            // All menu items in horizontal header must be grey except the active one
            &.cc-menuItemIsActive {
                .cc-fnMenu-verticalLayout-anchor {
                    color: $text-grey;

                    &_active {
                        color: $text-black;
                    }
                }
            }
        }
    }
}