// Custom Main container
.grid-container {
    max-width: rem-calc(1950);
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);

    @include breakpoint (medium up) {
        max-width: rem-calc(2000);
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);
        
    }

    &--narrow {
        @include breakpoint (medium up) {
            padding-left: calc(#{xy-cell-size(1 of 12)} + #{map-get($grid-margin-gutters, small)});
            padding-right: calc(#{xy-cell-size(1 of 12)} + #{map-get($grid-margin-gutters, small)});
            
        }
    }

    .cell {
        .padding-right {
            padding-right: rem-calc(8);
        }
    }
}
.main-header .grid-container {
    max-width: 100%;
}
.grid-container-fluid {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
}


