
.privacy-policy-profiling-popup,
.privacy-policy-marketing-popup {
    z-index: 3011;

    @include breakpoint(small down) {
        .form-request-title {
            width: 85%;
            padding-right: rem-calc(21);
        }
    }

    .tingle-modal-box {
        height: 100vh;
        max-height: none;
        max-width: none;
        width: rem-calc(491);
        margin-left: auto;
        @include breakpoint (small down) {
            height: 90vh;
            width: 100vw;
            margin-bottom: 0;
        }
        .tingle-modal-box__content {
            padding: rem-calc(40 0 0);
            min-height: -webkit-fill-available;
            min-height: 100vh;
            .close{
                top: rem-calc(16);
                right: rem-calc(16);
                z-index: 1;
            }
            .cc-close{
                position: absolute;
                top: rem-calc(19);
                right: rem-calc(16);
                z-index: 1;
            }
            .ca-privacy-policy-title{
                width: 100%;
                position: absolute;
                top: rem-calc(16);
                right: 0;
                text-align: center;
                &-text{
                    margin-top: rem-calc(2);
                }
            }
        }
    }
}



.form-request-title {
    font-size: rem-calc(20);
    letter-spacing: rem-calc(0.2);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(15);
    font-weight: 400;

    @include breakpoint(medium down) {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }
}

.form-request-paragraph {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(40);
}

.form-request-paragraph-no-margin {
    font-size: rem-calc(16);

    @include breakpoint(medium down) {
        font-size: rem-calc(14);
    }
}

.intro-content {
    position: relative;
    text-align: justify;
    padding: rem-calc(24 16);
    border-top: rem-calc(1) solid $medium-grey;
    margin-top: rem-calc(16);
}