.card {
    position: relative;
    margin-top: rem-calc(22);
    margin-bottom: rem-calc(42);
    border: rem-calc(1) solid $border-medium-grey;
    padding-top: rem-calc(12);
    background: $white;

    &.coupon-code{
        .coupon-error,
        .coupon-confirmation{
            @include breakpoint(medium down){
                margin-top: 0;
            }
        }

        a.disabled:not(.button), .disabled>a:not(.button){
            color: #000000;
            text-decoration: none;
            cursor: default !important;
            opacity: 0.5;
        }

        .coupon-card-padding{
            padding-left: 0;
            padding-right: 0;
            @include breakpoint(medium down){
                padding: rem-calc(0 40);
            }
            @include breakpoint(small down){
                padding: rem-calc(0 20);
            }
        }
        .optional-promo{
            font-size: rem-calc(12);
            line-height: rem-calc(16);
        }

        #add-coupon-code{
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
        }
    }
    &.ghost {
        padding-bottom: rem-calc(12);
    }

    @include breakpoint (small down) {
        margin-top: 0;
        margin-bottom: rem-calc(22);
        padding-top: rem-calc(12);
    }

    &.only-border-bottom {
        border: none;
        border-bottom: 1px solid $medium-light-gray;

        &.lighter {
            border-bottom: 1px solid $border-gray;
        }
    }

    &.small-margin {
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(10);
        padding-top: 0;
    }

    &--no-header {
        padding-top: 0;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &-body {

        .remove-btn {
            font-size: rem-calc(24);
            font-weight: 300;
            cursor: pointer;
            height: rem-calc(20);
            margin: auto 0 0;
            display: flex;
            align-items: center;
            @include breakpoint(medium down){
                position: relative;
                margin-top: rem-calc(20);
            }
        }

        .space-personalization {
            bottom: rem-calc(-35) !important
        }

        &.return {
            margin-top: rem-calc(-10);
            @include breakpoint (small down) {
                margin-top: rem-calc(-15);
            }
        }
    }

    h5.section-title {
        font-weight: $global-weight-dark;
    }

    p.smaller-text {
        font-size: rem-calc(14);
    }

    .form-section {
        border-bottom: 1px solid $border-gray;

        &.no-border {
            border-bottom: none;
        }
    }

    &-footer {
        padding: rem-calc(0 22 18);
    }

    &-button {
        position: absolute;
        bottom: 0;
        right: rem-calc(22);
        transform: translateY(60%);
    }

    // Alternate modifier
    &--alternate {
        background: #F6F6F5;
        border: none;
        .card-header:not(.no-padding){
            padding: rem-calc(21 0);
        }
        .total-box-body{
            padding: 0;
            border: 0 !important;
            .total-box-body-item{
                &.cart-discount{
                    justify-content: flex-start;
                }
                &.shipping-cost{
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;

                    div{
                        display: flex;
                    }
                }
                .cell{
                    position: relative;
                }
                p.regular{
                    line-height: rem-calc(22);
                }
                .grand-total-conversion-rate-wrapper{
                    width: 100%;
                }
                .align-prices {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
                &:nth-child(2):not(.order-discount){
                    margin-top: rem-calc(20);
                }
                &.scalapay-widget{
                    color: gray;
                    font-size: 12px;
                }
                @include breakpoint(medium down){
                    margin: rem-calc(10 40);
                }
                @include breakpoint(small down){
                    margin: rem-calc(10 15);
                }
                &:last-child{
                    @include breakpoint(medium down){
                        padding: rem-calc(0 0 30 0);
                    }
                    @include breakpoint(small down){
                        padding: rem-calc(0 0 20);
                    }
                }
                .total-info-shipping{
                    .icon{
                        height: rem-calc(12);
                        width: rem-calc(12);
                        background: transparent;
                        line-height: rem-calc(12);
                        font-size: rem-calc(7);
                        margin-top: rem-calc(5);
                        margin-left: rem-calc(8);
                    }
                    .info-tooltip-total-shipping{;
                        transform: translate(-40%, -110%);
                        visibility: unset;
                    }
                }
            }
        }
    }
}
.cc-leading-lines {
    position: relative;
    overflow: hidden;
    margin-bottom: rem-calc(16);
    border-bottom: 1px solid $border-gray;

    label,
    p {
        margin-bottom: rem-calc(6);
    }
}

// Card shadow exceptions
.page-checkout,
.receipt {
    .card {
        box-shadow: none;
    }
}
