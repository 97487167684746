@import "../mixins/helpers";
@import "../pages/pdp/suggestedProducts";

.cc-searchBar {
    &-container {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100dvw;
        height: 100dvh;
        z-index: $zIndex-search;
        opacity: 0;
        background-color: $white;
        transition: opacity 500ms ease;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        @include breakpoint(large up) {
            opacity: 1;
            background-color: $transparent;
        }

        &_visible {
            opacity: 1;
        }

        &_notVisible {
            @include breakpoint(large up) {
                opacity: 0;
            }
        }
    }

    &-wrapper {
        @include flex(column, initial, initial);
        height: 100%;
    }

    &-isOpen {
        display: block;
    }

    &-closeButton {
        padding: rem-calc(14);
        margin-left: auto;
    }

    &-top {
        @include flex(column, space-between, center);
        gap: rem-calc($s);
        background-color: $white;
        z-index: 1;

        @include breakpoint(large up) {
            gap: rem-calc($xxs);
            transition: transform 500ms ease, opacity 500ms ease;
            opacity: 0;
            transform: translateY(-100%);

            &_visible {
                transform: translateY(0);
                opacity: 1;
            }
        }

        &-header {
            @include flex(row, flex-start, center);
            gap: rem-calc($xs);
            width: 100%;
            padding: rem-calc($xxs $xs 0);
            @include breakpoint(large up) {
                height: rem-calc(62);
                padding: rem-calc($xxs $m 0);
            }

            &-wrapper {
                position: relative;
                width: 100%;
            }

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: rem-calc(1);
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $border-medium-grey;
                z-index: -1;
            }
        }
    }

    &-form {
        width: 100%;
    }

    &-bottom {
        @include flex(column, initial, initial);
        flex: 1;
        background-color: $white;
        opacity: 0;
        padding-top: rem-calc($s);
        transform: translateY(-50px);
        transition: transform 500ms ease 500ms, opacity 500ms ease 500ms; // delayed for mobile
        @include breakpoint(large up) {
            transform: translateY(100px);
            transition: transform 500ms ease, opacity 500ms ease;
            position: relative;
            padding-top: 0;

            &::after {
                content: '';
                position: absolute;
                top: rem-calc(1);
                left: 0;
                width: 100%;
                height: rem-calc(50);
                transform: translateY(-100%);
                background-color: $white;
            }
        }
        &_visible {
            opacity: 1;
            transform: translateY(0);
        }

        &-suggestions {
            @include breakpoint(large up) {
                padding-top: rem-calc($xxs);
            }
        }

        &-faq {
            padding: rem-calc($xs);
            margin-top: auto;
            @include breakpoint(large up) {
                gap: rem-calc($m);
                padding: rem-calc(0 $m $m);
            }

            &-wrapper {
                @include flex(column, initial, initial);
                gap: rem-calc($xs);
            }

            &-items {
                @include flex(column, initial, initial);
                border: rem-calc(1) solid $border-medium-grey;
                @include breakpoint(large up) {
                    @include flex(row, flex-start, stretch);
                }
            }

            &-singleItem {
                @include flex(row, space-between, center);
                gap: rem-calc($xxs);
                padding: rem-calc($xs);
                flex: 1;

                &:not(:last-child):not(:focus-visible) {
                    border-bottom: rem-calc(1) solid $border-medium-grey;
                    @include breakpoint(large up) {
                        border-bottom: 0;
                        border-right: rem-calc(1) solid $border-medium-grey;
                    }
                }

                &-icon {
                    width: rem-calc(16);
                    height: rem-calc(16);
                }
            }
        }
    }

    &-gender {
        padding: rem-calc($xxs $xs);
        min-width: rem-calc(48);
        width: calc(50% - #{rem-calc($m)});
        border: rem-calc(1) solid $transparent;
        height: rem-calc(54);
        z-index: 1;

        @include breakpoint(large up) {
            min-width: rem-calc(40);
            width: unset;
            padding: rem-calc($xxs);
        }

        &_selected {
            border-bottom-color: $border-black;
        }
    }

    &-input {
        position: relative;
        padding: rem-calc(0 $xs);

        @include breakpoint(large up) {
            padding: rem-calc(0 $m);
        }

        &-field {
            margin-bottom: 0;
            padding-left: calc(#{rem-calc($l)}) !important;
        }

        &-clearButton {
            padding: rem-calc(0 $xxs);
            right: calc(#{rem-calc($m + $xxs)});
            display: none;
            @include breakpoint(large up) {
                display: block;
            }
        }

        &-icon{
            left: calc(#{rem-calc($xs + $xs)});
            @include breakpoint(large up) {
                left: calc(#{rem-calc($m + $xxs)});
            }
        }

        &-icon,
        &-clearButton {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-suggestions {
        @include flex(column, flex-start, flex-start);
        gap: rem-calc($xxs);
        padding: rem-calc(0 $xs);
        @include breakpoint(large up) {
            @include flex(row, flex-start, center);
            gap: rem-calc($s);
            padding: rem-calc(0 $m);
        }

        &-title {
            margin-bottom: 0;
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textSmall'));
            letter-spacing: rem-calc(getLetterSpacing('textSmall'));
            line-height: rem-calc(getLineHeight('textSmall'));

            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('textMedium'));
                letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                line-height: rem-calc(getLineHeight('textMedium'));
            }
        }

        &-items {
            @include flex(column, flex-start, flex-start);
            gap: rem-calc($xxs);
            @include breakpoint(large up) {
                gap: rem-calc($xs);
                @include flex(row, flex-start, center);
            }
        }

        &-singleItem {
            @include breakpoint(large up) {
                padding: rem-calc(10 0);

                &:hover {
                    color: $text-grey;
                }
            }
        }
    }

    &-results {
        @include flex(column, flex-start, initial);
        margin-top: rem-calc($m);
        margin-bottom: rem-calc($m);
        @include breakpoint(large up) {
            margin-top: rem-calc($s);
            margin-bottom: rem-calc($super);
            gap: rem-calc($s);
        }

        &-header {
            @include flex(row, space-between, center);
            gap: rem-calc($xs);
            padding: rem-calc($xs);
            @include breakpoint(large up) {
                padding: rem-calc($xxs $m);
            }
        }

        &-productsLabel {
            margin-bottom: 0;
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            font-size: rem-calc(getFontSize('textLarge'));
            letter-spacing: rem-calc(getLetterSpacing('textLarge'));
            line-height: rem-calc(getLineHeight('textLarge'));

            @include breakpoint(large up) {
                font-size: rem-calc(getFontSize('title'));
                letter-spacing: rem-calc(getLetterSpacing('title'));
                line-height: rem-calc(getLineHeight('title'));
            }
        }

        &-grid {
            @include flex(row, flex-start, flex-start);
            overflow-x: auto;
            overflow-y: hidden;
            padding-left: rem-calc($xs);
            &::-webkit-scrollbar {
                display: none;
            }
            @include breakpoint(large up) {
                padding-left: 0;
            }

            &Item {
                width: 68.5%;
                flex: none;
                @include breakpoint(large up) {
                    width: 25%;
                }
            }
        }

        &_noResults {
            .cc-searchBar-results-header {
                @include flex(column, initial, initial);
                gap: rem-calc($xxs);
            }

            .cc-searchBar-results-productsLabel {
                font-size: rem-calc(getFontSize('title'));
                letter-spacing: rem-calc(getLetterSpacing('title'));
                line-height: rem-calc(getLineHeight('title'));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize('headline'));
                    letter-spacing: rem-calc(getLetterSpacing('headline'));
                    line-height: rem-calc(getLineHeight('headline'));
                }
            }

            .cc-searchBar-results-suggestedLabel {
                margin-bottom: 0;
                font-family: $body-font-family;
                font-weight: $global-weight-normal;
                font-size: rem-calc(getFontSize('textSmall'));
                letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                line-height: rem-calc(getLineHeight('textSmall'));

                @include breakpoint(large up) {
                    font-size: rem-calc(getFontSize('textMedium'));
                    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
                    line-height: rem-calc(getLineHeight('textMedium'));
                }
            }
        }

        &-arrowsBox {
            &_desktop {
                display: none;

                @include breakpoint(large up) {
                    @include flex(row, initial, initial);
                    gap: rem-calc($s)
                }
            }
            &_mobile {
                @include flex(row, space-between, initial);

                @include breakpoint(large up) {
                    display: none;
                }
            }

            &-icon {
                cursor: pointer;
                padding: rem-calc(14);

                @include breakpoint(large up) {
                    padding: 0;
                }

                &.slick-disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }


            &-image {
                width: rem-calc(16);
                width: rem-calc(16);
            }
        }
    }
}