.visibility-hidden {
    visibility: hidden;
}
.web-form-request-page, .web-form-request-modal {
    .form-request-title{
        font-size: rem-calc(20);
    }
    .contact-form-step-one{
        .form-support-description{
            margin-bottom: rem-calc(30);
            line-height: rem-calc(22);
            font-weight: 300;
        }
    }
    .contact-form-step-two{
        .send-button{
            @include breakpoint(small down){
                width: 100%;
            }
        }
        .more-info-text-form{
            font-size: rem-calc(9);
            line-height: rem-calc(17);
            margin-bottom: rem-calc(52);
            margin-top: rem-calc(-5);
            @include breakpoint(medium down){
                margin-top: rem-calc(15);
                margin-bottom: rem-calc(30);
            }
        }
        .recaptcha-cell{
            margin-bottom: rem-calc(23);
        }
    }
}

.web-form-request-modal{
    .tingle-modal-box__content{
        @include breakpoint(medium up){
            height: calc(100% - 85px) !important;
        }
        &.height-unset{
            height: unset !important;
        }
        .title-rect-fixed {
            @include breakpoint (medium up) {
                padding-top: rem-calc(40);
                padding-bottom: rem-calc(20);    
            }
        }
        .form-support-description {
            font-size: rem-calc(16);
        }
    }
    .send-button{
        width: 100%;
    }
    .no-robot{
        font-size: rem-calc(10);
        font-weight: 600;
        color: #DD2B2E;
        text-transform: uppercase;
        padding-top: rem-calc(10);
    }
    @include breakpoint(medium down){
        .contact-form{
            height: calc(100% - 65px) !important;
            padding-top: rem-calc(20);
            &.height-unset{
                height: unset !important;
            }
            .contact-form-step-one{
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            .button-margin{
                padding-bottom: 0 !important;
                margin-top: auto;
            }
        }
        .form-request-title{
            font-size: rem-calc(16);
        }
    }
    .title-rect-fixed {
        padding-bottom: rem-calc(20);
    }
}

.web-form-request-page {
    .close-button-modal {
        display: none;
    }
    &.flex-grow-1{
        flex-grow: 1;
    }
    .contact-us-image{
        width: 100%;
        @include breakpoint(large up){
            margin-bottom: rem-calc(90);
        }
    }
    &-right{
        margin-top: rem-calc(80);
        @include breakpoint(medium down){
            margin-top: rem-calc(30);
        }
        .fix-padding{
            padding-left: 8.472vw;
            padding-right: 8.472vw;
            @include breakpoint(medium down){
                padding-left: rem-calc(20);
                padding-right: rem-calc(20);
            }
            .form-request-title{
                margin-bottom: rem-calc(16) !important;
                font-size: rem-calc(16);
            }
            .contact-form-step-one{
                .form-support-description{
                    margin-bottom: rem-calc(30);
                    font-size: rem-calc(14);
                    @include breakpoint(medium down){
                        margin-bottom: rem-calc(40);
                    }
                }
                .contactType{
                    @include breakpoint(medium down){
                        margin-bottom: rem-calc(28);
                    }
                }
                .contact-form-next-step{
                    margin-bottom: 0;
                    @include breakpoint(medium down){
                        margin-top: rem-calc(55);
                        margin: rem-calc(55 0 76);
                    }
                }
                
            }
            .send-button,
            .contact-form-next-step{
                @include breakpoint(large up){
                    width: unset;
                    min-width: rem-calc(212);
                    margin-top: rem-calc(26);
                }
            }
            .contact-form-step-two{
                .contact-form-recap{
                    @include breakpoint(medium down){
                        margin-top: rem-calc(24);
                    }
                }
                .more-info-text-form{
                    @include breakpoint(large up){
                        margin-top: rem-calc(30);
                        margin-bottom: rem-calc(10);
                    }
                }
                .send-button{
                    margin-top: 0;
                    @include breakpoint(large up){
                        margin-bottom: rem-calc(96);
                    }
                    @include breakpoint(small down){
                        margin-bottom: rem-calc(60);
                    }
                }
                .question-form-item{
                    margin-bottom: rem-calc(40);
                }
                .recaptcha-cell{
                    margin-bottom: rem-calc(28);
                    .no-robot{
                        margin-bottom: 0;
                        margin-left: 0;
                        font-size: rem-calc(12);
                        font-weight: 400 !important;
                        color: #CC3447;
                        padding-top: rem-calc(5);
                    }
                }
                .text-area-web-form-request.is-invalid-input:not(:focus){
                    border: 1px solid #A2A2A2;
                }
            }
        }
    }
    .contact-form-success,
    .contact-form-error{
        @include breakpoint(small down){
            margin-bottom: rem-calc(100);   
        }
    }
}
.continue-to-form {
    margin-bottom: rem-calc(40);
    font-size: rem-calc(16);
    line-height: rem-calc(22);
    font-weight: 400;
    letter-spacing: rem-calc(0.23);
}

.contact-link {
    text-decoration: underline;
    font-weight: inherit;
    &:hover {
        cursor: pointer;
    }
}

.no-faqs {
    margin-top: auto;
}

.cc-contact{
    margin: 0;
    padding: rem-calc(13 $xs 13 $s);
    border-bottom: rem-calc(1) solid $border-medium-grey;
    text-align: center;
    &-faqs {
        margin-top: auto;
    }
    &-close {
        font-size: unset;
        position: absolute;
        right: rem-calc(1);
        top: rem-calc($xxxs);
        width: rem-calc(44);
        height: rem-calc(44);
    }
    &-form{
        display: flex;
        padding: rem-calc($xs $xs 0 $xs);
        height: calc(100dvh - #{rem-calc(51)});
        @include breakpoint (large up) {
            padding: rem-calc($s $m 0 $m);
        }
    }
    &-button{
        position: fixed;
        left: 0;
        bottom: 0;
        padding: rem-calc($xs);
        background: $surface-white;
        width: 100%;
        border-top: rem-calc(1) solid $border-medium-grey;
        @include breakpoint (large up) {
            padding: rem-calc($xs $m);
        }
        & .cc-button-primary_black{
            width: 100%;
        }
    }
    &-field{
        width: 100%;
        display: flex; 
    }
    &-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        select{
            margin-bottom: rem-calc($xxs);
        }
    }
    &-faq{
        margin-top: rem-calc($s);
        margin-bottom: rem-calc($s);
        padding: rem-calc($xs);
        background: $surface-light-grey;
        &-button{
            padding: rem-calc($xs);
            background: $surface-white;
            width: calc(100% + #{rem-calc($m)});
            margin-left: rem-calc(-$xs);
            border-top: rem-calc(1) solid $border-medium-grey;
            margin-top: auto;
            @include breakpoint (large up) {
                padding: rem-calc($xs $m);
                width: calc(100% + #{rem-calc($xl)});
                margin-left: rem-calc(-$m);
            }
            & .cc-button-primary_black{
                width: 100%;
            }
        }
        & .cc-accordion-title{
            padding-right: rem-calc(20);  
            &::after {
                right: rem-calc(4);
            }
        }
    }
    &-recap{
        background: $surface-light-grey;
        padding: rem-calc($xs);
        margin-bottom: rem-calc($m);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-wrap{
        display: flex; 
        flex-direction: column;
        @include breakpoint (large up) {
            flex-direction: row;
            justify-content: space-between;
        }
        &-data{
            @include breakpoint (large up) {
                width: calc(50% - #{rem-calc($xxs)});
            }
        }
    }
    &-result{
        display: flex; 
        align-items: center;
        background: $surface-light-grey;
        margin: rem-calc($s $m);
        padding: rem-calc($xxs);
        &-text{
            padding-left: rem-calc($xs);
        }
    }
}