.consent-modal {
	pointer-events: none;
	background: none;

	.tingle-modal-box {
		pointer-events: auto;
	}
}
.tracking-consent {

	p {
		font-size: rem-calc(10);
	}
}