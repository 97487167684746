.regulation-title{
    font-size: rem-calc(30);
    padding-bottom: rem-calc(15);
    font-weight: 600;
    margin: 0;
    text-align: left!important;
    width: 100%;
    height: rem-calc(56);
    border-bottom: rem-calc(1) solid $medium-gray;
    padding-left: 11%;
    background-image: url('../images/ico-arrow-left.svg');  
    background-size: rem-calc(20);
    background-position: rem-calc(0 8); 
    background-repeat: no-repeat;
    margin-bottom: rem-calc(58);
    @include breakpoint (small down) {
        padding-left: rem-calc(35);
        font-size: rem-calc(16);
        padding-bottom: rem-calc(10);
        padding-top: rem-calc(10);
        background-position: 0;
        height: auto;
        margin-bottom: rem-calc(10);
    }
}
// .close-button{
//     position: absolute;
//     top: rem-calc(23);
//     right: rem-calc(23);
//     margin-right: 0;
//     @include breakpoint (medium down){
//         right: 0;
//     }
//     img{
//         width: rem-calc(14);
//     }
// }
.regulation-list-style{
    margin-left: rem-calc(35);
    li{
        margin-bottom: rem-calc(15);
    }
}
.regulation-list-no-circle{
    list-style: none;
}