.prefooter-category-thanks{
    position: relative;
    .title-boxes-category{
        font-size: rem-calc(22);
        margin-bottom: rem-calc(40);
    }

    .img-categories-collection{
        max-width: rem-calc(375);
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    .link-center{
        width: 100%;
        position: absolute;
        top: 50%;
        margin-top: rem-calc(-12);
        display: block;
        text-align: center;
        font-size: rem-calc(25);
        color: $white;
        font-weight: 600;
        letter-spacing: rem-calc(1.5);
    }

    .link-text-category{
        position: relative;
    }

}