@import "select";
@import "../layout/spacing";
@import "../mixins/helpers";

.form-error,
.invalid-feedback {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    font-size: rem-calc(getFontSize('textSmall'));
    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
    line-height: rem-calc(getLineHeight('textSmall'));
    font-weight: $global-weight-normal!important ;
    color: $text-error;
    &:not(:empty) {
        display: flex !important;
        align-items: center;
        gap: rem-calc($xxxs);
    }
    &::before {
        content: url('../../images/error-icon-red.svg');
        width: rem-calc(16);
        height: rem-calc(16);
    }
}

.cc-form-item {
    margin: rem-calc($xxs 0 0 0);
    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea {
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textMedium'));
        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
        line-height: rem-calc(getLineHeight('textMedium'));
        border: rem-calc(1) solid $border-medium-grey;
        background-color: transparent;
        border-radius: 0;
        padding: rem-calc(16);
        height: rem-calc(56);
        transition: all 0.25s ease-in-out;
        margin-bottom: rem-calc($xxs);
        &:disabled {
            border-color: $border-medium-grey;
        }
        &::placeholder {
            color: transparent;
        }
        &:focus {
            outline: none;
            border: rem-calc(1) solid $border-black;
        }
        &.cc-height-textArea {
            min-height: rem-calc(150);
            resize: none;
            border: rem-calc(1) solid $border-medium-grey;
            padding: rem-calc(12) rem-calc(10);
            order: 2;
            & + .cc-form-item-title, & + .errors-list + .cc-form-item-title {
                display: none;
                transition: opacity 300ms, display 300ms;
                opacity: 0;
            }

            &:focus {
                border-color: $border-medium-grey;
            }
            &::placeholder {
                color: $text-grey;
                opacity: 1;
            }
        }
    }

    &-textArea{
        display: flex;
        flex-direction: column;
        position: relative;
        &.focused .cc-height-textArea {
            & + .cc-form-item-title, & + .errors-list + .cc-form-item-title{
                display: inline-block;
                order: 1;
                transition: opacity 300ms, display 300ms;
                opacity: 1;
                position: absolute;
                top: rem-calc(-9);
                width: fit-content;
            }
            &.is-invalid-input + .errors-list + .cc-form-item-title {
                color: $text-error;
                transition: opacity 300ms, display 300ms;
                opacity: 1;
                position: absolute;
            }
            &:focus {
                & + .errors-list + .cc-form-item-title {
                    color: $text-grey;
                }
            }
        }
    }

    textarea,
    select {
        border: rem-calc(1) solid $border-medium-grey;

        &:focus {
            outline: none;
        }

        #checkout-main & {
            &#shippingState,
            &#billingState {
                &.is-invalid-input + .chosen-container:not(.chosen-with-drop) .chosen-single {
                    border: solid rem-calc(1) $border-error;
                    background: $white;
                }
            }
        }
    }

    label {
        position: relative;
        display: flex;
        flex-direction: column;
        order: 1;
        .cc-input-icon{
            border: none;
            border-bottom: rem-calc(1) solid $border-medium-grey;
            padding: rem-calc(10 0);
            height: rem-calc(44);
            &:focus{
                border: none;
                border-bottom: rem-calc(1) solid $border-black;
            }
            &-label{
                left: 0;
                top: rem-calc(10);
                color: $text-grey;
            }
        }
        .cc-input-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            color: $text-black;
            width: rem-calc(44);
            height: 100%;
            position: absolute;
            top: rem-calc(0);
            right: rem-calc(0);
            z-index: 2;
            padding: rem-calc(0);
            &.disabled {
                opacity: .3;
                cursor: not-allowed;
            }
        }
        &.focused {
            .cc-input-label {    
                font-size: rem-calc(getFontSize('textSmall'));
                letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                line-height: rem-calc(getLineHeight('textSmall'));
                font-weight: $global-weight-normal;
                color: $text-grey;
                transform: none;
                left: rem-calc(12);
                top: rem-calc(-9);
                pointer-events: auto;
                background: $surface-white;
                padding: rem-calc(0 $xxxs);
                &.cc-input-icon-label{
                    display: none;
                }
            }
            .cc-input-button {
                opacity: 1;
            }
        }
        input:-webkit-autofill {
            & ~ .cc-input-label {
                font-size: rem-calc(getFontSize('textSmall'));
                letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                line-height: rem-calc(getLineHeight('textSmall'));
                font-weight: $global-weight-normal;
                color: $text-grey;
                transform: none;
                left: rem-calc(12);
                top: rem-calc(-10);
                pointer-events: auto;
            }
        }
    }
    .cc-input-label {
        display: block;
        font-family: $body-font-family;
        font-weight: $global-weight-normal;
        font-size: rem-calc(getFontSize('textMedium'));
        letter-spacing: rem-calc(getLetterSpacing('textMedium'));
        line-height: rem-calc(getLineHeight('textMedium'));
        color: $text-black;
        position: absolute;
        left: rem-calc(16);
        top: rem-calc(17);
        transition: all 250ms ease;
        pointer-events: none;
        margin: 0;
        white-space: nowrap;

        &.disabled {
            color: $text-grey;
            z-index: 2;
            &.cc-input-label_select {
                color: $text-medium-grey;
            }
        }
    }
    &-helper{
        font-size: rem-calc(getFontSize('textSmall'));
        letter-spacing: rem-calc(getLetterSpacing('textSmall'));
        line-height: rem-calc(getLineHeight('textSmall'));
        color: $text-grey;
        order: 2;
        margin-top: rem-calc(-$xxxs);
    }

    &-title{
        font-size: rem-calc(getFontSize('textSmall'));
        letter-spacing: rem-calc(getLetterSpacing('textSmall'));
        line-height: rem-calc(getLineHeight('textSmall'));
        color: $text-grey;
        position: absolute;
        display: inline-block;
        padding: rem-calc(0 $xxxs);
        left: rem-calc(12);
        top: rem-calc(-9);
        background: $surface-white;
        z-index: 1;
        width: fit-content;
        opacity: 0;
    }

    .errors-list{
        order: 3;
    }

    .hidden {
        display: none;
    }

    .is-invalid-input:not(:focus) {
        border-color: $border-error;
        background-color: transparent;
    }
    input.is-invalid-input:not(:focus):not(.boolean-field),
    input.is-invalid:not(:focus),
    select.is-invalid:not(:focus),
    textarea.is-invalid:not(:focus) {
        border: rem-calc(1) solid $border-error;
    }

    input.is-invalid:not(:focus) + .cc-input-label {
        color: $text-error;
    }

    select {
        &:focus {
            outline: none;
        }

        option {

            &:checked,
            &:hover {
                box-shadow: 0 0 10px 100px $black inset;
            }
        }

        &.is-invalid + .chosen-container .chosen-single {
            border: rem-calc(1) solid $border-error;
        }
    }

    label {
        &.inline {
            display: inline-block;
            margin-right: rem-calc(32);
        }
    }

    .parsley-errors-list {
        list-style-type: none;
        margin-left: 0;

        li {
            top: 0;
            margin-bottom: rem-calc(20);
            margin-left: 0;
            font-size: rem-calc(10);
            font-weight: $global-weight-bold;
            color: $alert;
            text-transform: uppercase;
        }
    }

    // Input Autocomplete
    .autocomplete {
        /*the container must be positioned relative:*/
        position: relative;
        display: inline-block;

        input {
            margin-bottom: 0;
        }

        .parsley-errors-list {
            margin-top: $input-padding;
        }

        .autocomplete-items {
            position: relative;
            border: 1px solid $black;
            border-bottom: none;
            border-top: none;
            z-index: 101;
            /*position the autocomplete items to be the same width as the container:*/
            top: 100%;
            left: 0;
            right: 0;
        }

        .autocomplete-items div {
            padding: rem-calc(8);
            cursor: pointer;
            background-color: $white;
            border-bottom: 1px solid $black;
        }

        .autocomplete-items div:hover {
            /* When hovering an item */
            background-color: $more-light-gray;
        }

        .autocomplete-active {
            /* When navigating through the items using the arrow keys */
            background-color: $medium-gray !important;
            color: $white;
        }
    }

    input.fiscalCode {
        cursor: pointer;
    }
    #fiscalCodePT {
        cursor: initial;
    }


    // Form item specific for the search field
    &_search {
        margin: 0;

        &:has(.parsley-errors-list.filled) {
            margin-bottom: rem-calc(16);

            .cc-searchBar-input-field {
                border-color: $border-error;
            }
        }

        .parsley-errors-list {
            position: absolute;
            margin: 0;
            bottom: rem-calc(-4);
            transform: translateY(100%);

            & > li {
                margin: 0;
                color: $text-error;
                font-weight: $global-weight-normal;
                font-size: rem-calc(getFontSize('textSmall'));
                letter-spacing: rem-calc(getLetterSpacing('textSmall'));
                line-height: rem-calc(getLineHeight('textSmall'));
                text-transform: none;

                &:not(:empty) {
                    display: flex !important;
                    align-items: center;
                    gap: rem-calc($xxxs);
                }
                &::before {
                    content: url('../../images/error-icon-red.svg');
                    width: rem-calc(16);
                    height: rem-calc(16);
                }
            }
        }

        input[type='search'] {
            &::placeholder {
                color: $text-grey;
            }

            &:focus {
                &::placeholder {
                    color: $transparent;
                }
            }

            @include breakpoint(large up) {
                border: 0;
                border-bottom: rem-calc(1) solid $border-medium-grey;

                &:focus {
                    outline: none;
                    border: 0;
                    border-bottom: rem-calc(1) solid $border-black;
                }
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }
        }
    }
}

.ellipsis-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cc-unknown-email-domain {
    margin-top: rem-calc($xxxs);
    display: none;
    font-size: rem-calc(getFontSize('textSmall'));
    letter-spacing: rem-calc(getLetterSpacing('textSmall'));
    line-height: rem-calc(getLineHeight('textSmall'));
    color: $text-error;
    &::before {
        content: url('../../images/error-icon-red.svg');
        width: rem-calc($xs);
        height: rem-calc($xs);
    }
    &.is-visible{
        display: flex !important;
        align-items: center;
        gap: rem-calc($xxxs);
    }
}

.change-email-form-error {
    margin-top: -0.75rem;
    margin-bottom: 1.5rem;
    margin-left: 0;
    font-size: rem-calc(12);
    font-weight: $global-weight-normal;
    color: $alert;
}