.alert[role="alert"], .cart-alert[role="alert"], .checkout-alert[role="alert"] {
    padding: rem-calc(8);
    border: none;

    &.alert-danger {
        background-color: $light-grey;
        color: $error;
    }

    &.alert-warning {
        background-color: $light-grey;
        color: $blu-baltico;
    }

    &.alert-blue {
        background: #D2DEF5;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.not-available-banner {
    padding: rem-calc(20);
    border: none;
    color: $alert;
}

.out-of-stock-line-item {
    .item-image,
    .top-info{
        opacity: 0.5;
    }
}