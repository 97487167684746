button,
.button,
span.widget-span, a {
    cursor: pointer;
    &:focus-visible {
        border: rem-calc(1) solid $border-focused;
        outline: none;
    }
}
button[type="button"]:not(.cc-button), a {
    color: $text-black;
}

.cc-button {
    position: relative;
    font-family: $body-font-family;
    font-weight: $global-weight-normal;
    font-size: rem-calc(getFontSize('textMedium'));
    letter-spacing: rem-calc(getLetterSpacing('textMedium'));
    line-height: rem-calc(getLineHeight('textMedium'));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: rem-calc(0);
    transition: color 0.35s ease;
    color: $text-black;
    &-small{
        font-size: rem-calc(getFontSize('textSmall'));
        letter-spacing: rem-calc(getLetterSpacing('textSmall'));
        line-height: rem-calc(getLineHeight('textSmall'));
    }
    &:focus-visible {
        border: rem-calc(1) solid $border-focused;
        outline: none;
    }
    &-primary{
        padding: rem-calc(0 24);
        height: rem-calc(44);
        text-decoration: none;
        border-radius: 0;
        cursor: pointer;
        &:disabled,
        &.disabled,
        &.hollow:disabled,
        &.hollow.disabled {
            cursor: not-allowed;
        }
        &:focus-visible {
            border: rem-calc(1) solid $border-focused;
            outline: none;
        }
        &_black {
            color: $text-white;
            background-color: $surface-dark;
            &:focus-visible {
                color: $text-white;
                background: $surface-dark;
            }
            &:hover, &:active, &.active, &:focus {
                color: $text-medium-grey;
                background: $surface-dark;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                background: $medium-grey;
                color: $text-black;
            }
        }
        &_white{
            color: $text-black;
            background-color: $surface-white;
            &:focus-visible {
                color: $text-black;
                background: $surface-white;
            }
            &:hover, &:active, &.active, &:focus {
                color: $text-grey;
                background: $surface-white;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                background: $surface-white;
                color: $text-grey;
            }
        }
        &_loyalty {
            color: $text-black;
            background-color: $surface-organic;
            &:focus-visible {
                color: $text-black;
                background: $surface-organic;
            }
            &:hover, &:active, &.active, &:focus {
                color: $text-grey;
                background: $surface-organic;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                background: $surface-organic;
                color: $text-grey;
            }
        }
        &_outlineBlack{
            color: $text-black;
            background-color: transparent;
            border: rem-calc(1) solid $border-black;
            &:hover, &:active, &.active, &:focus {
                color: $grey;
                background-color: transparent;
                border: rem-calc(1) solid $border-black;
            }
            &:focus-visible {
                color: $text-black;
                background-color: transparent;
                border: rem-calc(1) solid $border-focused;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                background: transparent;
                color: $text-grey;
                border: rem-calc(1) solid $border-grey;
            }
        }
        &_outlineWhite{
            color: $text-white;
            background-color: transparent;
            border: rem-calc(1) solid $border-white;
            &:hover, &:active, &.active, &:focus {
                color: $text-medium-grey;
                background-color: transparent;
                border: rem-calc(1) solid $border-white;
            }
            &:focus-visible {
                color: $text-white;
                background-color: transparent;
                border: rem-calc(1) solid $border-focused;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                background: transparent;
                color: $text-white;
                border: rem-calc(1) solid $border-white;
                opacity: .5;
            }
        }
    }
    &-secondary{
        display: inline-flex;
        align-items: center;
        padding: rem-calc(0);
        height: rem-calc(44);
        text-decoration: underline;
        text-underline-offset: rem-calc(1.5);
        cursor: pointer;
        &:focus-visible{
            border: rem-calc(1) solid $border-focused;
            outline: none;
            &:not(.cc-button-secondary-icon){
                padding: rem-calc(0 8);
            }
        }
        &_black{
            color: $text-black;
            &:hover, &:active, &.active, &:focus {
                color: $text-grey;
            }
            &:focus-visible {
                color: $text-black;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                cursor: not-allowed;
                color: $text-grey;
                &:hover{
                    color: $text-grey;
                }
            }
        }
        &_white{
            color: $text-white;
            &:hover, &:focus, &:active, &.active {
                color: $text-medium-grey;
            }
            &:focus-visible {
                color: $text-white;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                cursor: not-allowed;
                color: $text-medium-grey;
                &:hover{
                    color: $text-medium-grey;
                }
            }
        }
        &-icon{
            padding: rem-calc(14);
            height: auto;
        }
    }
    &-tertiary{
        display: inline-flex;
        align-items: center;
        gap: rem-calc(8);
        padding: rem-calc(0);
        height: rem-calc(44);
        text-decoration: none;
        cursor: pointer;
        &:focus-visible{
            border: rem-calc(1) solid $border-focused;
            outline: none;
            &:not(.cc-button-secondary-icon){
                padding: rem-calc(0 8);
            }
        }
        &_black{
            color: $text-black;
            &:hover, &:active, &.active, &:focus {
                color: $text-grey;
            }
            &:focus-visible {
                color: $text-black;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                cursor: not-allowed;
                color: $text-grey;
                &:hover{
                    color: $text-grey;
                }
            }
        }
        &_white{
            color: $text-white;
            &:hover, &:focus, &:active, &.active {
                color: $text-medium-grey;
            }
            &:focus-visible {
                color: $text-white;
            }
            &:disabled,
            &.disabled,
            &.hollow:disabled,
            &.hollow.disabled {
                cursor: not-allowed;
                color: $text-medium-grey;
                &:hover{
                    color: $text-medium-grey;
                }
            }
        }
        &-icon{
            padding: rem-calc(14);
            height: auto;
        }
    }

    &.extended {
        width: 100%;
        min-width: 100%;
    }

    &.extended-for-small-only {
        @include breakpoint(small down) {
            width: 100%;
            min-width: 100%;
        }
    }
}
.cc-toggleButton {
    display: flex;
    align-items: center;
    gap: rem-calc(8);

    &-switch {
        position: relative;
        display: inline-flex;
        width: rem-calc(32);
        height: rem-calc(18);
        border-radius: rem-calc(10);
        cursor: pointer;
        transition: all 0.6s ease-in-out;
        &:before {
            content: '';
            position: absolute;
            border-radius: rem-calc(20);
            transition: all 0.4s ease-in-out;
            height: rem-calc(12);
            width: rem-calc(12);
            top: 50%;
            transform: translate(0, -50%);
            left: rem-calc(2);
        }
        &_black{
            background-color: $surface-white;
            border: rem-calc(1) solid $border-black;
            &:before {
                background-color: $surface-dark;
            }
        }
        &_grey{
            background-color: $surface-white;
            border: rem-calc(1) solid $border-grey;
            &:before {
                background-color: $surface-grey;
            }
        }
    }

    &-input {
        display: none;
        &:checked + .cc-toggleButton-switch {
            &_black{
                background-color: $surface-dark;
            }
            &_grey{
                background-color: $surface-grey;
            }
            &::before {
                content: '';
                transform: translate(#{rem-calc(14)}, -50%);
                background-color: $surface-white;
            }
        }
    }
}