:root {
    --paddingleft: calc((100% - 1180px)/2);
}

.cc-recommendations {
    width: 100%;
    margin-bottom: rem-calc($l);
    @include breakpoint (large up){
        margin-bottom: rem-calc($xl);
    }
    &-box{
        overflow-x: auto;
        padding: rem-calc(0 0 $m $xs);
        margin-left: 0;
        display: flex;
        @include breakpoint (large up){
            padding-left: 0;
            overflow-y: hidden;
        }
    }
    &-title{
        display: flex;
        justify-content: space-between;
        padding: rem-calc(26 0 26 $xs);
        margin-top: rem-calc($m);
        @include breakpoint (large up){
            padding: rem-calc(18 $xs 18 $m);
            margin-bottom: rem-calc($s);
        }
    }
    &-arrowsBox {
        display: flex;
        align-items: center;
        &-left {
            padding-right: rem-calc($s);
        }
        &-left, &-right {
            &.active {
                cursor: pointer;
                & .cc-recommendations-arrowsBox-image {
                    opacity: 1;
                }
            }
        }
        &-image {
            width: rem-calc($xs);
            opacity: .5;
            transition: all 0.2s ease;
        }
    }
}
.cc-carouselRecommedation{
    display: flex;
    &-widget{
        padding: 0;
        width: 100%;
        @include breakpoint (medium down) {
            overflow-x: visible;
        }
        &-item{
            margin-left: 0;
            padding-right: 0;
            flex: none;
            @include breakpoint(large up) {
                max-width: rem-calc(360);
            }
            @include breakpoint(medium down) {
                max-width: rem-calc(257.5);
            }
        }
    }
}

.cc-google-recommendations-search {
    .cc-recommendations-title {
        @include breakpoint(large up) {
            padding: rem-calc(18 $m);
        }
    }

    .cc-carouselRecommedation-widget-item {
        @include breakpoint(large up) {
            max-width: 25vw;
        }
    }
}

.component-suggested-product-einstein,
.recently-viewed-wrapper {
    max-width: 100%;
    @include breakpoint (large up){
        max-width: calc(100% - var(--paddingleft));
        margin-left: var(--paddingleft);
        width: 100%;
    }

    .recommendation-title {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(30);
    }

    .single-item {

        .strike-through {
            text-decoration: line-through;
            color: $medium-light-gray;
        }

        .product-tile-title-label {
            @include breakpoint (medium down){
                font-size: rem-calc(12);
            }
        }

        .price-container {
            @include breakpoint (medium down){
                display: flex;
                flex-direction: column;
            }

            .value {
                @include breakpoint (medium down){
                    font-size: rem-calc(12);
                    margin-top: rem-calc(3);
                }
            }
        }

        .product-tile-with-omnibus{
            .price-container {
                @include breakpoint(medium down) {
                    flex-flow: row-reverse;
                    justify-content: flex-end;
                    align-items: baseline;
                    gap: rem-calc(5);
                }
                .value {
                    font-size: rem-calc(14);
                    line-height: rem-calc(14);
                    @include breakpoint (medium down){
                        font-size: rem-calc(12);
                        line-height: rem-calc(12);
                        margin-top: rem-calc(3);
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: baseline;
                        gap: rem-calc(5);
                    }
                }

                .cc-discount-msg:not(.hidden) {
                    display: block;
                    font-size: rem-calc(14);
                    line-height: rem-calc(14);
                    margin-left: rem-calc(4);
                    @include breakpoint(medium down) {
                        margin: rem-calc(3 0 0 4);
                        font-size: rem-calc(12);
                        line-height: rem-calc(12);
                    }
                }
            }
        }

        .plp-swatch {
            @include breakpoint (medium down){
                display: block;
                font-size: rem-calc(10);
            }
        }

        .plp-swatch-colors-wrapper {
            display: none;
        }
    }
}
.einstein-products-recommender {
    width: 100%;
    margin-bottom: rem-calc(120);

    @include breakpoint(medium down){
        margin-left: 0;
        padding-right: 0;
        margin-bottom: rem-calc(80);
    }
    .strike-through{
        @include breakpoint(medium down){
            display: inline-block;
            .value{
                white-space: nowrap;
            }
        }
    }
    .carousel-einstein{
        display: flex;
        .widget-2-container{
            padding: 0;
            width: 100%;
            @include breakpoint (medium down) {
                overflow-x: visible;
                    .three-blocks-box{
                        transform: unset;
                        width: unset;
                        padding-left: rem-calc(20);
                    }
            }
            @include breakpoint (large up){
                .three-blocks-box{
                    overflow-y: hidden;
                }
                .single-item{
                    margin-right: rem-calc(8);
                    max-width: rem-calc(350);
                    margin-left: 0;
                    padding-right: 0;

                    &:last-of-type{
                        margin-right: 0;
                    }
                }
            }
            .product{
                max-width: unset;
            }
        }
        .product{
            max-width: 33%;
            margin-right: rem-calc(65);
            &:last-of-type{
                margin-right: 0;
            }
            @include breakpoint (medium down){
                &:nth-of-type(3n){
                    display: none;
                }
                max-width: 50%;
                margin-right: rem-calc(0);
                &:first-of-type{
                    margin-right: rem-calc(10);
                }
            }
            @include breakpoint(small down){
                &:first-of-type{
                    margin-right: 0;
                }
            }
            .tile-body{
                padding: 0 rem-calc(10);
                margin-top: rem-calc(10);
                .product-tags{
                    font-size: rem-calc(10);
                }
                .js-new-msg{
                    display: none;
                }
            }
        }


    }
}

.product-detail {
    h4.recommendation-title,
    .recently-viewed-recommendation-title {
        font-size: rem-calc(18);
        font-weight: 300;
        letter-spacing: rem-calc(0.23);
        line-height: rem-calc(24);
        font-family:  $body-font-family-cursive;
        margin-bottom: rem-calc(10);

        @include breakpoint(large up) {
            font-size: rem-calc(24);
            line-height: rem-calc(32);
            margin-bottom: rem-calc(30);
            letter-spacing: rem-calc(0.2);
            padding-right: 10%;
        }
    }
}
