.cc-accordion {
  &[disabled] .submenu-title,
  &[disabled] .cc-accordion-title {
    cursor: not-allowed;
  }
  &-title {
    padding: rem-calc(16 0);
    position: relative;
    cursor: pointer;
    background-color: transparent;
    margin-bottom: 0;
    -webkit-transition: opacity .5s cubic-bezier(.25, .46, .45, .94);
    width: 100%;
    text-align: left;
    &::after {
      content: "";
      font-weight: $global-weight-normal;
      width: rem-calc(16);
      height: rem-calc(16);
      position: absolute;
      display: inline-block;
      right: rem-calc(0);
      top: 50%;
      transform: translateY(-50%);
      background: url("../images/plus-new.svg") no-repeat center;
      background-size: 100%;
    }
    &_border{
      border-top: rem-calc(1) solid $border-medium-grey;
      border-bottom: rem-calc(1) solid $border-medium-grey;
    }
    &_borderBottom{
      border: 0;
      border-bottom: rem-calc(1) solid $border-medium-grey;
      &::after {
        right: rem-calc(14);
      }
    }
    &_padding{
      padding: rem-calc(16);
      &::after {
        right: rem-calc(16);
      }
    }
  }
  &-item {
    &.active {
      .cc-accordion{
        &-title {
          border-bottom: 0;
          &::after {
            content: "";
            background: url("../images/minus.svg") no-repeat center;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  &-content {
    display: none;
    padding: rem-calc(0 0 8 0);
    &_padding{
      padding: rem-calc(0 16 8 16);
    }
    &_border{
      border-bottom: rem-calc(1) solid $border-medium-grey;
    }
    &_borderBottom{
      border: 0;
      border-bottom: rem-calc(1) solid $border-medium-grey;
    }
  }
}
