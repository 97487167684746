.cc-wishlistNotification{
  width: 100%;
  position: fixed;
  right: 0;
  border-bottom: rem-calc(1) solid $border-medium-grey;
  background: $surface-light-grey;
  z-index: $zIndex-search;
  padding: rem-calc($xs);
  display: flex;
  align-items: center;
  gap: rem-calc($xxs);
  transition: transform 1s;
  &:focus-visible {
      outline: none;
  }
  @include breakpoint(large up) {
    margin-top: rem-calc($xs);
    right: rem-calc($m);
    width: rem-calc(344);
    border: rem-calc(1) solid $border-medium-grey;
  }
  &.cc-notificationAnimation{
    @include breakpoint(medium down) {
      transform: translate(0, #{rem-calc(-57)});
    }
  }
  &-hideAnimation{
    transform: translate(0, #{rem-calc(-200)});
  }
  &-hidden{
    display: none;
  }
  &-image{
    width: rem-calc(53);
  }
  &-text{
    width: auto;
    flex: 1 1 0;
    padding-right: rem-calc($s);
  }
  &-link{
    display: flex;
    justify-content: flex-start;
  }
  &-button{
    position: absolute;
    right: rem-calc(2);
    width: rem-calc(44);
    height: rem-calc(44);
  }
}

.cc-notification {
    position: fixed;
    width: 100vw;
    z-index: 102;
    transition: transform 800ms;
    transform: translate(0, 0);
    @include breakpoint(large up) {
      width: rem-calc(344);
      right: rem-calc($m);
      transform: translate(0, #{rem-calc(32)});
    }
    &.cc-notificationAnimation{
      transition: transform 800ms;
      transform: translate(0, #{rem-calc(-57)});
      @include breakpoint(large up) {
        transform: translate(0, #{rem-calc(-21)});
      }
    }
  }
  
  .header-message {
    text-align: center;
    background: transparent;
    transition: background 0.25s ease;
    position: relative;
  
    p:last-of-type {
      margin-bottom: 0;
    }
  
    &-close {
      cursor: pointer;
      margin-left: rem-calc($xxs);
      display: none;
      font-size: 2em;
      line-height: 1;
      font-style: normal;
      font-weight: $global-weight-light;
    }
  
    &-content {
      padding: rem-calc($xs);
      &-container{
        align-items: center;
        justify-content: space-between;
      }
    }
  
    &.dismissable {
      .header-message-close {
        display: block;
      }
    }
  
    /*
      ** Types of notifications
      */
  
    &.alert-danger {
      background: $alert;
      color: $white;
  
      .header-message-close {
        color: $white;
      }
    }
  
    &.alert-success {
      background: $primary;
      color: $white;
  
      .header-message-close {
        color: $white;
      }
    }
  
    &.promo-message {
      transition: background 0.25s ease, transform 0.3s ease;
      background: $christmas;
      color: $white;
      //z-index: 3;
      transform: translateY(0);
  
      .header-message-close {
        color: $white;
      }
  
      &.go-down {
        transform: translateY(-100%);
        @media (max-width: 1024px) {
          transform: none;
        }
      }
    }
  }

.cc-feedbackMessage {
  &-container {
    @include flex(row, initial, center);
    background-color: $surface-light-grey;
    padding: rem-calc($xxs);
    gap: rem-calc($xs);
  }

  &-icon {
    width: rem-calc(16);
    height: rem-calc(16);
  }

  &-message {
    margin-bottom: 0;
    a {
      text-decoration: underline;
      text-underline-offset: rem-calc(2);
      color: inherit;
    }
  }
}