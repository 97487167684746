.cc-productTile {
  &-body {
    @include flex(column, flex-start, flex-start);
    gap: rem-calc($xxs);
    padding: rem-calc($xs $xxs 0);
    @include breakpoint(large up) {
      padding: rem-calc($xs $xs 0);
    }
  }
  &-swatches {
    &-singleItem {
      margin-top: rem-calc(1);
      width: rem-calc(16);
      height: rem-calc(16);
      &_selected {
        border: rem-calc(1) solid $border-black;
        padding: rem-calc(1);
        width: rem-calc(18);
        height: rem-calc(18);
        margin-top: 0;
      }
    }

    &-wrapper {
      @include flex(row, flex-start, center);
      gap: rem-calc($xxs);

      &_slick {
        max-width: rem-calc(160);
        opacity: 0;
        height: 0;
        &:has(.cc-productTile-swatches-singleItem_selected) {
          max-width: rem-calc(162);
        }
        .slick-slide {
          width: rem-calc(16);
          margin: 0;
          &:has(.cc-productTile-swatches-singleItem_selected) {
            width: rem-calc(18);
          }
        }
        .slick-track {
          @include flex(row, flex-start, center);
          padding: rem-calc(3 0);
          gap: rem-calc($xxs);
          height: 0;
        }
        .slick-list {
          margin: 0;
          max-width: rem-calc(160);
          height: 0;
          &:has(.cc-productTile-swatches-singleItem_selected) {
            max-width: rem-calc(162);
          }
        }
        .slick-arrow {
          top: 50%;
          height: rem-calc(20);
          width: rem-calc(12);
          background-color: $surface-white;

          &.slick-prev {
            left: rem-calc(-8);
            transform: translate(-100%, -50%);
            &:before {
              content: "";
              background: url("../../images/chevron-left.svg") no-repeat;
              background-size: rem-calc(12);
              width: rem-calc(12);
              height: rem-calc(12);
              display: block;
            }
          }
          &.slick-next {
            right: rem-calc(-8);
            transform: translate(100%, -50%);
            &:before {
              content: "";
              background: url("../../images/chevron-right.svg") no-repeat;
              background-size: rem-calc(12);
              width: rem-calc(12);
              height: rem-calc(12);
              display: block;
            }
          }
        }
      }
    }
    &-wrapperBox {
      @include flex(row, initial, center);
      gap: rem-calc(8);
      a {
        line-height: 0;
      }
      .cc-productTile-swatches-singleItem {
        width: rem-calc(16);
        margin-top: 0;
        &_selected {
          border: rem-calc(1) solid $border-black;
          width: rem-calc(18);
        }
      }
    }
  }

  @include breakpoint(large up) {
    &:hover {
      .cc-productTile-swatches-wrapper_slick {
        opacity: 1;
        height: rem-calc(24);
        .slick-track,
        .slick-list {
          height: rem-calc(24);
        }
        .slick-arrow,
        .slick-arrow:before {
          opacity: 1;
          z-index: 1;
        }
      }
      .cc-productTile-swatches-wrapperBox_hidden {
        display: none;
      }
    }
  }

  &-image {
    &-wrapper {
      position: relative;
    }

    &-soldOut,
    &-comingSoon {
      @include flex(column, center, center);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: rem-calc(0 $xxs);
      pointer-events: none;
      font-family: $body-font-family;
      font-weight: $global-weight-normal;
      font-size: rem-calc(getFontSize("textSmall"));
      letter-spacing: rem-calc(getLetterSpacing("textSmall"));
      line-height: rem-calc(getLineHeight("textSmall"));

      @include breakpoint(large up) {
        font-size: rem-calc(getFontSize("textMedium"));
        letter-spacing: rem-calc(getLetterSpacing("textMedium"));
        line-height: rem-calc(getLineHeight("textMedium"));
      }
    }

    &-slider {
      pointer-events: none;
      @include breakpoint(large up) {
        pointer-events: all;
      }

      &-prev,
      &-next {
        display: none;
      }

      &-wrapper {
        .slick-arrow {
          opacity: 0;
          top: 50%;
          transform: translateY(-50%);
          padding: rem-calc($xs);
          position: absolute;
          cursor: pointer;

          &.cc-productTile-image-slider-prev {
            left: rem-calc(8);
            @include breakpoint(large up) {
              display: block;
            }
          }

          &.cc-productTile-image-slider-next {
            right: rem-calc(8);
            @include breakpoint(large up) {
              display: block;
            }
          }
        }

        &:hover {
          .slick-arrow {
            opacity: 1;
          }
        }

        .slick-arrow:focus-visible {
          opacity: 1;
        }
      }
    }

    // Images Slider - Progress Bar
    &-sliderProgress {
      display: none;
      width: 100%;
      height: rem-calc(1);
      overflow: hidden;
      position: absolute;
      bottom: 0;

      @include breakpoint(large up) {
        display: block;
      }

      background-color: $border-medium-grey;
      background-image: linear-gradient(to right, $border-black, $border-black);
      background-repeat: no-repeat;
      background-size: 0 100%;

      transition: background-size 600ms ease-in-out;

      // In mobile, if the product image slider is itself inside a carousel, progress bar does not display
      .cc-isCarousel & {
        display: none;
        @include breakpoint(large up) {
          display: block;
        }
      }
    }

    &-quickAddButton,
    &-wishlist {
      display: flex;
      cursor: pointer;
      position: absolute;
    }

    &-quickAddButton {
      bottom: 0;
      right: 0;
      padding: rem-calc(10);

      &-icon {
        padding: rem-calc($xxxs);
        background-color: $surface-light-grey;
      }
    }

    &-wishlist {
      top: 0;
      right: 0;
      padding: rem-calc($xs);
    }
  }

  &-price {
    width: 100%;

    &-wrapper {
      @include flex(row, initial, initial);
      gap: rem-calc($xxs);
    }

    .cc-price {
      @include flex(column, initial, initial);
      gap: rem-calc($xxs);
    }
  }
}

.cc-productTag {
  padding: rem-calc($xxxs $xxs);

  &_sale {
    background-color: $surface-terracotta;
    color: $text-white;
  }

  &_outlet {
    background-color: $surface-felce;
    color: $text-white;
  }

  &_loyalty {
    background-color: $surface-organic;
  }

  &_extraordinary {
    background-color: $surface-jeans;
  }
}
