html {
    overflow-x: hidden;
}

::selection {
    background-color: $black;
    color: $white;
}

// Head space for fixed nav
.page {
    position: relative;

    &.prehome{
        padding-top: 0;
    }
    &[data-action="Order-Confirm"] {
        @include breakpoint(medium up) {
            padding-top: rem-calc(116);
        }
    }

}


// Footer Fixes
.page {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    position: relative;

    .grid-container {
        flex-grow: 1;
        width: 100%;
    }
}

.price-policy-popup-modal {
    background: rgba(0, 0, 0, 0.3);
    button.close {
        @include breakpoint (small down) {
            top:  rem-calc(9);
            right:  rem-calc(14);
            z-index: 999999999;
        }
        @include breakpoint (medium up) {
            top: rem-calc(15);
            right: rem-calc(18);
        }
    }
    .content-asset {
        @include breakpoint (small down) {
            width: rem-calc(345);
            height: rem-calc(447);
        }
        @include breakpoint (medium up) {
            width: rem-calc(597);
            height: rem-calc(340);
        }
    }
    .tingle-modal-box {
        background: none;
        @include breakpoint (small down) {
            width: rem-calc(345);
            height: rem-calc(447);
        }
        @include breakpoint (medium up) {
            width: rem-calc(597);
            height: rem-calc(340);
        }
        &__content {
            position: relative;
            padding: 0;
            background: none;
            @include breakpoint (small down) {
                width: rem-calc(345);
                height: rem-calc(447);
            }
            @include breakpoint (medium up) {
                width: rem-calc(597);
                height: rem-calc(340);
            }
        }
    }
    img {
        height: 100%;
        width: 100%;
    }
    .container {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        text-align: center;
        @include breakpoint (medium up) {
            width:  rem-calc(418);
        }
    }
    .text {
        color: black;
        font-family: "MessinaSansWeb";
        font-weight: normal;
        display: block;
        &.text-1 {
            font-weight: 300;
            font-size: rem-calc(26);
            line-height: rem-calc(20.4);
            margin-top: rem-calc(40);
            margin-bottom: rem-calc(45.26);
            height: rem-calc(18.74);
            @include breakpoint (small down) {
                margin-bottom: rem-calc(105.6);
            }
            @include breakpoint (medium up) {
                position: relative;
                left: rem-calc(3);
            }
            .strong {
                text-transform: uppercase;
                letter-spacing: rem-calc(1.5);
            }
        }
        &.text-2 {
            font-size: rem-calc(20);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(0.33);
            margin-bottom: rem-calc(10);
            position: relative;
            @include breakpoint (small down) {
                left: rem-calc(-1);
            }
            @include breakpoint (medium up) {
                height: rem-calc(24);
                left: rem-calc(1);
                top: rem-calc(2);
            }
        }
        &.text-3 {
            font-weight: 300;
            font-size: rem-calc(16);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(0.23);
            position: relative;
            @include breakpoint (small down) {
                margin-bottom: rem-calc(49);
                left: rem-calc(-1);
            }
            @include breakpoint (medium up) {
                margin-bottom: rem-calc(46);
                height: rem-calc(41);
                left: rem-calc(1);
                top: rem-calc(-1);
            }
        }
    }
    .link {
        font-size: rem-calc(14);
        line-height: rem-calc(14);
        letter-spacing: rem-calc(0.2);
        padding-bottom: rem-calc(3);
        margin: 0;
        &.button {
            text-transform: uppercase;
            height:  rem-calc(40);
            padding-top: rem-calc(11);
            padding-bottom: rem-calc(13);
            margin-bottom: rem-calc(15);
            @include breakpoint (small down) {
                width:  rem-calc(313);
            }
            @include breakpoint (medium up) {
                width:  rem-calc(408);
                left: rem-calc(5);
            }
            color: #FFFFFF;
            text-transform: uppercase;
            &:hover {
                color: black;
            }
        }
        &:not(.button) {
            @include breakpoint (medium up) {
                font-weight: 600;
            }
        }
        &.force-hover {
            border-bottom: 1px solid black;
            display: inline;
            position: relative;
            @include breakpoint (small down) {
                top: -3px;
                left: -1px;
            }
            @include breakpoint (medium up) {
                height: rem-calc(20);
                top: rem-calc(-2);
                left: rem-calc(-1);
            }
        }
    }
}