@mixin fill {
    width: 100%;
    height: 100%;
}

@mixin helpers() {
    .pos-relative {
        position: relative;
    }

    .hidden,
    .cc-visuallyHidden { // .cc-visuallyHidden used mainly for accessibility purposes
        display: none;
    }

    .no-margin {
        margin: 0 !important;
    }

    .no-margin-top {
        margin-top: 0 !important;
    }

    .no-padding {
        padding: 0 !important;
    }

    .margin-top-auto {
        margin-top: auto !important;
    }

    .cover {
        object-fit: cover;
        transform-origin: 50% 50%;
        width: 100%;
        height: 100%;
    }

    .visuallyhidden {
        position: absolute !important;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
        border: 0;
    }

    .small {
        &-text-center {
            @include breakpoint(small down) {
                text-align: center;
            }
        }

        &-align-center {
            @include breakpoint(small down) {
                justify-content: center;
            }
        }
    }

    .medium {
        &-text-center {
            @include breakpoint(medium down) {
                text-align: center;
            }
        }

        &-align-center {
            @include breakpoint(medium down) {
                justify-content: center;
            }
        }
    }

    // Standard SFRA
    .d-none {
        display: none !important;
    }
    .hide-order-discount,
    .hide-product-discount,
    .hide-shipping-discount {
        display: none;
    }
    .hide-cod-item {
        display: none;
    }
    .hide-ld-item {
        display: none;
    }
    .non-adjusted-price {
        display: none;
    }
}

@mixin triangle($size, $color) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $size $size;
    border-color: transparent transparent $color;
    margin-top: -$size/2;
    transition: 0.3s;
}

/// Generate border class helpers
///
/// @param {Color} $color - border color
@mixin borders($color, $break) {
    $positions: ("top", "left", "right", "bottom");

    @each $pos in $positions {
        @include breakpoint($break) {
            .border-#{$pos} {
                border-#{$pos}: 1px solid $color;
            }
        }
        @each $breakpoint, $size in $breakpoints {
            .#{$breakpoint}-border-#{$pos} {
                @include breakpoint($breakpoint down) {
                    border-#{$pos}: 1px solid $color;
                }
            }
        }
    }
}

@mixin full-container() {
    @each $breakpoint, $size in $breakpoints {
        .#{$breakpoint}-full {
            @include breakpoint($breakpoint down) {
                padding-right: 0;
                padding-left: 0;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}

@mixin flex($direction, $primary: flex-start, $secondary: stretch) {
    display: flex;
    flex: 0 0 auto;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
    -webkit-justify-content: $primary;
    justify-content: $primary;
    -webkit-align-items: $secondary;
    align-items: $secondary;
    -webkit-align-content: $secondary;
    align-content: $secondary;
}

@mixin flexGenerator($direction, $primary: flex-start, $secondary: stretch) {
    display: flex;
    flex: 1 0 auto;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
    -webkit-justify-content: $primary;
    justify-content: $primary;
    -webkit-align-items: $secondary;
    align-items: $secondary;
    -webkit-align-content: $secondary;
    align-content: $secondary;
}

@mixin fixHeight($height) {
    height: $height;
    min-height: $height;
    max-height: $height;
}
@mixin fixWidth($width) {
    width: $width;
    min-width: $width;
    max-width: $width;
}
@mixin truncateRow($numRow:1) {
    overflow: hidden;
    text-overflow: ellipsis;
    @if $numRow>1 {
        display: -webkit-box;
        -webkit-line-clamp: $numRow;
        -webkit-box-orient: vertical;
    }
    @else {
        white-space: nowrap;
    }
}

@mixin customScrollbar {
    
    &::-webkit-scrollbar {
        width: rem-calc(2);
    }
    &::-webkit-scrollbar-track {
        background-color: $gray-separator;
        border-left: rem-calc(1) solid #F6F6F5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $black;
    }
}