@import "../setup/settings";

.overlay-bubble-chat {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 997;
}

.bubble-element {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1050;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
    &.on-mobile {
        @media screen and (max-width: 800px) {
            width: auto;
            bottom: rem-calc(20);
            right: rem-calc(20);
            .botton-bubble {
                display: block;
            }
        }
    }
    &.footer-links-add {
        @include breakpoint (xlarge up) {
            margin: auto;
            width: rem-calc(60);
            padding: rem-calc(10);
            margin-right: rem-calc(250);
            padding-right: rem-calc(450);
            padding-left: rem-calc(250);
        }
    }
    @include breakpoint (medium down) {
        z-index: 998;
    }
    .botton-bubble {
        position: relative;
        background: $white;
        border-left: 1px solid $border-gray;
        border-top: 1px solid $border-gray;
        border-top-left-radius: 20px;
        border-color: transparent;
        display: block;
        position: relative;
        box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
        padding: rem-calc(12 200 9 20);
        @media screen and (max-width: 800px) {
            display: none;
        }
        @media screen and (max-width: 1024px) {
            padding: rem-calc(12 20);
        }
        @media screen and (max-width: 800px) {
            border: 1px solid $border-gray;
            border-radius: 20px;
            padding: rem-calc(9 20);
        }
        &.its-hide {
            display: none;
        }
        > span {
            font-family: "MessinaSansWeb", Arial, sans-serif;
            font-size: rem-calc(14);
            line-height: rem-calc(14);
            letter-spacing: .0125rem;
        }
        i {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            transform-origin: center;
            &.show {
                transform: translateY(-50%) rotate(180deg);
            }
        }
        .svg-chat {
            margin-right: rem-calc(5);
        }
    }
    .bubble-block {
        padding: rem-calc(0 20);
        border-left: 1px solid $border-gray;
        display: none;
        background: $white;
        min-width: rem-calc(260);
        position: relative;
        margin-bottom: 0;
        box-shadow: 0 1px 6px 0 rgba(0,0,0,0.4);
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 8px;
            background: white;
            top: rem-calc(-8);
            left: 0;
        }
        &.show {
            display: block;
            @media screen and (max-width: 800px) {
                padding: rem-calc(0 40);
                border: none;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -15px);
                    width: 100px;
                    height: rem-calc(5);
                    background: #fff;
                    border-radius: 2px;
                }
            }
        }
        .bubble-service-list {
            margin-left: 0;
            list-style-type: none;
            margin-bottom: 0;
            padding-top: rem-calc(5);
            @media screen and (max-width: 800px) {
                padding-top: 0;
            }
            li {
                padding: rem-calc(25 0);
                border-top: 1px solid $border-gray;
                @media screen and (max-width: 800px) {
                    &:first-of-type {
                        border: none;
                    }
                }
                a {
                    line-height: rem-calc(28);
                    &.disabled {
                        color: $black;
                        text-decoration: none;
                        opacity: 0.4;
                    }
                }
            }
        }
        .close-button-bubble {
            position: absolute;
            right: rem-calc(15);
            top: rem-calc(18);
            width: rem-calc(24);
            span {
                font-size: rem-calc(18);
                float: right;
            }
        }
        .icon-bubble-service {
            font-size: rem-calc(20);
            line-height: rem-calc(28);
            letter-spacing: .0125rem;
            font-weight: 300;
            img {
                width: rem-calc(18);
                margin-right:rem-calc(8);
            }
        }
        .not-available-text {
            margin: 0;
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            display: block;
            letter-spacing: .0125rem;
            font-weight: 300;
        }
        .not-available-chat {
            margin: 0;
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            color: red;
            position: relative;
            padding-left: rem-calc(10);
            letter-spacing: .0125rem;
            font-weight: 400;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: rem-calc(6);
                height: rem-calc(6);
                background: red;
                border-radius: 20px;
            }
        }
        .available-chat {
            margin: 0;
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            color: #50A12A;
            position: relative;
            padding-left: rem-calc(10);
            letter-spacing: .0125rem;
            font-weight: 400;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: rem-calc(6);
                height: rem-calc(6);
                background: #8ac492;
                border-radius: 20px;
            }
        }
        .loader {
            margin-left: rem-calc(25);
            margin-top: rem-calc(3);
            margin-right: rem-calc(9);
            border: 2px solid #f3f3f3; /* Light grey */
            border-top: 2px solid #000000; /* Blue */
            border-radius: 50%;
            width: rem-calc(15);
            height: rem-calc(15);
            animation: spin 2s linear infinite;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        .availabilityContainer {
            text-align:center;
            display: flex;
        }
        .mobile-version-phone {
            background: transparent;
            padding-right:rem-calc(5);
            border: 0;
        }
    }
}

.page[data-action="Product-Show"],.page[data-action="Cart-Show"] {
    .bubble-element {
        display: none;
    }
}

.page[data-action="Search-Show"] {
    .bubble-element {
        display: inherit;
    }
}