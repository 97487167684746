.loyalty-use-your-coupon{
    margin: rem-calc(60 0 170);
    @include breakpoint(small down){
        margin: rem-calc(50 0 100);
    }
    .grid-container{
        @include breakpoint(small down){
            padding: rem-calc(0 20);
        }
    }
    p.title{
        margin-bottom: rem-calc(30);
        @include breakpoint(small down){
            font-size: rem-calc(14);
            margin-bottom: rem-calc(40);
        }
        &.big{
            font-size: rem-calc(20);
        }
    }
    .use-online{
        margin-bottom: rem-calc(70);
        @include breakpoint(small down){
            margin-bottom: rem-calc(60);
        }
        ul{
            list-style: none;
            margin: 0;
            @include breakpoint(small down){
                font-size: rem-calc(12);
                padding-right: rem-calc(35);
            }
            li{
                line-height: rem-calc(20);
                margin-bottom: rem-calc(15);
                display: flex;
                @include breakpoint(small down){
                    margin-bottom: rem-calc(20);
                }
                img{
                    margin-right: rem-calc(9.5);
                    width: rem-calc(20);
                    height: rem-calc(20);
                }
            }
        }
    }
    .use-in-store{
        line-height: rem-calc(22);
        .description{
            margin-bottom: rem-calc(55);
            @include breakpoint(small down){
                font-size: rem-calc(12);
            }
        }
    }
    .legal-notes{
        font-size: rem-calc(9);
        line-height: rem-calc(17);
        margin-bottom: rem-calc(27);
        @include breakpoint(small down){
            margin-bottom: rem-calc(40);
        }
    }
    .ctas-container{
        .button{
            margin: 0;
            @include breakpoint(small down){
                margin-bottom: rem-calc(20);
            }
        }
    }
    hr{
        margin: rem-calc(52 0);
        border-color: #DDDDDD;
    }
    .stop-communications-text{
        @include breakpoint(small down){
            margin-top: rem-calc(76);
        }
        .paragraph{
            margin-bottom: rem-calc(40);
            display: block;
            @include breakpoint(small down){
                font-size: rem-calc(12);
            }
        }
        .options{
            display: block;
            margin: rem-calc(0 0 30 30);
            line-height: rem-calc(20);
            @include breakpoint(small down){
                margin-left: rem-calc(35);
            }
        }
        .fix-line-height{
            line-height: rem-calc(22);
        }
        .options-box{
            @include breakpoint(small down){
                padding-right: rem-calc(40);
            }
        }
    }
}