.widget-container {
  display: flex;
  position: relative;

  @include breakpoint(medium down) {
    flex-direction: column;
  }

  .widget-rect {
    background-color: #d3cbc9;
    &.display-none-desktop {
      @include breakpoint(large up) {
        display: none;
      }
    }
    &.display-none-mobile {
      @include breakpoint(medium down) {
        display: none;
      }
    }
  }

  .widget-photo {
    img {
      width: 100%;
      height: auto;
    }
    @include breakpoint(medium down) {
      width: 100vw;
    }
  }
}

.height-100 {
  height: 100%;
}

.widget-description-item-left {
  display: flex;
  padding: rem-calc(40);
  flex-direction: column;
  justify-content: space-between;
  @include breakpoint(medium down) {
    padding: rem-calc(30 20);
    justify-content: start;
  }
}

.widget-description-item-right {
  padding-left: rem-calc(40);
  @include breakpoint(medium down) {
    padding-left: 0;
  }
}

.widget-upper-item {
  font-family: "Messina Sans Light", sans-serif;
  .h2 {
    margin-bottom: rem-calc(30);
  }
  .cashmere-title {
      line-height: rem-calc(32);
      font-size: rem-calc(24);
  }
}

.widget-inferior-item {
  margin-right: rem-calc(180);

  @include breakpoint(medium down) {
    margin-right: rem-calc(235);
  }

  @include breakpoint(small down) {
    margin-right: 0;
  }

  .h1 {
    margin-bottom: rem-calc(10);
  }
  .h5 {
    margin-bottom: rem-calc(30);
  }
}

.no-padding-right-mobile {
  @include breakpoint(medium down) {
    padding-right: 0 !important;
  }
}

.widget-2-container {
    overflow: hidden;
    @include breakpoint(medium down) {
        &.widget-4c-container {
            padding: rem-calc(110 90);
        }
    }
    @include breakpoint(medium only){
        padding: rem-calc(60 90 100);
    }
    @include breakpoint(small down) {
        &.widget-4c-container {
            padding: 0;
        }
    }
    &.widget-2c-container {
        padding-right: 0;
        .parent {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        .arrows-box {
            height: 2rem;
            display: flex;
            align-items: center;
            .arrow-left {
                padding-right: rem-calc(15);
            }
            .arrow-left,
            .arrow-right {
                &.active {
                    cursor: pointer;
                    img {
                        width: rem-calc(20);
                        filter: none;
                    }
                }
                img {
                    width: rem-calc(20);
                    filter: opacity(0.5);
                    transition: all 0.2s ease;
                }
            }
        }
        .single-item {
            flex: none;
            @include breakpoint(large up) {
                max-width: rem-calc(467);
            }
            @include breakpoint(medium down) {
                max-width: rem-calc(324);
            }
            @include breakpoint(small down) {
                max-width: rem-calc(232);
            }
        }

        .three-blocks-box {
            overflow-x: auto;
            padding-bottom: rem-calc(30);
            width: calc(100% + 3px);
            transform: translateX(2px);
            margin-left: 0;
            @include breakpoint(large up) {
                &::-webkit-scrollbar {
                    height: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    background: black;
                    border-bottom: 1px solid white;
                }
                &::-webkit-scrollbar-track {
                    background: #dddddd;
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;
                }
            }
        
            @include breakpoint(medium down) {
                &::-webkit-scrollbar {
                    height: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    background: black;
                    border-bottom: 1px solid white;
                }
                &::-webkit-scrollbar-track {
                    background: #dddddd;
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;
                }
            }
            &.smooth {
                scroll-behavior: smooth;
            }
        }
    }
    .three-blocks-box {
        overflow-x: auto;
        padding-bottom: rem-calc(30);
        @include breakpoint(large up) {
            &::-webkit-scrollbar {
                height: 3px;
            }
            &::-webkit-scrollbar-thumb {
                background: black;
                border-bottom: 1px solid white;
            }
            &::-webkit-scrollbar-track {
                background: #dddddd;
                border-top: 1px solid white;
                border-bottom: 1px solid white;
            }
        }
        @include breakpoint(medium down) {
            &::-webkit-scrollbar {
                height: 3px;
            }
            &::-webkit-scrollbar-thumb {
                background: black;
                border-bottom: 1px solid white;
            }
            &::-webkit-scrollbar-track {
                background: #dddddd;
                border-top: 1px solid white;
                border-bottom: 1px solid white;
            }
        }
    }
  
    .fix-width-plp-widget .single-item{
        @include breakpoint(large up){
            max-width: rem-calc(365);
        }
        @include breakpoint(small down) {
            max-width: rem-calc(270);
        }
    }
    .text-title {
        margin-bottom: rem-calc(20);
        &.h2 {
            font-family: "SangBleu Sunrise", "Arial", "sans-serif";
        }
        &.margin-top-30 {
            margin-top: rem-calc(30);
            @include breakpoint(small down) {
                margin-top: rem-calc(20);
            }
        }
    }
    .section-title {
        margin-bottom: rem-calc(10);
    }
}

.border-red {
    border: 1px solid red;
}
.three-blocks-box {
    display: flex;
    @include breakpoint(medium down) {
        overflow-x: auto;
        position: relative;
        user-select: none;
        cursor: grab;
        padding-bottom: rem-calc(20);
        &::-webkit-scrollbar {
            height: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background: black;
            border-bottom: 1px solid white;
        }
        &::-webkit-scrollbar-track {
            background: #dddddd;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
        }
    }
    @include breakpoint(medium down){
        &.widget-2b-height {
            height: auto;
        }
    }
    &.widget-2a-height {
        .secondary-cta{
            margin-top: rem-calc(10) !important;
        }
    }
    .secondary-cta a{
        padding-bottom: rem-calc(1);
        border-bottom: rem-calc(1) solid $black !important;
    }
    &.border-bottom {
        @include breakpoint(medium down) {
            border-bottom: 1px solid #dddddd;
        }
    }
    .single-item {
        @include breakpoint(large up) {
            flex: 1;
        }
        @include breakpoint(medium down) {
            max-width: 55%;
        }
        @include breakpoint(small down) {
            max-width: 80%;
        }
    }
    &.widget-2b-height{
        .single-item{
            padding-right: 0;
            margin: rem-calc(0 14 0 0) !important;
            &:last-child{
                margin-right: 0 !important;
            }
            @include breakpoint(small down) {
                width: rem-calc(298);
                margin-right: rem-calc(10) !important;
            }
        }
    }
    img {
        width: 100%;
    }
}

.margin-grid-box {
    margin-left: -5px;
    margin-right: -5px;
    .single-item {
        padding-right: rem-calc(20);
        padding-left: rem-calc(0);
        @include breakpoint (medium down) {
            padding-right: rem-calc(20);
            padding-left: rem-calc(0);
            margin-left: rem-calc(-10);
        }
        .total-height-box {
            display: flex;
            flex-direction: column;
            height: 100%;
            .description-box {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                .description-text {
                    margin: 0;
                }
            }
            .secondary-cta {
                margin-top: auto;
            }
        }
    }
    .single-item:first-child {
        margin-left: rem-calc(10);
        @include breakpoint (medium down) {
            margin-left: rem-calc(0);
        }
    }
}

.description-box {
    margin-top: rem-calc(20);
    .description-title {
        line-height: rem-calc(24);
        margin-bottom: rem-calc(20);
    }
    &.description-box-2c {
        margin-top: rem-calc(15);
    }
    &.description-box-4c {
        margin-top: 0;
    }
    .description-text {
        margin-bottom: rem-calc(10);
        padding-right: rem-calc(30);
        line-height: rem-calc(24);
        &.padding-right-15 {
            padding-right: rem-calc(15);
        }       
        @include breakpoint(small down) {
            padding: 0;
        }
        &.width-fix {
            width: 80%;
            @include breakpoint(medium down) {
                width: 90%;
            }
            @include breakpoint(small down) {
                width: 100%;
            }
        }
    }
    .margin-bottom-40 {
        margin-bottom: rem-calc(40);
    }
}
.margin-bottom-10 {
  margin-bottom: rem-calc(10);
}

.flex-container-column {
    display: flex;
    flex-direction: column;
}

.widget-4c-container {
    .photo-box img {
        @include breakpoint(large down) {
            width: 100%;
        }
        @include breakpoint(small down) {
            height: rem-calc(570);
            object-fit: cover;
            object-position: 30%;
        }
    }
}


.widget14-box{
    margin: rem-calc(60 0);
    background-color: #EDECEA;
    @include breakpoint(medium down){
        position: relative;
        overflow: hidden;
        margin: rem-calc(30 0);
    }
    @include breakpoint(small down){
        margin: rem-calc(20 0);
    }
    .container-tabs{
        .tabs-widget-14{
            border-bottom: 1px solid #DDDDDD;
            white-space: nowrap;
            overflow: auto;
            padding-top: rem-calc(50) !important;
            @include breakpoint(medium down){
                padding: 0;
                padding-top: rem-calc(40) !important;
                position: absolute;
                top: rem-calc(780);
            }
            @include breakpoint(medium only){
                overflow: hidden;
                margin-left: rem-calc(40);
                width: 90%;
            }
            @include breakpoint(small down){
                padding-left: 0;
                margin-left: rem-calc(20);
                padding-top: rem-calc(50) !important;
                position: unset;
                width: 95%;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            li{
                width: 33%;
                @include breakpoint(small down){
                    width: 45%;
                }
            }
        }

        @include breakpoint(large up){
            .image-container{
                position: relative;
                width: 50%;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                img{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            @include breakpoint(medium down){
                height: rem-calc(780);
            }
            @include breakpoint(small down) {
                height: rem-calc(280);
            }
        }
        .tab-content{
            .d-flex{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include breakpoint(large up){
                    height: 100%;
                }
                @include breakpoint(medium only){
                    margin-top: rem-calc(110);
                }
                @include breakpoint(small down){
                    padding: 0;
                }
            }
            .cashmere-top-box{
                width: 75%;
                @include breakpoint(medium down){
                    width: 80%;
                }
                @include breakpoint(small down){
                    width: 100%;
                    padding-bottom: rem-calc(40);
                }
                h3{
                    margin-bottom: rem-calc(25);
                }
            }
            .cashmere-bottom-box{
                padding: rem-calc(40 0);
                @include breakpoint(small down){
                    padding-top: rem-calc(45);
                    padding-bottom: rem-calc(30);
                }
                h5{
                    margin-bottom: rem-calc(40);
                }
                img.feature-icon{
                    height: rem-calc(35);
                    width: rem-calc(35);
                    position: unset;
                    margin-right: rem-calc(15);
                    filter: opacity(0.4);
                    @include breakpoint(small down){
                        margin-bottom: rem-calc(10);
                    }
                }
                .cashmere-feature{
                    @include breakpoint(medium up){
                        display: flex;
                        align-items: center;
                    }
                    margin-bottom: rem-calc(20);
                }
                button{
                    margin: rem-calc(30 0 0);
                    background: transparent;
                }
            }
            .cashmere-top-box,
            .cashmere-bottom-box{
                @include breakpoint(small down){
                    padding-left: rem-calc(20);
                    padding-right: rem-calc(20);
                }
            }
        }
    }
    .cashmere-widget-pdp{
        @include breakpoint(large up){
            .d-flex.tablet-pdp-margin{
                padding-top: rem-calc(40);
            }
            .cashmere-bottom-box.tablet-pdp-padding{
                padding-bottom: rem-calc(10);
            }
        }
        @include breakpoint(medium only){
            h5{
                margin-bottom: rem-calc(30) !important;
            }
            .d-flex.tablet-pdp-margin{
                margin-top: rem-calc(100);
            }
            .cashmere-bottom-box.tablet-pdp-padding{
                padding: rem-calc(70 0 10);
            }
        }
        @include breakpoint(small down){
            h5{
                margin-bottom: rem-calc(20) !important;
            }
            img{
                width: 100%;
                height: auto;
            }
            .cashmere-top-box{
                padding-top: rem-calc(40);
            }
            .cashmere-bottom-box.tablet-pdp-padding{
                padding: rem-calc(0 20 10);
            }
        }
    }
}

.no-padding-mobile{
    @include breakpoint(medium down){
        padding: 0;
    }
}

.padding-mobile {
    @include breakpoint(small down) {
        padding: rem-calc(20);
    }
}

.widget-13-container{
    margin: rem-calc(60 0);
    @include breakpoint(medium down){
        margin: rem-calc(0 0 60 30);
    }
    @include breakpoint(small down){
        margin-bottom: rem-calc(20);
    }
    .margin-grid-box{
        @include breakpoint(large up){
            width: calc(100% + 10px);
        }
    }
    h2{
        margin-bottom: rem-calc(20);
    }
   .single-item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: auto;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
        .text-box{
            position: absolute;
            text-align: center;
            padding: rem-calc(0 100);
            @include breakpoint(medium down){
                padding: rem-calc(0 150);
            }
            @include breakpoint(small down){
                padding: rem-calc(0 60);
            }
            p:first-child{
                font-size: rem-calc(24);
            }
        }
    }
    @include breakpoint(medium down){
        .margin-grid-box{
            margin: 0;
            display: flex;
            justify-content: center;
            .single-item{
                margin-bottom: rem-calc(10);
                padding: 0;
            }
        }
    }
}

.widget-1-pdp{
    @include breakpoint(large up){
        .widget-inferior-item{
            margin: 0;
        }
    }
    @include breakpoint(medium down){
        .widget-upper-item{
            margin-bottom: rem-calc(100);
        }
        .widget-inferior-item{
            margin-right: rem-calc(140);
        }
    }
    @include breakpoint(small down){
        .widget-inferior-item{
            margin-right: 0;
        }
        .widget-upper-item{
            margin-bottom: rem-calc(25);
        }
    }
}

.widget-1-gift{
    .widget-upper-item{
        @include breakpoint(medium down){
            margin-bottom: rem-calc(110);
        }
        @include breakpoint(small down){
            margin-bottom: rem-calc(150);
        }
    }
}

.width-50{
    @include breakpoint(medium up){
        width: 70%;
        margin-bottom: rem-calc(40);
    }
    @include breakpoint(large up){
        width: 50%; 
    }
    margin-bottom: rem-calc(30);
}